import { TFunction } from 'i18next';

export function getErrorMessage(errorCode: number, t: TFunction<'translation', undefined, 'translation'>) {
	switch (errorCode) {
		case 404:
			return t<string>('apiNotFound');
		case 401:
			return t<string>('unableToAuthenticateTheUser');
		case 403:
			return t<string>('accessDenied');
		case 500:
			return t<string>('somethingWentWrong');
		case 1001:
			return t<string>('constraintViolated');
		case 1002:
			return t<string>('recordIsLinked');
		case 1003:
			return t<string>('parametersCannotBeNull');
		case 1004:
			return t<string>('methodArgumentsAreInvalid');
		case 1005:
			return t<string>('fileUploadingError');
		case 1006:
			return t<string>('parameterIsNotValid');
		case 3000:
			return t<string>('requiredParametersAreMissing');
		case 3001:
			return t<string>('pleaseEnterValidEmailAddress');
		case 3002:
			return t<string>('passwordDoesNotMatchRequirements');
		case 3003:
			return t<string>('invalidMerchantOfferRequest');
		case 3004:
			return t<string>('invalidTradingAccountGroup');
		case 2016:
			return t<string>('countryIsNotAllowed');
		case 2017:
			return t<string>('orderNumberProvidedIsInvalid');
		case 2018:
			return t<string>('orderIsExpiredOrClosed');
		case 2019:
			return t<string>('actionNotApplicable');
		case 2000:
			return t<string>('errorOccurredWhileProcessingYourRequest');
		case 2001:
			return t<string>('problemOccurredWhileLoadingRecords');
		case 2002:
			return t<string>('problemOccurredWhileLoadingRecord');
		case 2003:
			return t<string>('problemOccurredWhileAddingRecord');
		case 2004:
			return t<string>('problemOccurredWhileUpdatingRecord');
		case 2005:
			return t<string>('problemOccurredWhileDeletingRecord');
		case 2006:
			return t<string>('recordAlreadyExists');
		case 2007:
			return t<string>('noRecordFound');
		case 2008:
			return t<string>('accessDeniedMissingKyc');
		case 2009:
			return t<string>('invalidCredentials');
		case 2010:
			return t<string>('invalidPaymentMethod');
		case 2011:
			return t<string>('invalidOfferType');
		case 2012:
			return t<string>('orderIsInProgress');
		case 2013:
			return t<string>('orderReviewIsAlreadyPresent');
		case 2014:
			return t<string>('orderIsClosedMoreThanWeekAgo');
		case 2015:
			return t<string>('userIsInactive');
		case 2020:
			return t<string>('invalidOrderAction');
		case 2021:
			return t<string>('onlyOneOrderAllowedAtTime');
		case 2022:
			return t<string>('invalidTradingAccount');
		case 2023:
			return t<string>('merchantBelongsToDifferentCountry');
		case 2024:
			return t<string>('orderAmountIsMoreThanAvailableBalance');
		case 2025:
			return t<string>('merchantOfferNotAvailable');
		case 2026:
			return t<string>('amountDoesNotSatisfyAvailableLimit');
		case 2027:
			return t<string>('errorProccessingMT5');
		case 2028:
			return t<string>('amountDosentSatisfyLimit');
		case 2029:
			return t<string>('chatHasBeenClosed');
		case 2030:
			return t<string>('invalidPaymentMethod');
		case 2031:
			return t<string>('emailAlreadyExist');
		case 2032:
			return t<string>('phoneNumberDoesNotMatchTheRequirement');
		case 2033:
			return t<string>('newPasswordAndConfirmPasswordNotSame');
		case 2034:
			return t<string>('userIsSuspended');
		case 2035:
			return t<string>('userIsBlocked');
		case 2036:
			return t<string>('merchantNotFound');
		case 2037:
		case 2051:
			return t<string>('invalidOTP');
		case 2038:
			return t<string>('cannotEnableOffer');
		case 2046:
			return t<string>('OTPIsrequired');
		case 2047:
			return t<string>('Pleaseuploadthepaymentproof');
		case 2048:
			return t<string>('sessionExpired');
		case 2050:
			return t<string>('otpexpired');
		case 2052:
			return t<string>('reachedotplimit');
		default:
			return t<string>('somethingWentWrong');
	}
}
