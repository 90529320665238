export enum OrderType {
	sell = 'Sell',
	buy = 'Buy',
}

export enum OrdersFilters {
	idDesc = 'id-desc',
	idAsc = 'id-asc',
	clientDesc = 'client-desc',
	clientAsc = 'client-asc',
	merchantsAsc = 'merchants-asc',
	merchantsDesc = 'merchants-desc',
	typeAsc = 'type-asc',
	typeDesc = 'type-desc',
	amountAsc = 'amount-asc',
	amountDesc = 'amount-desc',
	priceDesc = 'price-desc',
	priceAsc = 'price-asc',
	statusAsc = 'status-asc',
	statusDesc = 'status-desc',
	dateAndTimeAsc = 'createdAt-asc',
	dateAndTimeDesc = 'createdAt-desc',
}

export type Order = {
	orderNumber: number;
	price: string;
	amount: string;
	quantity: string;
	status: string;
	createdAt: string;
	note: string;
	type: string;
	clientId: number;
	clientFullName: string;
	merchantId: number;
	merchantFullName: string;
};
