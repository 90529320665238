import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './AccountGroups.scss';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { getAccountGroupColumns } from '../../helpers/accountGroupsColumns';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { AccountGroup } from '../../models/AccountGroup';

const AccountGroups = () => {
	const { t } = useTranslation();
	const [accountGroupsLoading, setAccountGroupsLoading] = useState(false);
	const [accountGroups, setAccountGroups] = useState<AccountGroup[]>([]);
	const [sortBy, setSortBy] = useState('');
	const token = getUserInfo()?.token;
	const reloadDataCallback = () => {
		setAccountGroupsLoading(true);
		axios
			.get(`${API_ENDPOINTS.accountGroupsLimits}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((data) => {
				setAccountGroupsLoading(false);
				setAccountGroups(
					data.data.map((el: any) => {
						return {
							tradingPlatform: el.tradingPlatform,
							tradingAccountGroup: el.group,
							...el,
						};
					}),
				);
			})
			.catch((err) => console.error(err));
	};
	useEffect(() => {
		setAccountGroupsLoading(true);
		axios
			.get(`${API_ENDPOINTS.accountGroupsLimits}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((data) => {
				setAccountGroupsLoading(false);
				setAccountGroups(
					data.data.map((el: any) => {
						return {
							tradingPlatform: el.tradingPlatform,
							tradingAccountGroup: el.group,
							...el,
						};
					}),
				);
			})
			.catch((err) => console.error(err));
	}, []);
	return (
		<div>
			<div className='merchants-head py-10'>
				<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center'>
					{t<string>('accountGroupLimits')}
				</h1>
				<p className='text-white text-sm text-center'>{t<string>('accountGroupLimitsSubtitle')}</p>
			</div>
			<div className='max-w-[1440px] xss:px-[5px] mx-auto md:px-[75px]  pt-[30px] pb-20 bg-white'>
				<Table
					columns={getAccountGroupColumns(t, sortBy, setSortBy, reloadDataCallback, token)}
					dataSource={accountGroups}
					loading={accountGroupsLoading}
					tableLayout='auto'
				/>
			</div>
		</div>
	);
};

export default AccountGroups;
