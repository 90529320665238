import './StateTimer.scss';

import { OrderStateType } from '../../../types/OrderStateType';
import { useEffect, useMemo, useState } from 'react';

import State from '../State/State';

type StateTimerProps = {
	orderState: OrderStateType;
	expireRemainingDuration: number;
	getOrderDetails: () => void;
};

const numberFormatter = (num: number) => {
	return `${num > 9 ? '' : '0'}${num}`;
};
const orderStateWithTimer: OrderStateType[] = ['PLACED', 'OPENED', 'TRANSFERRED'];

function StateTimer({ orderState, expireRemainingDuration, getOrderDetails }: StateTimerProps) {
	const showTimer = useMemo(
		() => orderStateWithTimer.includes(orderState) && expireRemainingDuration > 0,
		[orderState, expireRemainingDuration],
	);
	const [minutes, setMinutes] = useState('0');
	const [seconds, setSeconds] = useState('0');

	useEffect(() => {
		let end = Date.now() + expireRemainingDuration * 1000;

		const interval = setInterval(() => {
			let timeLeft = Math.floor((end - Date.now()) / 1000);

			if (timeLeft <= -3) {
				// 3sec to give sometime to the backend in case the timer on frontend was not correct
				clearInterval(interval);
			} else if (timeLeft > 0) {
				setMinutes(numberFormatter(Math.floor((timeLeft % 3600) / 60)));
				setSeconds(numberFormatter(timeLeft % 60));
			} else {
				if (expireRemainingDuration) {
					// get order details when the expiration time is over
					getOrderDetails();
				}
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [expireRemainingDuration]);

	return (
		<div className='order-state-timer-container mx-2'>
			<div className='state-timer-container'>
				<div className='state-timer-row'>
					<State orderState={orderState} size='big' />
				</div>
				{showTimer ? (
					<div className='timer-container'>
						<span>{minutes}</span> : <span>{seconds}</span>
					</div>
				) : null}
			</div>
		</div>
	);
}

export default StateTimer;
