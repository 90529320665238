import { MainState } from '../../models/main-state';
import { getUserInfo } from '../../helpers/localStorageHandler';

const userInfo = getUserInfo();

export const initialMainState: MainState = {
	isLoggedIn: Boolean(userInfo?.token) ?? false,
	email: userInfo?.email ?? '',
	fullName: userInfo?.fullName ?? '',
	userName: userInfo?.userName ?? '',
	countryCode: userInfo?.countryCode ?? '',
	role: userInfo?.role ?? '',
	canHandleAppeal: userInfo?.canHandleAppeal ?? false,
};

export const resetMainState: MainState = {
	...initialMainState,
	isLoggedIn: false,
};
