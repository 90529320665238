import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import {
	StopOutlined,
	KeyOutlined,
	PlusOutlined,
	EditFilled,
	CaretUpOutlined,
	CaretDownOutlined,
} from '@ant-design/icons';

import { Tooltip } from 'antd';
import './countryAndPaymMethodColumn.scss';

import { AdminUser } from '../models/AdminUsers';
import { handleSortClick } from './sorterColumn';

export const getAdminUsersColumn = (
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
	setUser: (user: AdminUser) => void,
	setShowModal: (value: boolean) => void,
	role: string,
) => {
	const columns: ColumnsType<AdminUser> = [
		{
			responsive: ['lg'],
			title: (
				<div>
					<Link to={`/add-admin`}>
						<PlusOutlined
							className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold'
							alt='add'
						/>
					</Link>
				</div>
			),
			dataIndex: 'add',
			key: 'add',
			ellipsis: true,
			render: (value, record) => {
				return (
					<div className='flex items-center w-[100%] justify-end'>
						{record.role === 'ADMIN' && role === 'SUPER_USER' && (
							<Tooltip placement='top' title={t<string>('passwordChange')}>
								<Link to={`/change-password?userAdmin=${record.id}`}>
									<KeyOutlined className='text-[18px] text-black mt-2' />
								</Link>
							</Tooltip>
						)}
						<Link to={`/add-admin?userAdmin=${record.id}`}>
							<EditFilled className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold' />
						</Link>
						<Tooltip placement='top' title={record.userStatus === 'ACTIVE' ? 'De-Activate' : 'Activate'}>
							<StopOutlined
								className='px-1'
								onClick={() => {
									setShowModal(true);
									setUser(record);
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('name')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'name-asc'))}
							style={{ color: sortBy === 'name-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'name-desc'))}
							style={{ color: sortBy === 'name-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			render: (value, record) => {
				return (
					<div className='flex py-1 pb-0 my-1'>
						<div className='text-[14px] text-[#00142A] flex items-center mb-1 font-medium'>{`${record.firstname} ${record.lastname}`}</div>
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('emailAddress')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'email-asc'))}
							style={{ color: sortBy === 'email-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'email-desc'))}
							style={{ color: sortBy === 'email-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'email-address',
			key: 'email-address',
			render: (value, record) => {
				return (
					<div className='flex py-1 pb-0 my-1'>
						<div className='text-[14px] text-[#626A74] flex items-center mb-1 font-medium'>{record.email}</div>
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('countryCode')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'countryCode-asc'))}
							style={{ color: sortBy === 'countryCode-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'countryCode-desc'))}
							style={{ color: sortBy === 'countryCode-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'country-code',
			key: 'country-code',
			render: (value, record) => (
				<div className='flex py-1 pb-0 my-1'>
					<div className='text-[14px] text-[#626A74] flex items-center mb-1 font-medium'>{record.countryCode}</div>
				</div>
			),
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('phoneNumber')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'phone-asc'))}
							style={{ color: sortBy === 'phone-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'phone-desc'))}
							style={{ color: sortBy === 'phone-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'phone',
			key: 'phone',
			render: (value, record) => {
				return <div className='text-[14px] font-medium text-[#000D1D99] flex items-center'>{record.phone}</div>;
			},
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('role')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'role-asc'))}
							style={{ color: sortBy === 'role-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'role-desc'))}
							style={{ color: sortBy === 'role-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'role',
			key: 'role',
			render: (value, record) => {
				return <div className='text-[14px] font-medium text-[#000D1D99] flex items-center'>{record.role}</div>;
			},
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('canHandleAppeal')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'canHandleAppeal-asc'))}
							style={{ color: sortBy === 'canHandleAppeal-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'canHandleAppeal-desc'))}
							style={{ color: sortBy === 'canHandleAppeal-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'canHandleAppeal',
			key: 'canHandleAppeal',
			render: (value, record) => {
				return (
					<div
						className='text-[14px] font-medium text-[#000D1D99] flex items-center'
						style={{ color: record.canHandleAppeals ? 'green' : 'red' }}
					>
						{record.canHandleAppeals ? t<string>('yes') : t<string>('no')}
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('status')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'userStatus-asc'))}
							style={{ color: sortBy === 'userStatus-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'userStatus-desc'))}
							style={{ color: sortBy === 'userStatus-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'status',
			key: 'status',
			render: (value, record) => {
				return (
					<div
						className='text-[14px] font-medium text-[#000D1D99] flex items-center'
						style={{ color: record.userStatus === 'ACTIVE' ? 'green' : 'red' }}
					>
						{record.userStatus}
					</div>
				);
			},
		},
		{
			dataIndex: 'record',
			className: 'small-screen-row',
			render: (value, row) => {
				return (
					<div className='flex py-2 pb-0 justify-between country-and-paym-table-col'>
						<div className='flex-col'>
							<div className='flex flex-row'>
								<div className='flex'>
									<Link to={`/add-admin?userAdmin=${row.id}`}>
										<EditFilled className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold' />
									</Link>
									<StopOutlined
										className='px-1'
										onClick={() => {
											setShowModal(true);
											setUser(row);
										}}
									/>
								</div>
								<div className='flex py-1 pb-0 my-1'>
									<div className='text-[14px] text-[#00142A] flex items-center mb-1 font-medium'>{`${row.firstname} ${row.lastname}`}</div>
								</div>
							</div>
							<div>
								<div className='text-xs text-[#00142A66] m-0  p-0'>
									{t<string>('emailAddress')}
									<span className='inline-block mx-1 text-[#000] text-[13px]'>{row.email}</span>
								</div>
							</div>
							<div className='flex items-center h-6'>
								<span className='text-xs text-[#00142A66] '>{t<string>('countryCode')}</span>
								<span className='text-xs text-lightBlue mx-2'>{row.countryCode}</span>
							</div>
							<div className='flex items-center h-6'>
								<div className='text-xs text-[#00142A66] m-0  p-0'>
									{t<string>('phoneNumber')}
									<span className='inline-block mx-1 text-[#000] text-[13px]'>{row.phone}</span>
								</div>
							</div>
							<div className='flex items-center h-6'>
								<span className='text-xs text-[#00142A66] '>{t<string>('canHandleAppeal')}</span>
								<span
									className='text-xs text-lightBlue mx-2'
									style={{ color: row.canHandleAppeals ? 'green' : 'red' }}
								>{`${row.canHandleAppeals}`}</span>
							</div>
							<div className='flex items-center h-6'>
								<span className='text-xs text-[#00142A66] '>{t<string>('role')}</span>
								<span className='text-xs text-lightBlue mx-2'>{`${row.role}`}</span>
							</div>
							<div className='flex items-center h-6'>
								<span className='text-xs text-[#00142A66] '>{t<string>('status')}</span>
								<span className='text-xs text-lightBlue mx-2'>{`${row.userStatus}`}</span>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return columns;
};
