import { OrderFeedbackType } from '../../../types/OrderFeedbackType';
import { ActionTypeAdmin } from '../../../types/ActionType';
import { OrderStateType } from '../../../types/OrderStateType';
import { OrderType } from '../../../types/OrderType';
import ActionsCorner from './ActionsCorner/ActionsCorner';
import './BodyDetails.scss';
import OrderDetails from './OrderDetails/OrderDetails';
import PaymentMethodDetails from './PaymentMethodDetails/PaymentMethodDetails';
import OrderHistoryDetails from './OrderHistory/OrderHistory';
import { OrderHistory } from '../../../types/OrderHistory';
import { OrderDetailsType } from '../../../types/OrderDetailsType';
import Stomp from 'stompjs';

type BodyDetailsProps = {
	unitPrice: string;
	quantity: string;
	amount: string;
	feedback: OrderFeedbackType;
	paymentMehodId: number;
	accountNNumber: string;
	orderState: OrderStateType;
	orderNumber: string;
	actions: ActionTypeAdmin[];
	orderType: OrderType;
	merchantId: string;
	userName: string;
	note: string;
	isActionsDisabled: boolean;
	orderHistory: OrderHistory[];
	appealTask: OrderDetailsType['appealTask'];
	triggerAction: (action: ActionTypeAdmin, otp?: string) => void;
	getOrderDetails: () => void;
	paymentProof: boolean;
	paymentProofFileId: string;
	paymentProofFileLabel: string;
	otpVlaid: boolean;
	loading: boolean;
	stompClient: Stomp.Client | null;
	accountGroup: string;
};

function BodyDetails({
	orderState,
	unitPrice,
	quantity,
	amount,
	feedback,
	paymentMehodId,
	accountNNumber,
	orderNumber,
	actions,
	orderType,
	merchantId,
	userName,
	note,
	isActionsDisabled,
	triggerAction,
	getOrderDetails,
	orderHistory,
	appealTask,
	paymentProof,
	paymentProofFileId,
	paymentProofFileLabel,
	otpVlaid,
	loading,
	stompClient,
	accountGroup,
}: BodyDetailsProps) {
	return (
		<div className='body-details-wrapper'>
			<OrderDetails unitPrice={unitPrice} quantity={quantity} amount={amount} note={note} />
			<PaymentMethodDetails
				paymentMehodId={paymentMehodId}
				accountNNumber={accountNNumber}
				amount={amount}
				quantity={quantity}
				orderType={orderType}
				paymentProof={paymentProof}
				paymentProofFileId={paymentProofFileId}
				paymentProofFileLabel={paymentProofFileLabel}
				orderNumber={orderNumber}
				accountGroup={accountGroup}
			/>
			<OrderHistoryDetails orderHistory={orderHistory} />
			<ActionsCorner
				orderState={orderState}
				orderType={orderType}
				feedback={feedback}
				actions={actions}
				orderNumber={orderNumber}
				merchantId={merchantId}
				quantity={quantity}
				amount={amount}
				userName={userName}
				isActionsDisabled={isActionsDisabled}
				triggerAction={triggerAction}
				getOrderDetails={getOrderDetails}
				appealTask={appealTask}
				otpVlaid={otpVlaid}
				loading={loading}
				stompClient={stompClient}
			/>
		</div>
	);
}

export default BodyDetails;
