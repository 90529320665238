import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';

const Footer = () => {
	const currentYear = React.useMemo(() => new Date().getFullYear(), []);
	const { t } = useTranslation();

	return (
		<div className='footer'>
			<div className='footer-tail bg-lightBlue py-4'>
				<div className='max-w-[1440px] mx-auto flex flex-col sm:flex-row items-center justify-between'>
					<span className='text-white text-xs lg:mb-0'>
						© {currentYear} Webbats.com. {t<string>('allRightsReserved')}.
					</span>
				</div>
			</div>
		</div>
	);
};

export default Footer;
