import { API_ENDPOINTS } from '../assets/api/endpoints';
import { countryAndPaymentMethodMock } from '../mocks/CountryAndPaymentMethod';
import { merchantAccountsMock } from '../mocks/accountsMock';
import { countriesMock } from '../mocks/countriesMock';
import { merchantsMock } from '../mocks/merchantsMock';
import { ordersMock } from '../mocks/ordersMock';
import { paymentMehodsMock } from '../mocks/paymentMehodsMock';

export async function getDataSimulator(endpointUrl: string, errorMessage: string = '', timer: number = 3000) {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			if (!errorMessage) {
				resolve(getDataFromMocks(endpointUrl));
			} else {
				reject({
					errorCode: 123456,
					errorMessage,
				});
			}
		}, timer);
	});
}

function getDataFromMocks(endpointUrl: string) {
	switch (endpointUrl) {
		case API_ENDPOINTS.orders:
			return ordersMock;
		case API_ENDPOINTS.countries:
			return countriesMock;
		case API_ENDPOINTS.paymentMethods:
			return paymentMehodsMock;
		case API_ENDPOINTS.countryAndPaymMethod:
			return countryAndPaymentMethodMock;
		default:
			return {};
	}
}
