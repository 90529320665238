import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { Order, OrderType } from '../../models/Orders';
import { Button, Dropdown, Form, Input, MenuProps, Select, Table, DatePicker } from 'antd';

import dayjs, { Dayjs } from 'dayjs';
import { getOrderColumns } from '../../helpers/ordersTableColumns';
import OrderHeader from '../../components/Orders/OrdersHeader';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Row, Col } from 'antd';
import './Orders.scss';
import { DownOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useSearchParams, useLocation } from 'react-router-dom';
import { Country } from '../../models/Country';
import { PaymentMethod } from '../../models/PaymentMethod';
import { useCountriesContext } from '../../store/CountriesContext';

const DATE_FORMAT = 'YYYY-MM-DD';

function Orders() {
	const { countriesState } = useCountriesContext();
	const [searchParams, setSearchParam] = useSearchParams();
	const [pagesize, setPageSize] = useState(10);
	const [orderStatusFilter, setOrderStatusFilter] = useState<string>(searchParams.get('orderStatusFilter') || 'All');
	const [startDateFilter, setStartDateFilter] = useState<string>(searchParams.get('startDateFilter') || '');
	const [endDateFilter, setEndDateFilter] = useState<string>(searchParams.get('endDateFilter') || '');
	const { t } = useTranslation();
	const [orders, setOrders] = useState([]);
	const [ordersLoading, setOrdersLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [sortBy, setSortBy] = useState<string>('');
	const [order, setOrder] = useState<Order | null>(null);
	const [totalElements, setTotalElements] = useState(0);
	const [page, setPage] = useState(0);
	const [offerTypeFilter, setOfferTypeFilter] = useState(searchParams.get('offerTypeFilter') || 'All');
	const [searchValue, setSearchValue] = useState(searchParams.get('searchValue') || '');
	const [countries, setCountries] = useState<Country[]>([]);
	const [countriesLoading, setCountriesLoading] = useState<boolean>(false);
	const [countryFilter, setCountryFilter] = useState<string>(searchParams.get('country') || 'All');
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
	const [paymentMethodsLoading, setPaymentMethodsLoading] = useState<boolean>(false);
	const [paymentMethodFilter, setPaymentMethodFilter] = useState<any>(
		Number(searchParams.get('payment-method')) || 'All',
	);
	const location = useLocation();
	const today = new Date();
	const lastWeek = new Date(today);
	lastWeek.setDate(today.getDate() - 7);

	const isDev = window.location.hostname.includes('devb');

	const [selectedDates, setSelectedDates] = useState<[Dayjs, Dayjs]>([dayjs(today), dayjs(lastWeek)]);

	const { RangePicker } = DatePicker;

	const pageSizeItems: MenuProps['items'] = [
		{ label: '10', key: '1', onClick: () => setPageSize(10) },
		{ label: '20', key: '2', onClick: () => setPageSize(20) },
		{ label: '50', key: '3', onClick: () => setPageSize(50) },
		{ label: '100', key: '4', onClick: () => setPageSize(100) },
	];

	const token = getUserInfo()?.token;
	useEffect(() => {
		setCountriesLoading(true);
		setPaymentMethodsLoading(true);
		axios
			.get(`${API_ENDPOINTS.countries}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				let paymentMethods: any[] = [{ methodName: t<string>('all') }];
				const countriesData = res.data?.map((el: any) => {
					paymentMethods = [...paymentMethods, ...el.paymentMethods];
					return {
						name: el.countryName,
						code: el.countryCode,
					};
				});
				setPaymentMethods(paymentMethods);
				setCountries([{ name: t<string>('all'), code: 'All' }, ...countriesData]);
				setCountriesLoading(false);
				setPaymentMethodsLoading(false);
			})
			.catch((err) => {
				setCountriesLoading(false);
				console.error(err);
			});
	}, []);
	useEffect(() => {
		setOrdersLoading(true);
		let TypeValue = offerTypeFilter === 'All' ? '' : `type%3A${offerTypeFilter}`;
		let orderStatus =
			orderStatusFilter !== 'ALL' && orderStatusFilter !== 'All' && orderStatusFilter !== ''
				? `status%3A${orderStatusFilter}`
				: '';
		let startDate =
			startDateFilter && !endDateFilter
				? `&search=createdAt%3E${dayjs(startDateFilter).toISOString().replace(/T/, ' ').replace(/\..+/, '')}`
				: '';
		let endDate =
			!startDateFilter && endDateFilter
				? `&search=createdAt%3C${dayjs(endDateFilter).toISOString().replace(/T/, ' ').replace(/\..+/, '')}`
				: '';
		let dateRange =
			startDateFilter && endDateFilter
				? `&search=createdAt%3E${dayjs(startDateFilter)
						.toISOString()
						.replace(/T/, ' ')
						.replace(/\..+/, '')}%2CcreatedAt%3C${dayjs(endDateFilter)
						.toISOString()
						.replace(/T/, ' ')
						.replace(/\..+/, '')}`
				: '';
		let name = '';
		let sort = '&sort=createdAt-desc';
		if (sortBy.startsWith('client')) {
			sort = `&sort=client%23user%23fullName-${sortBy.split('-')[1]}`;
		} else if (sortBy.startsWith('merchants')) {
			sort = `&sort=merchant%23user%23fullName-${sortBy.split('-')[1]}`;
		} else if (sortBy) {
			sort = `&sort=${sortBy}`;
		}
		if (searchParams.get('clientId')) {
			name = `&search=client%23clientId%3A${searchParams.get('clientId')}`;
		} else if (searchParams.get('merchantId')) {
			name = `&search=merchant%23merchantId%3A${searchParams.get('merchantId')}`;
		}
		let paymentMethodsFilter = '';
		let countrtCodeFilter = '';
		let paymentMethod = paymentMethods.find((item) => item.methodId === paymentMethodFilter)?.methodName;
		
		if (countryFilter && !countryFilter.startsWith('All')) {
			countrtCodeFilter += `paymentMethod%23country%23countryCode%3B${countryFilter}`;
		}
		if (paymentMethod) {
			paymentMethodsFilter += `paymentMethod%23methodName%3B${paymentMethod}`;
		}
		let search = searchTerm ? `&wildSearch=${searchTerm}` : '';

		let filter;
		if (!orderStatus && !TypeValue && !paymentMethod && !countrtCodeFilter) {
			filter = '';
		} else {
			filter = `&search=${TypeValue && orderStatus ? `${orderStatus}%2C` : orderStatus}${
				paymentMethodsFilter && TypeValue ? `${TypeValue}%2C` : TypeValue
			}${
				countrtCodeFilter && paymentMethodsFilter ? `${paymentMethodsFilter}%2C` : paymentMethodsFilter
			}${countrtCodeFilter}`;
		}
		axios
			.get(
				`${API_ENDPOINTS.orders}?page=${page}&pageSize=${pagesize}${dateRange}${startDate}${endDate}${sort}${search}${name}${filter}`,
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.then((result) => {
				setOrders(result.data.data);
				setTotalElements(result.data.totalElements);
				setOrdersLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setOrdersLoading(false);
			});
	}, [pagesize, searchValue, sortBy, page, location]);

	const resetFilters = () => {
		setOrderStatusFilter('All');
		setOfferTypeFilter('All');
		setStartDateFilter('');
		setEndDateFilter('');
		setSearchTerm('');
		setSortBy('');
		setSelectedDates([dayjs(today), dayjs(lastWeek)]);
		setStartDateFilter('');
		setEndDateFilter('');
		setSearchParam('');
	};

	useEffect(() => {
		const params = new URLSearchParams(searchParams);
		if (orderStatusFilter) {
			orderStatusFilter !== 'All'
				? params.set('orderStatusFilter', orderStatusFilter)
				: params.delete('orderStatusFilter');
			setSearchParam(params);
		}
		if (paymentMethodFilter) {
			paymentMethodFilter !== 'All'
				? params.set('payment-method', paymentMethodFilter)
				: params.delete('payment-method');
			setSearchParam(params);
		}
		if (countryFilter) {
			countryFilter !== 'All' ? params.set('country', countryFilter) : params.delete('country');
			setSearchParam(params);
		}

		if (startDateFilter) {
			startDateFilter !== '' ? params.set('startDateFilter', startDateFilter) : params.delete('startDateFilter');
			setSearchParam(params);
		}
		if (endDateFilter) {
			endDateFilter !== '' ? params.set('endDateFilter', endDateFilter) : params.delete('endDateFilter');
			setSearchParam(params);
		}
		if (searchValue) {
			searchValue !== '' ? params.set('searchValue', searchValue) : params.delete('search');
			setSearchParam(params);
		}
		if (offerTypeFilter) {
			offerTypeFilter !== 'All' ? params.set('offerTypeFilter', offerTypeFilter) : params.delete('offerTypeFilter');
			setSearchParam(params);
		}
	}, [
		orderStatusFilter,
		startDateFilter,
		searchValue,
		endDateFilter,
		searchValue,
		offerTypeFilter,
		countryFilter,
		paymentMethodFilter,
		countries,
	]);
	const getColor = (type: string) => {
		if (type === OrderType.buy) {
			return 'green';
		} else if (type === OrderType.sell) {
			return 'red';
		} else {
			return 'black';
		}
	};

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			setSearchValue(searchTerm);
		}, 1000);
		return () => clearTimeout(delaySearch);
	}, [searchTerm]);

	useEffect(() => {
		setPage(0);
		setPageSize(10);
	}, [orderStatusFilter, endDateFilter, startDateFilter, searchValue, offerTypeFilter]);

	useEffect(() => {
		const country: any = countriesState.countries.find((item) => item.countryCode === countryFilter);
		const methods = country?.paymentMethods
			? country.paymentMethods
			: [{ methodName: t<string>('all') }, ...countriesState.paymentMethods];
		setPaymentMethods(methods);
	}, [countryFilter, countries, countriesState.paymentMethods, t]);
	return (
		<div>
			<OrderHeader />
			<div className='max-w-[1440px] mx-auto xss:px-[20px] sm:px-[75px] pt-[30px] pb-10 bg-white'>
				<div className='border-[#000d1d]/10 rounded-md shadow-md py-3 pb-0 px-4 mb-6 border border-solid border-gray-200'>
					<Form layout='vertical'>
						<Row gutter={16}>
							<Col xs={24} md={12} lg={8}>
								<Form.Item label={t<string>('type')}>
									<Select defaultValue={''} value={offerTypeFilter} onChange={(e: string) => setOfferTypeFilter(e)}>
										{[
											{
												TypeName: 'All',
												value: 'All',
											},
											{
												TypeName: isDev ? t<string>('buy') : t<string>('deposit'),
												value: 'BUY',
											},
											{
												TypeName: isDev ? t<string>('Sell') : t<string>('withdrawal'),
												value: 'SELL',
											},
										].map((item, index) => (
											<Select.Option key={index} value={item.value} style={{ color: getColor(item.TypeName) }}>
												{item.TypeName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={8}>
								<Form.Item label={t<string>('countries')}>
									<Select
										defaultValue='All'
										value={countryFilter}
										loading={countriesLoading}
										onChange={(e: string) => {
											setCountryFilter(e);
										}}
										showSearch
										filterOption={(inputValue, option: any) =>
											option?.children ? option.children.toLowerCase().includes(inputValue.toLowerCase()) : false
										}
									>
										{countries.map((item, index) => (
											<Select.Option key={index} value={item.code}>
												{item.name !== 'All' && (
													<img
														src={`https://flagsapi.com/${item.code}/shiny/64.png`}
														alt='country'
														className='country-flag'
													/>
												)}
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={8}>
								<Form.Item label={t<string>('paymentMethods')}>
									<Select
										defaultValue='All'
										value={paymentMethodFilter}
										loading={paymentMethodsLoading}
										onChange={(e: string) => {
											setPaymentMethodFilter(e);
										}}
										showSearch
										filterOption={(inputValue, option: any) =>
											option?.children ? option.children.toLowerCase().includes(inputValue.toLowerCase()) : false
										}
									>
										{paymentMethods.map((item, index) => (
											<Select.Option key={index} value={item.methodId}>
												{item.methodName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={12} lg={8}>
								<Form.Item label={t<string>('status')}>
									<Select
										defaultValue={'ALL'}
										value={orderStatusFilter}
										onChange={(e: string) => setOrderStatusFilter(e)}
									>
										{[
											{ id: 0, status: 'All', key: 'ALL' },
											{ id: 1, status: 'Placed', key: 'PLACED' },
											{ id: 2, status: 'Opened', key: 'OPENED' },
											{ id: 3, status: 'Transferred', key: 'TRANSFERRED' },
											{ id: 4, status: 'Completed', key: 'COMPLETED' },
											{ id: 5, status: 'Cancelled', key: 'CANCELLED' },
											{ id: 6, status: 'Expired', key: 'EXPIRED' },
											{ id: 7, status: 'Appealed', key: 'APPEAL' },
											{ id: 8, status: 'Rejected', key: 'REJECTED' },
										].map((item, index) => (
											<Select.Option key={index} value={item.key}>
												{item.status}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={24} lg={8}>
								<Form.Item label={t<string>('dateRange')}>
									<RangePicker
										value={
											[
												startDateFilter ? dayjs(startDateFilter, DATE_FORMAT) : dayjs(selectedDates[0], DATE_FORMAT),
												endDateFilter ? dayjs(endDateFilter, DATE_FORMAT) : dayjs(selectedDates[1], DATE_FORMAT),
											] as any
										}
										onChange={(values) => {
											if (values && values.length === 2) {
												const startDate = dayjs(values[0]).format('YYYY-MM-DD 00:00:00');
												const endDate = dayjs(values[1]).format('YYYY-MM-DD 23:59:00');
												setSelectedDates([dayjs(values[0]), dayjs(values[1])]);
												setStartDateFilter(startDate);
												setEndDateFilter(endDate);
											} else {
												setSelectedDates([dayjs(), dayjs()]);
												setStartDateFilter('');
												setEndDateFilter('');
											}
										}}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
				<div className='mb-4 flex items-center justify-between w-full  xss:flex-col md:flex-row md:flex-wrap '>
					<div className='flex items-center'>
						<span className='text-[#000D1D99] text-sm '>{t<string>('show')}</span>
						<Dropdown className='mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
							<span className='bg-[#F5F5F5] h-10 w-24 flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer'>
								{pagesize}
								<DownOutlined className='w-2 ' style={{ fontSize: 8 }} />
							</span>
						</Dropdown>
						<span className='text-[#000D1D99] text-sm '>{t<string>('entries')}</span>
					</div>
					<div className='flex items-center xss:my-4 lg:my-0'>
						<Button
							onClick={resetFilters}
							className='h-10 w-10 border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px] mr-2'
						>
							<ReloadOutlined className='h-[14px] w-[14px]' />
						</Button>
						<Input
							className='border-none h-10 bg-[#F5F5F5] search-input mx-2'
							placeholder={t<string>('searchOrder')}
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
							size='large'
							prefix={<SearchOutlined className='mr-2' />}
						/>
					</div>
				</div>
				<Table
					columns={getOrderColumns(t, sortBy, setSortBy, setOrder)}
					dataSource={orders}
					loading={ordersLoading}
					pagination={{
						pageSize: pagesize,
						total: totalElements,
						showSizeChanger: false,
						onChange: (pageIndex) => setPage(pageIndex - 1),
						current: page + 1,
					}}
					tableLayout='auto'
				/>
			</div>
		</div>
	);
}

export default Orders;
