import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import SortArrow from '../assets/images/icons/SortArrow';
import { CountryAndPaymentMethod } from '../models/CountryAndPaymentMethod';

import {
	EyeOutlined,
	PlusOutlined,
	EditFilled,
	CheckCircleOutlined,
	CloseCircleOutlined,
	CaretUpOutlined,
	CaretDownOutlined,
} from '@ant-design/icons';

import { Tooltip } from 'antd';
import './countryAndPaymMethodColumn.scss';
import { handleSortClick } from './sorterColumn';

export const getCountryAndPaymMethodColumn = (
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
) => {
	const columns: ColumnsType<CountryAndPaymentMethod> = [
		{
			responsive: ['lg'],
			title: (
				<div>
					<Link to={`/add-country`}>
						<PlusOutlined className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold' />
					</Link>
				</div>
			),
			dataIndex: 'add',
			key: 'add',
			ellipsis: true,
			render: (value, record) => {
				return (
					<div>
						<Link to={`/countries/${record.countryCode}`}>
							<EditFilled className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold' />
						</Link>
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('name')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'name-asc'))}
							style={{ color: sortBy === 'name-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'name-desc'))}
							style={{ color: sortBy === 'name-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			render: (value, record) => {
				return (
					<div className='flex py-1 pb-0 my-1'>
						<div className='text-[14px] text-[#00142A] flex items-center mb-1 font-medium'>{record.countryName}</div>
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('countryCode')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'country-code-asc'))}
							style={{ color: sortBy === 'country-code-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'country-code-desc'))}
							style={{ color: sortBy === 'country-code-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'country-code',
			key: 'country-code',
			render: (value, record) => {
				return (
					<div className='flex py-1 pb-0 my-1'>
						<div className='text-[14px] text-[#626A74] flex items-center mb-1 font-medium'>{record.countryCode}</div>
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('currencyCode')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'currency-code-asc'))}
							style={{ color: sortBy === 'currency-code-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'currency-code-desc'))}
							style={{ color: sortBy === 'currency-code-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'currency-code',
			key: 'currency-code',
			render: (value, record) => (
				<div className='flex py-1 pb-0 my-1'>
					<div className='text-[14px] text-[#626A74] flex items-center mb-1 font-medium'>{record.currencyCode}</div>
				</div>
			),
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('maxProfitRatio')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'max-profit-asc'))}
							style={{ color: sortBy === 'max-profit-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'max-profit-desc'))}
							style={{ color: sortBy === 'max-profit-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'max-profit',
			key: 'max-profit',
			render: (value, record) => {
				return (
					<div className='text-[14px] font-medium text-[#000D1D99] flex items-center'>
						{record.maxProfitRatio * 100}
						<span className='inline-block mx-1 text-[grey] text-[14px]'>%</span>
					</div>
				);
			},
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('paymentMethod')}
				</div>
			),
			dataIndex: 'paymentMethods',
			key: 'paymentMethods',
			render: (value, record) => (
				<span style={{ cursor: 'pointer' }} className='ml-5'>
					<Tooltip
						placement='bottomRight'
						title={
							<li>
								{record.paymentMethods.map((el) => (
									<span className='block'>{el.methodName}</span>
								))}
							</li>
						}
					>
						<EyeOutlined />
					</Tooltip>
				</span>
			),
			ellipsis: true,
		},
		{
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('public')}{' '}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'is-public-asc'))}
							style={{ color: sortBy === 'is-public-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'is-public-desc'))}
							style={{ color: sortBy === 'is-public-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'isPublic',
			key: 'isPublic',
			render: (value, record) => {
				return (
					<div className='text-[19px] font-medium text-lightBlue flex items-center'>
						{record.isPublic ? (
							<CheckCircleOutlined className='h-[20px] w-[20px] mx-2 text-[#33A867]' />
						) : (
							<CloseCircleOutlined className='h-[20px] w-[20px] mx-2 text-[#F6465D]' />
						)}
					</div>
				);
			},
		},
		{
			dataIndex: 'record',
			title: (
				<div>
					<Link to={`/add-country`}>
						<PlusOutlined className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold' />
					</Link>
				</div>
			),
			className: 'small-screen-row',
			render: (value, row) => {
				return (
					<div className='flex py-2 pb-0 justify-between'>
						<div className='flex-col'>
							<div className='flex flex-row'>
								<div>
									<Link to={`/countries/${row.countryCode}`}>
										<EditFilled className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold' />
									</Link>
								</div>
								<div className='flex py-1 pb-0 my-1'>
									<div className='text-[14px] text-[#00142A] flex items-center mb-1 font-medium'>{row.countryName}</div>
								</div>
							</div>
							<div>
								<div className='text-xs text-[#00142A66] m-0  p-0'>
									{t<string>('countryCode')}
									<span className='inline-block mx-1 text-[#000] text-[13px]'>{row.countryCode}</span>
								</div>
							</div>
							<div className='flex items-center h-6'>
								<span className='text-xs text-[#00142A66] '>{t<string>('currencyCode')}</span>
								<span className='text-xs text-lightBlue mx-2'>{row.currencyCode}</span>
							</div>
							<div className='flex items-center h-6'>
								<div className='text-xs text-[#00142A66] m-0  p-0'>
									{t<string>('maxProfitRatio')}
									<span className='inline-block mx-1 text-[#000] text-[13px]'>{row.maxProfitRatio * 100} %</span>
								</div>
							</div>
						</div>
						<div className='flex-col'>
							<div className='text-[19px] font-medium text-lightBlue flex items-center p-5'>
								<span style={{ cursor: 'pointer' }} className='ml-5'>
									<Tooltip
										placement='bottomRight'
										title={
											row.paymentMethods.length === 0 ? (
												<li>{t<string>('noPaymentMethod')}</li>
											) : (
												<li>
													{row.paymentMethods.map((el) => (
														<span className='block'>{el.methodName}</span>
													))}
												</li>
											)
										}
									>
										<EyeOutlined />
									</Tooltip>
								</span>
								<div className='text-[19px] font-medium text-lightBlue flex items-center p-5'>
									{row.isPublic ? (
										<CheckCircleOutlined className='h-[20px] w-[20px] mx-2 text-[#33A867]' />
									) : (
										<CloseCircleOutlined className='h-[20px] w-[20px] mx-2 text-[#F6465D]' />
									)}
								</div>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return columns;
};
