import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { EditFilled, DeleteFilled, PlusOutlined } from '@ant-design/icons';
import {
	handleCountrySortClick,
	handleCreatedAtSortClick,
	handleFullNameSortClick,
	handlePhoneNumberSortClick,
} from './handleClientsTableSortClick';
import { Link } from 'react-router-dom';
import { AccountGroup } from '../models/AccountGroup';
import { Modal } from 'antd';
import axios from 'axios';
import { API_ENDPOINTS } from '../assets/api/endpoints';

import EditIcon from '../assets/images/icons/edit-icon.svg';
export const getAccountGroupColumns = (
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
	reloadData: () => void,
	token: string | undefined,
) => {
	const showDeleteConfirmation = (tradingPlatform: any, tradingGroup: any) => {
		Modal.confirm({
			title: t('confirmDelete'),
			content: t('deleteConfirmation'),
			okText: t('ok'),
			cancelText: t('cancel'),
			onOk() {
				axios
					.delete(`${API_ENDPOINTS.accountGroupsLimits}`, {
						headers: { Authorization: `Bearer ${token}` },
						data: {
							group: tradingGroup,
							tradingPlatform: tradingPlatform,
						},
					})
					.then((res) => {
						reloadData();
					})
					.catch((err) => console.error(err));
			},
			onCancel() {
				Modal.destroyAll();
			},
		});
	};
	const columns: ColumnsType<AccountGroup> = [
		{
			title: (
				<div>
					<Link to={'/add-account-group'}>
						<PlusOutlined className='p-3 flex items-center cursor-pointer text-[20px] text-black font-bold' alt='add' />
					</Link>
				</div>
			),
			dataIndex: 'id',
			key: 'action',
			ellipsis: true,
			responsive: ['lg'],
			render: (value, record) => {
				return (
					<div className='flex mt-0 mb-0'>
						{record.tradingPlatform && record.tradingAccountGroup ? (
							<div className='flex items-center'>
								{' '}
								<Link
									to={`/account-groups/${record.tradingPlatform}?tradingAccountGroup=${
										record.tradingAccountGroup
									}&buyMin=${record.buyMin ?? ''}&buyMax=${record.buyMax ?? ''}&sellMin=${
										record.sellMin ?? ''
									}&sellMax=${record.sellMax ?? ''}`}
								>
									<EditFilled className='p-3 flex items-center cursor-pointer text-[20px] text-black font-bold' />
								</Link>
								<DeleteFilled
									onClick={() => showDeleteConfirmation(record.tradingPlatform, record.tradingAccountGroup)}
									className='hover:text-[#F6465D] text-[#000D1D99]  text-xl h-4 w-4 cursor-pointer'
								/>
							</div>
						) : (
							<>
								{!record.tradingPlatform && !record.tradingAccountGroup ? (
									<div className='flex items-center justify-center w-[100%]'>
										{' '}
										<DeleteFilled
											onClick={() => showDeleteConfirmation(record.tradingPlatform, record.tradingAccountGroup)}
											className='hover:text-[#F6465D] text-[#000D1D99]  text-xl h-4 w-4 cursor-pointer'
										/>
									</div>
								) : (
									<>
										<Link
											to={`/account-groups/${record.tradingPlatform}?tradingAccountGroup=${
												record.tradingAccountGroup
											}&buyMin=${record.buyMin ?? ''}&buyMax=${record.buyMax ?? ''}&sellMin=${
												record.sellMin ?? ''
											}&sellMax=${record.sellMax ?? ''}`}
										>
											<EditFilled className='p-3 flex items-center cursor-pointer text-[20px] text-black font-bold' />
										</Link>
										<DeleteFilled
											onClick={() => showDeleteConfirmation(record.tradingPlatform, record.tradingAccountGroup)}
											className='hover:text-[#F6465D] text-[#000D1D99]  text-xl h-4 w-4 cursor-pointer'
										/>
									</>
								)}
							</>
						)}
					</div>
				);
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99] mt-2 mb-2 text-sm flex items-center cursor-pointer'>
					{t<string>('tradingPlatform')}
				</div>
			),
			onHeaderCell: () => {
				return {
					onClick: () => {
						setSortBy(handleFullNameSortClick(sortBy));
					},
				};
			},
			dataIndex: 'tradingPlatform',
			key: 'tradingPlatform',
			ellipsis: true,
			responsive: ['lg'],
			render: (value, record) => {
				return (
					<div className='ml-5  mt-0 mb-0 flex'>
						<p className='text-lightBlue  text-[19px] font-bold mb-1'>
							{value ? value : <span className='italic font-light'>{t('public')}</span>}
						</p>
					</div>
				);
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99] mt-2 mb-2 text-sm flex items-center cursor-pointer'>
					{t<string>('tradingAccountGroup')}
				</div>
			),
			onHeaderCell: () => {
				return {
					onClick: () => {
						setSortBy(handlePhoneNumberSortClick(sortBy));
					},
				};
			},
			dataIndex: 'tradingAccountGroup',
			key: 'tradingAccountGroup',
			responsive: ['lg'],
			render: (value) => {
				return (
					<p className='ml-5 mt-0 mb-0 text-[19px] font-light text-[#999999] Blue flex items-center'>
						{value ? value : <span className='italic font-light'>Public</span>}
					</p>
				);
			},
		},
		{
			title: (
				<div className='text-[#000D1D99]  mt-2 mb-2 text-sm flex items-center cursor-pointer'>
					{t<string>('buyMin')}
				</div>
			),
			onHeaderCell: () => {
				return {
					onClick: () => {
						setSortBy(handleCountrySortClick(sortBy));
					},
				};
			},
			dataIndex: 'buyMin',
			key: 'buyMin',
			responsive: ['lg'],
			render: (row, record) => (
				<p className='ml-2 mt-0 mb-0 text-[19px] font-medium text-lightBlue flex items-center'>
					{record.buyMin?.toLocaleString()}
				</p>
			),
			ellipsis: true,
		},
		{
			title: (
				<div className='text-[#000D1D99]  mt-2 mb-2 text-sm flex items-center cursor-pointer'>
					{t<string>('buyMax')}
				</div>
			),
			onHeaderCell: () => {
				return {
					onClick: () => {
						setSortBy(handleCountrySortClick(sortBy));
					},
				};
			},
			dataIndex: 'buyMax',
			key: 'buyMax',
			responsive: ['lg'],
			render: (row, record) => (
				<p className='ml-2 mt-0 mb-0 text-[19px] font-medium text-lightBlue flex items-center'>
					{record.buyMax?.toLocaleString()}
				</p>
			),
			ellipsis: true,
		},
		{
			title: (
				<div className='text-[#000D1D99]  mt-2 mb-2 text-sm flex items-center cursor-pointer'>
					{t<string>('sellMin')}
				</div>
			),
			onHeaderCell: () => {
				return {
					onClick: () => {
						setSortBy(handleCountrySortClick(sortBy));
					},
				};
			},
			dataIndex: 'sellMin',
			key: 'sellMin',
			responsive: ['lg'],
			render: (row, record) => (
				<p className='ml-2 mt-0 mb-0 text-[19px] font-medium text-lightBlue flex items-center'>
					{record.sellMin?.toLocaleString()}
				</p>
			),
			ellipsis: true,
		},
		{
			title: (
				<div className='mt-2 mb-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('sellMax')}
				</div>
			),
			onHeaderCell: () => {
				return {
					onClick: () => {
						setSortBy(handleCreatedAtSortClick(sortBy));
					},
				};
			},
			dataIndex: 'sellMax',
			key: 'sellMax',
			responsive: ['lg'],
			render: (row, record) => (
				<p className='ml-2 mt-0 mb-0 text-[19px] font-medium text-lightBlue flex items-center'>
					{record.sellMax?.toLocaleString()}
				</p>
			),
		},
		{
			title: (
				<div>
					<Link to={'/add-account-group'}>
						<PlusOutlined className='p-3 flex items-center cursor-pointer text-[20px] text-black font-bold' />
					</Link>
				</div>
			),
			dataIndex: 'record',
			className: 'small-screen-row',
			render: (value, row) => {
				return (
					<div className='flex py-2 pb-0 justify-between'>
						<div className='flex-col'>
							<div className='text-sm text-[#00142A66] h-6'>
								<span className='text-[#00142A66] '>{t<string>('tradingPlatform')}</span>
								<span className='font-bold text-lightBlue mx-2'>
									{row.tradingPlatform ? row.tradingPlatform : t('public')}
								</span>
							</div>
							<div className='flex items-center h-6 mt-1'>
								<span className=' text-[#00142A66] '>{t<string>('tradingAccountGroup')}</span>
								<span className='font-bold text-lightBlue mx-2'>
									{row.tradingAccountGroup ? row.tradingAccountGroup : t('public')}
								</span>
							</div>
							<div className='flex items-center h-6 mt-1'>
								<span className=' text-[#00142A66] '>{t<string>('buyMin')}</span>
								<span className='font-bold text-lightBlue mx-2'>{row.buyMin?.toLocaleString()}</span>
							</div>
							<div className='flex items-center h-6 mt-1'>
								<span className='text-[#00142A66] '>{t<string>('buyMax')}</span>
								<span className='font-bold text-lightBlue mx-2'>{row.buyMax?.toLocaleString()}</span>
							</div>
							<div className='flex items-center h-6 mt-1'>
								<span className=' text-[#00142A66] '>{t<string>('sellMin')}</span>
								<span className='font-bold text-lightBlue mx-2'>{row.sellMin?.toLocaleString()}</span>
							</div>
							<div className='flex items-center h-6 mt-1'>
								<span className=' text-[#00142A66] '>{t<string>('sellMax')}</span>
								<span className='font-bold text-lightBlue mx-2'>{row.sellMax?.toLocaleString()}</span>
							</div>
						</div>
						<div className='flex mt-0 mb-0'>
							{row.tradingPlatform && row.tradingAccountGroup ? (
								<div className='flex items-center'>
									{' '}
									<Link
										to={`/account-groups/${row.tradingPlatform}?tradingAccountGroup=${row.tradingAccountGroup}&buyMin=${
											row.buyMin ?? ''
										}&buyMax=${row.buyMax ?? ''}&sellMin=${row.sellMin ?? ''}&sellMax=${row.sellMax ?? ''}`}
									>
										<EditFilled className='p-3 flex items-center cursor-pointer text-[20px] text-black font-bold' />
									</Link>
									<DeleteFilled
										onClick={() => showDeleteConfirmation(row.tradingPlatform, row.tradingAccountGroup)}
										className='hover:text-[#F6465D] text-[#000D1D99]  text-xl h-4 w-4 cursor-pointer'
									/>
								</div>
							) : (
								<>
									{!row.tradingPlatform && !row.tradingAccountGroup ? (
										<div className='flex items-center justify-center w-[100%]'>
											{' '}
											<DeleteFilled
												onClick={() => showDeleteConfirmation(row.tradingPlatform, row.tradingAccountGroup)}
												className='hover:text-[#F6465D] text-[#000D1D99]  text-xl h-4 w-4 cursor-pointer'
											/>
										</div>
									) : (
										<>
											<Link
												to={`/account-groups/${row.tradingPlatform}?tradingAccountGroup=${
													row.tradingAccountGroup
												}&buyMin=${row.buyMin ?? ''}&buyMax=${row.buyMax ?? ''}&sellMin=${row.sellMin ?? ''}&sellMax=${
													row.sellMax ?? ''
												}`}
											>
												<EditFilled className='p-3 flex items-center cursor-pointer text-[20px] text-black font-bold' />
											</Link>
											<DeleteFilled
												onClick={() => showDeleteConfirmation(row.tradingPlatform, row.tradingAccountGroup)}
												className='hover:text-[#F6465D] text-[#000D1D99]  text-xl h-4 w-4 cursor-pointer'
											/>
										</>
									)}
								</>
							)}
						</div>
					</div>
				);
			},
		},
	];

	return columns;
};
