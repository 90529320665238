import { Dropdown, Input, MenuProps, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDataSimulator } from '../../helpers/dataSimulator';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import CountriesAndPaymentMethodsHeader from '../../components/CountriesAndPaymentMethods/CountriesAndPaymentMethodsHeader/CountriesAndPaymentMethodsHeader';
import { getCountryAndPaymMethodColumn } from '../../helpers/countryAndPaymMethodColumn';
import { CountryAndPaymentMethod } from '../../models/CountryAndPaymentMethod';
import './CountriesAndPaymentMethods.scss';

const CountriesAndPaymentMethods = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const [countryAndPaymMethods, setCountryAndPaymMethods] = useState([]);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [pagesize, setPageSize] = useState(10);
	const [sortBy, setSortBy] = useState<string>('');
	const [countryAndPaymMethod, setCountryAndPaymMethod] = useState<CountryAndPaymentMethod | null>(null);

	useEffect(() => {
		setLoading(true);

		let url = API_ENDPOINTS.countryAndPaymMethod;
		let params = new URLSearchParams();
		params.set('limit', pagesize.toString());
		params.set('offset', '0');

		if (searchTerm && searchTerm !== '') {
			params.set('search', searchTerm);
		}

		if (sortBy && sortBy !== '') {
			params.set('sort', sortBy);
		}

		console.log(params.toString());

		Promise.all([
			getDataSimulator(url).catch((_) => setLoading(false)),
			getDataSimulator(API_ENDPOINTS.countryAndPaymMethod).catch((_) => setLoading(false)),
		]).then(([countryAndPaymMethodData]: any) => {
			setCountryAndPaymMethods(countryAndPaymMethodData);
			setLoading(false);
		});
	}, [pagesize, setLoading, searchTerm, sortBy]);

	const pageSizeItems: MenuProps['items'] = [
		{ label: '10', key: '1', onClick: () => setPageSize(10) },
		{ label: '20', key: '2', onClick: () => setPageSize(20) },
		{ label: '50', key: '3', onClick: () => setPageSize(50) },
		{ label: '100', key: '4', onClick: () => setPageSize(100) },
	];

	return (
		<div>
			<CountriesAndPaymentMethodsHeader />
			<div className='max-w-[1440px] mx-auto  xss:px-[10px] md:px-[75px] pt-[30px] pb-20 bg-white'>
				<div className='mb-10 flex items-center justify-between w-full'>
					<div className='flex items-center'>
						<span className='text-[#000D1D99] text-sm '>{t<string>('show')}</span>
						<Dropdown className='mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
							<span className='bg-[#F5F5F5] h-10 w-24 flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer'>
								{pagesize}
								<DownOutlined className='w-2 ' style={{ fontSize: 8 }} />
							</span>
						</Dropdown>
						<span className='text-[#000D1D99] text-sm '>{t<string>('entries')}</span>
					</div>
					<div className='flex items-center'>
						<Input
							className='border-none h-10 bg-[#F5F5F5] search-input'
							disabled={loading}
							placeholder={t<string>('searchInCountriesAndPaymTable')}
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
							size='large'
							prefix={<SearchOutlined className='mr-2' />}
						/>
					</div>
				</div>
				<Table
					columns={getCountryAndPaymMethodColumn(t, sortBy, setSortBy)}
					dataSource={countryAndPaymMethods}
					loading={loading}
					pagination={{ pageSize: pagesize }}
					tableLayout='auto'
				/>
			</div>
		</div>
	);
};

export default CountriesAndPaymentMethods;
