export enum AdminUserFilters {
	nameAsc = 'name-asc',
	nameDesc = 'name-desc',
	emailAddressAsc = 'email-asc',
	emailAddressDesc = 'email-desc',
	phoneNumberAsc = 'phone-asc',
	phoneNumberDesc = 'phone-desc',
	canHandleAppealAsc = 'canHandleAppeal-asc',
	canHandleAppealDesc = 'canHandleAppeal-desc',
	roleAsc = 'role-asc',
	roleDesc = 'role-desc',
	countryAsc = 'countryCode-asc',
	countryDesc = 'countryCode-desc',
	userStatusAsc = 'userStatus-asc',
	userStatusDesc = 'userStatus-desc',
}

export type AdminUser = {
	id: number;
	email: string;
	firstname: string;
	lastname: string;
	phone: string;
	countryCode: string;
	canHandleAppeals: boolean;
	role: string;
	userStatus: string;
};
