import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ChangePassword.scss';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Button, Form, Input, Spin, notification } from 'antd';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';

import { PASSWORD_VALIDATION } from '../../assets/config/validation-regex';
import { encryptData } from '../../helpers/passwordEncryptionHandler';

type Password = {
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
};

const ChangePassword = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const userData = getUserInfo();

	const onSubmit = (passwordValues: Password) => {
		setLoading(true);
		const encryptedNewPassword = encryptData(passwordValues.newPassword, userData?.email || '');
		const encryptedConfirmPassword = encryptData(passwordValues.confirmNewPassword, userData?.email || '');
		const encryptedoldPassword = encryptData(passwordValues.oldPassword, userData?.email || '');
		axios(`${API_ENDPOINTS.updatePassword}`, {
			method: 'POST',
			headers: { Authorization: `Bearer ${userData?.token}` },
			data: {
				newPassword: encryptedNewPassword,
				confirmNewPassword: encryptedConfirmPassword,
				oldPassword: encryptedoldPassword,
			},
		})
			.then((_) => notification['success']({ message: '', description: t<string>('UpdatedSuccessfully') }))
			.catch((err) => console.error(err))
			.finally(() => setLoading(false));
	};

	return (
		<div>
			<div className='merchants-head py-10'>
				<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center'>{t<string>('passwordChange')}</h1>
			</div>
			<div className='border border-solid border-[#E6E6E6] rounded-md p-4 max-w-[900px] mx-auto px-[75px] pt-[20px] pb-20 mt-16 bg-white'>
				<Spin spinning={loading}>
					<div style={{ margin: 'auto', maxWidth: '50%' }}>
						<Form layout='vertical' onFinish={onSubmit}>
							<div className='flex flex-wrap'>
								<div className='w-full'>
									<Form.Item
										label={<b>{t<string>('currentpassword')}</b>}
										name='oldPassword'
										rules={[
											{ required: true, message: t<string>('passwordRequired') },
											{
												pattern: PASSWORD_VALIDATION,
												message: t<string>('passwordMsg'),
											},
										]}
									>
										<Input.Password />
									</Form.Item>
								</div>
								<div className='w-full'>
									<Form.Item
										label={<b>{t<string>('newpassword')}</b>}
										name='newPassword'
										rules={[
											{ required: true, message: t<string>('passwordRequired') },
											{
												pattern: PASSWORD_VALIDATION,
												message: t<string>('passwordMsg'),
											},
										]}
									>
										<Input.Password />
									</Form.Item>
								</div>
								<div className='w-full'>
									<Form.Item
										label={<b>{t<string>('confirmPassword')}</b>}
										name='confirmNewPassword'
										rules={[
											{ required: true, message: t<string>('passwordRequired') },
											{
												pattern: PASSWORD_VALIDATION,
												message: t<string>('passwordMsg'),
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue('newPassword') === value) {
														return Promise.resolve();
													}
													return Promise.reject(new Error(t<string>('matchedPassword')));
												},
											}),
										]}
									>
										<Input.Password />
									</Form.Item>
								</div>
								<div className='flex justify-center w-full'>
									<Form.Item className=''>
										<Button className='form-btn ' type='primary' htmlType='submit' loading={loading}>
											{t<string>('update')}
										</Button>
									</Form.Item>
								</div>
							</div>
						</Form>
					</div>
				</Spin>
			</div>
		</div>
	);
};

export default ChangePassword;
