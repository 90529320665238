import { CountryAndPaymentMethod } from '../models/CountryAndPaymentMethod';

export const countryAndPaymentMethodMock: CountryAndPaymentMethod[] = [
	{
		countryName: 'Iraq',
		countryCode: 'IQ',
		currencyCode: 'IQD',
		maxProfitRatio: 50,
		paymentMethods: ['Zencash'],
		isPublic: true,
	},
	{
		countryName: 'Iraq',
		countryCode: 'IQ',
		currencyCode: 'IQD',
		maxProfitRatio: 50,
		paymentMethods: ['Zencash'],
		isPublic: true,
	},
	{
		countryName: 'Iraq',
		countryCode: 'IQ',
		currencyCode: 'IQD',
		maxProfitRatio: 50,
		paymentMethods: ['Zencash'],
		isPublic: true,
	},
	{
		countryName: 'Iraq',
		countryCode: 'IQ',
		currencyCode: 'IQD',
		maxProfitRatio: 50,
		paymentMethods: ['Zencash'],
		isPublic: true,
	},
	{
		countryName: 'Iraq',
		countryCode: 'IQ',
		currencyCode: 'IQD',
		maxProfitRatio: 50,
		paymentMethods: ['Zencash'],
		isPublic: true,
	},
	{
		countryName: 'Iraq',
		countryCode: 'IQ',
		currencyCode: 'IQD',
		maxProfitRatio: 50,
		paymentMethods: ['Zencash'],
		isPublic: true,
	},
	{
		countryName: 'Iraq',
		countryCode: 'IQ',
		currencyCode: 'IQD',
		maxProfitRatio: 50,
		paymentMethods: ['Zencash'],
		isPublic: true,
	},
	{
		countryName: 'Iraq',
		countryCode: 'IQ',
		currencyCode: 'IQD',
		maxProfitRatio: 50,
		paymentMethods: ['Zencash'],
		isPublic: true,
	},
];
