import { Navigate, Outlet } from 'react-router';
import { useMainContext } from '../store/MainContext';
import Layout, { Content } from 'antd/es/layout/layout';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { deleteUserInfo, getUserInfo } from '../helpers/localStorageHandler';
import { initialMainState } from '../assets/config/initial-main-state';
import './ProtectedRoutes.scss';
import Sider from 'antd/es/layout/Sider';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { getSideAminPanelItems, getSideSuperUserPanelItems } from '../helpers/getSidePanelItems';
import { useCountriesContext } from '../store/CountriesContext';
import { API_ENDPOINTS } from '../assets/api/endpoints';
import { CountryItem } from '../models/Country';
import { useRouteContext } from '../components/CurrentRouteProvider';
import { v4 as uuidv4 } from 'uuid';

function ProtectedRoutes() {
	const { t } = useTranslation();
	const { mainState, setMainState } = useMainContext();
	const navigate = useNavigate();
	const [selectedMenuItem, setSelectedMenuItem] = React.useState('');
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const isSmallScreen = React.useMemo(() => windowWidth < 600, [windowWidth]);
	const [collapsedSidePanel, setCollapsedSidePanel] = React.useState(isSmallScreen);
	const userInfo = React.useMemo(() => getUserInfo(), []);

	const { countriesState, setCountriesState } = useCountriesContext();

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	React.useEffect(() => {
		const token = userInfo?.token;

		if (mainState.isLoggedIn) {
			axios
				.get(API_ENDPOINTS.countries, { headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					const countries = res.data
						?.filter((country: CountryItem) => country.isPublic)
						.map((country: CountryItem) => ({
							...country,
							paymentMethods: country?.paymentMethods.filter((method) => method?.isPublic),
						}));

					const paymentMethods = countries.map((country: CountryItem) => country.paymentMethods)?.flat();

					setCountriesState({
						...countriesState,
						countries,
						paymentMethods,
						allPaymentMethods: res.data.map((country: CountryItem) => country.paymentMethods)?.flat(),
					});
				})
				.catch((error) => console.error(error));
		}
	}, []);

	const sidePanelItems = React.useMemo(
		() => (userInfo?.role === 'SUPER_USER' ? getSideSuperUserPanelItems(t) : getSideAminPanelItems(t)),
		[t],
	);
	const location = useLocation();
	const { routeVariable, updateRouteVariable } = useRouteContext();
	React.useEffect(() => {
		setSelectedMenuItem(window.location.pathname.replace('/', ''));
	}, [routeVariable, location.pathname]);
	const onMenuItemSelect = React.useCallback((menuItemKey: string) => {
		navigate(menuItemKey);
		setSelectedMenuItem(window.location.pathname.replace('/', ''));
		if (isSmallScreen) {
			setCollapsedSidePanel(true);
		}
	}, []);

	return Boolean(mainState?.isLoggedIn) ? (
		<section className='app'>
			<main className='main'>
				<Layout style={{ minHeight: '100vh' }}>
					<Sider
						collapsible
						collapsed={collapsedSidePanel}
						onCollapse={(value) => setCollapsedSidePanel(value)}
						collapsedWidth={collapsedSidePanel && isSmallScreen ? 0 : 70}
					>
						<Menu
							theme='dark'
							mode='inline'
							triggerSubMenuAction='click'
							items={sidePanelItems}
							onSelect={(menuItem) => onMenuItemSelect(menuItem.key)}
							selectedKeys={[selectedMenuItem]}
						/>
					</Sider>
					<Layout className='site-layout'>
						<Content>
							<Outlet />
						</Content>
					</Layout>
				</Layout>
			</main>
		</section>
	) : (
		<Navigate to='/sign-in' />
	);
}

export default ProtectedRoutes;
