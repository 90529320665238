import { Avatar, Card, Dropdown, List, MenuProps, Pagination } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { getUserInfo } from '../../helpers/localStorageHandler';
import './Notifications.scss';
import { BellOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { formatDate } from '../../helpers/formatDate';
import { notificationContentGenerator } from '../../helpers/notificationContent';
import { getNotificationType } from '../../helpers/getNotificationType';
import { Link } from 'react-router-dom';
import { useNotifications } from '../../components/NotificationContextProvider';
import ReactTimeAgo from 'react-time-ago';
import { getLanguage } from '../../helpers/getLanguage';
import { getUserLanguageInCookie } from '../../helpers/cookiesHandler';

const Notifications = () => {
	const { t } = useTranslation();
	const { notificationCount } = useNotifications();
	const [pagesize, setPageSize] = useState(10);
	const [totalElements, setTotalElements] = useState(0);
	const [notifications, setNotifications] = useState([]);
	const [page, setPage] = useState(0);
	const token = getUserInfo()?.token;
	const changePageSize = (pageSize: number) => {
		setPage(0);
		setPageSize(pageSize);
	};
	const changePage = (page: number) => setPage(page - 1);
	const pageSizeOptions = [10, 20, 50, 100];
	const pageSizeItems: MenuProps['items'] = [
		{ label: '10', key: '2', onClick: () => changePageSize(10) },
		{ label: '20', key: '3', onClick: () => changePageSize(20) },
		{ label: '50', key: '4', onClick: () => changePageSize(50) },
		{ label: '100', key: '5', onClick: () => changePageSize(100) },
	];
	useEffect(() => {
		axios
			.get(`${API_ENDPOINTS.notifications}?size=${pagesize}&offset=${page}&markread=true`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				res.data.data = res.data.data.map((notification: any) => {
					return {
						...notification,
						avatar: 'https://example.com/avatar2.png',
						title: t<string>(notification.notification)
							.replace(/_/g, ' ')
							.replace(/\w\S*/g, (w: string) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()),
						timestamp: notification.createdAt ? (
							<ReactTimeAgo date={notification.createdAt} locale={getLanguage(getUserLanguageInCookie())} />
						) : null,
						content: notificationContentGenerator(notification.notification, {
							...notification.properties,
							createdAt: notification.createdAt,
						}),
						type: getNotificationType(notification.notification),
					};
				});
				setNotifications(res.data.data);
				setTotalElements(res.data.totalElements);
			})
			.catch((error) => console.error(error));
	}, [pagesize, page, notificationCount, t]);
	const redirectToOrdersNotifications = [
		'NEW_ORDER',
		'ORDER_NEW_MESSAGE',
		'ORDER_EXPIRY_WARNING',
		'ORDER_EXPIRED',
		'ORDER_STATUS_UPDATE',
		'NEW_APPEAL_TASK_ASSIGNED',
		'APPEAL_NEW_MESSAGE',
		'APPEAL_REASSIGNED',
	];
	const listItems = notifications.map((item: any) => {
		const redirectToOrderPage = redirectToOrdersNotifications.includes(item.notification);
		const redirectToOffersPage = item.notification === 'OFFER_DISABLED';
		const cardElement = (
			<Card className='notification-card'>
				<Card.Meta
					avatar={
						<div className='avatar-container'>
							<Avatar src={item.avatar}>Ai</Avatar>
							{item.type === 'ACTION' ? (
								<div className='avatar-badge green-badge'>
									<BellOutlined size={0.5} />
								</div>
							) : (
								<div className='avatar-badge gold-badge'>
									<InfoCircleOutlined size={0.5} />
								</div>
							)}
						</div>
					}
					title={item.title}
					description={
						<div className='flex items-center justify-between w-100'>
							<p className='m-0 text-black pt-0 pb-0'>{item.content}</p>
							<p className='text-lightgray m-0'>{item.timestamp}</p>
						</div>
					}
				/>
			</Card>
		);
		if (redirectToOrderPage)
			return (
				<Link to={`/order-life-cycle?id=${item.properties.orderNumber}`}>
					<List.Item key={item.id}>{cardElement}</List.Item>
				</Link>
			);
		if (redirectToOffersPage)
			return (
				<Link to={`/my-offers`}>
					<List.Item key={item.id}>{cardElement}</List.Item>
				</Link>
			);
		return <List.Item key={item.id}>{cardElement}</List.Item>;
	});
	return (
		<div className='notifications-container'>
			<div className='merchants-head py-10'>
				<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center'>
					{t<string>('notificationsHeading')}
				</h1>
				<p className='text-white text-sm text-center'>{t<string>('clientsPageHeading')}</p>
			</div>
			<div className='max-w-[1440px] mx-auto px-[20px] lg:px-[75px] pt-[30px] pb-10 bg-white'>
				<div className='mb-4 items-center justify-between w-full flex flex-col md:flex-row md:flex-wrap my-2'>
					<div className='flex items-center my-2'>
						<Dropdown className='mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
							<span className='bg-[#F5F5F5] h-10 w-24 flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer'>
								{pagesize}
								<DownOutlined className='w-2 ' style={{ fontSize: 8 }} />
							</span>
						</Dropdown>
					</div>
				</div>
				<div style={{ minHeight: '100vh' }} className='flex flex-col h-100'>
					<div className='w-full'>
						<List itemLayout='horizontal'>{listItems}</List>
					</div>
				</div>
			</div>
			<div className='w-full flex flex-col items-end max-w-[1440px] mx-auto px-[20px] lg:px-[75px] pt-[30px] pb-5 bg-white'>
				<Pagination
					current={page + 1}
					pageSize={pagesize}
					total={totalElements}
					onChange={changePage}
					showSizeChanger
					pageSizeOptions={pageSizeOptions}
					showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
				/>
			</div>
		</div>
	);
};

export default Notifications;
