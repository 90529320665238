import { t } from 'i18next';

export function formatDate(inputDate: string) {
	const currentDate: any = new Date();
	const date: any = new Date(inputDate);

	const timeDiff: any = currentDate - date;
	const oneDay = 24 * 60 * 60 * 1000;

	if (timeDiff < oneDay) {
		// Today
		return `${t('today')} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
	} else if (timeDiff < 2 * oneDay) {
		// Yesterday
		return `${t('yesterday')} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
	} else if (timeDiff < 6 * oneDay) {
		// 2 to 5 days ago
		return `${Math.floor(timeDiff / oneDay)} ${t('daysAgo')}`;
	} else {
		// Older than 5 days
		const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
		const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
		return `${formattedDate} ${formattedTime}`;
	}
}

export function toHoursAndMinutes(totalMinutes: number) {
	const minutes = totalMinutes % 60;
	const hours = Math.floor(totalMinutes / 60);

	return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

function padTo2Digits(num: number) {
	return num.toString().padStart(2, '0');
}
