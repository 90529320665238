import { Popover, Button } from 'antd';
import { getUserLanguageInCookie, setUserLanguageInCookie } from '../../helpers/cookiesHandler';
import i18n from 'i18next';
import React, { useEffect } from 'react';
import ArFlag from '../../languages/flags/ar';
import EnFlag from '../../languages/flags/en';
import FrFlag from '../../languages/flags/fr';
import PlFlag from '../../languages/flags/pl';
import { DownOutlined } from '@ant-design/icons';
import { getCountryFlag } from '../../helpers/getCountryFlag';
import { useDirectionContext } from '../../store/DirectionContext';

function Languages() {
	const [selectedLanguage, setSelectedLanguage] = React.useState('');
	const { setDirectionState } = useDirectionContext();

	const onLanguageChange = (lang: string) => {
		i18n.changeLanguage(lang);
		setUserLanguageInCookie(lang);
		setSelectedLanguage(lang);
		const body = document.body;
		body.className = '';
		body.classList.add(lang === 'ar' ? 'rtl' : 'ltr');
		setDirectionState(lang === 'ar' ? 'rtl' : 'ltr');
	};

	useEffect(() => {
		if (getUserLanguageInCookie()) {
			setSelectedLanguage(getUserLanguageInCookie());
		} else {
			setSelectedLanguage('en');
			i18n.changeLanguage('en');
		}
	}, []);

	useEffect(() => {
		const body = document.body;
		selectedLanguage !== 'ar' ? body.classList.add('ltr') : body.classList.add('rtl');
	}, [selectedLanguage]);

	return (
		<Popover
			placement={selectedLanguage === 'ar' ? 'topLeft' : 'topRight'}
			trigger='click'
			content={
				<div className='flex flex-col'>
					<Button
						onClick={() => onLanguageChange('ar')}
						className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
					>
						العربية
						<span className='w-5'>
							<ArFlag />
						</span>
					</Button>

					<Button
						onClick={() => onLanguageChange('en')}
						className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
					>
						English
						<span className='w-5'>
							<EnFlag />
						</span>
					</Button>

					<Button
						onClick={() => onLanguageChange('fr')}
						className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
					>
						Français
						<span className='w-5'>
							<FrFlag />
						</span>
					</Button>

					<Button
						onClick={() => onLanguageChange('pl')}
						className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
					>
						Polski
						<span className='w-5'>
							<PlFlag />
						</span>
					</Button>
				</div>
			}
		>
			<Button className='p-0 m-0 border-none uppercase text-white text-xs  font-medium flex items-center hover:!text-white bg-transparent'>
				<span className='w-[21px] '>{getCountryFlag(selectedLanguage)}</span>
				<div className='mx-[6px]'>{selectedLanguage}</div>
				<DownOutlined className='w-2 ' style={{ fontSize: 8, color: '#fff' }} />
			</Button>
		</Popover>
	);
}

export default Languages;
