import { OrderFeedbackType } from '../../types/OrderFeedbackType';
import { ActionTypeAdmin } from '../../types/ActionType';
import { OrderStateType } from '../../types/OrderStateType';
import { OrderType } from '../../types/OrderType';
import BodyDetails from './BodyDetails/BodyDetails';
import ChatContainer from './ChatContainer/ChatContainer';
import Stomp from 'stompjs';
import './OrderLifeCycleBody.scss';
import { useState } from 'react';
import { OrderHistory } from '../../types/OrderHistory';
import { OrderDetailsType } from '../../types/OrderDetailsType';

type OrderLifeCycleBodyProps = {
	orderState: OrderStateType;
	orderNumber: string;
	isMerchant: boolean;
	userName: string;
	orders: number;
	reviews: number;
	rating: number;
	feedback: OrderFeedbackType;
	orderBody: {
		unitPrice: string;
		quantity: string;
		amount: string;
		paymentMehodId: number;
		accountNumber: string;
		accountGroup: string;
	};
	actions: ActionTypeAdmin[];
	orderType: OrderType;
	merchantId: string;
	note: string;
	client: string;
	triggerAction: (action: ActionTypeAdmin, otp?: string) => void;
	stompClient: Stomp.Client | null;
	getOrderDetails: () => void;
	clientOrder: number;
	orderHistory: OrderHistory[];
	appealTask: OrderDetailsType['appealTask'];
	paymentProof: boolean;
	paymentProofFileId: string;
	paymentProofFileLabel: string;
	otpVlaid: boolean;
	loading: boolean;
};

function OrderLifeCycleBody({
	orderState,
	isMerchant,
	orderNumber,
	orderBody,
	userName,
	orders,
	reviews,
	rating,
	feedback,
	actions,
	orderType,
	merchantId,
	note,
	client,
	triggerAction,
	stompClient,
	getOrderDetails,
	clientOrder,
	orderHistory,
	appealTask,
	paymentProof,
	paymentProofFileId,
	paymentProofFileLabel,
	otpVlaid,
	loading,
}: OrderLifeCycleBodyProps) {
	const [isActionsDisabled, setIsActionsDisabled] = useState(true);

	return (
		<div className='order-life-cycle-body-main-wrapper'>
			<div className='order-life-cycle-body-wrapper'>
				<BodyDetails
					orderState={orderState}
					orderNumber={orderNumber}
					unitPrice={orderBody.unitPrice}
					quantity={orderBody.quantity}
					amount={orderBody.amount}
					feedback={feedback}
					paymentMehodId={orderBody.paymentMehodId}
					accountNNumber={orderBody.accountNumber}
					accountGroup={orderBody.accountGroup}
					actions={actions}
					orderType={orderType}
					merchantId={merchantId}
					userName={userName}
					note={note}
					isActionsDisabled={isActionsDisabled}
					triggerAction={triggerAction}
					getOrderDetails={getOrderDetails}
					orderHistory={orderHistory}
					appealTask={appealTask}
					paymentProof={paymentProof}
					paymentProofFileId={paymentProofFileId}
					paymentProofFileLabel={paymentProofFileLabel}
					otpVlaid={otpVlaid}
					loading={loading}
					stompClient={stompClient}
				/>
				<ChatContainer
					orderNumber={orderNumber}
					isMerchant={isMerchant}
					orderState={orderState}
					userName={userName}
					clientName={client}
					orders={orders}
					reviews={reviews}
					rating={rating}
					stompClient={stompClient}
					getOrderDetails={getOrderDetails}
					setIsActionsDisabled={setIsActionsDisabled}
					clientOrder={clientOrder}
					appealTask={appealTask}
				/>
			</div>
		</div>
	);
}

export default OrderLifeCycleBody;
