import './OrdersHeader.scss';
import { useTranslation } from 'react-i18next';

const OrderHeader = () => {
	const { t } = useTranslation();

	return (
		<div className='orders-head py-10'>
			<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center mt-6'>{t<string>('orderHeading')}</h1>
			<p className='text-white text-sm text-center'>{t<string>('orderSubtitle')}</p>
		</div>
	);
};

export default OrderHeader;
