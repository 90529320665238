import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import './Review.scss';
import Header from '../../components/Header/Header';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Review } from '../../models/Review';

import { DownOutlined, DislikeOutlined, LikeOutlined } from '@ant-design/icons';

import { getInitials } from '../../helpers/getStringInitials';
import { Pagination, Spin, MenuProps, Dropdown, Form, DatePicker } from 'antd';

import dayjs, { Dayjs } from 'dayjs';
import { Merchant } from '../../models/merchant';

const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';

const DATE_FORMAT = 'YYYY-MM-DD';

const MerchantReview = () => {
	const { t } = useTranslation();
	const { RangePicker } = DatePicker;
	const today = new Date();
	const lastWeek = new Date(today);
	lastWeek.setDate(today.getDate() - 7);

	const [selectedDates, setSelectedDates] = useState<[Dayjs, Dayjs]>([dayjs(today), dayjs(lastWeek)]);

	const [reviewLoading, setReviewLoading] = useState(false);
	const [reviews, setReviews] = useState<Review[]>([]);
	const [merchant, setMerchant] = useState<Merchant>();
	const [pagesize, setPageSize] = useState(5);
	const [page, setPage] = useState(1);
	const [totalElements, setTotalElements] = useState(0);
	const [startDateFilter, setStartDateFilter] = useState<string>('');
	const [endDateFilter, setEndDateFilter] = useState<string>('');
	const token = getUserInfo()?.token;
	const queryParams = new URLSearchParams(window.location.search);
	const merchantId = queryParams.get('merchantId') ?? '';
	useEffect(() => {
		setReviewLoading(true);
		let sort = 'createdAt-desc';
		let startDate = startDateFilter ? `createdAt%3E${startDateFilter}` : '';
		let endDate = endDateFilter ? `createdAt%3C${endDateFilter}` : '';
		let filter;
		if (!startDate && !endDate) {
			filter = '';
		} else {
			filter = `search=${startDate},${endDate}&`;
		}
		axios
			.get(
				`${API_ENDPOINTS.getReviews.replace('%merchantId%', merchantId)}?${filter}page=${
					page - 0
				}&pageSize=${pagesize}&sort=${sort}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				},
			)
			.then((res) => {
				setReviews(res.data.data);
				setTotalElements(res.data.totalElements);
			})
			.catch((err) => console.error(err))
			.finally(() => setReviewLoading(false));
	}, [page, startDateFilter, endDateFilter]);

	useEffect(() => {
		axios
			.get(`${API_ENDPOINTS.getMerchant.replace('%merchantId%', merchantId)}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				setMerchant(res.data);
			})
			.catch((err) => console.error(err))
			.finally(() => setReviewLoading(false));
	}, []);

	const pageSizeItems: MenuProps['items'] = [
		{ label: '5', key: '1', onClick: () => setPageSize(5) },
		{ label: '10', key: '2', onClick: () => setPageSize(10) },
		{ label: '20', key: '3', onClick: () => setPageSize(20) },
		{ label: '50', key: '4', onClick: () => setPageSize(50) },
	];
	return (
		<div className='reviews-container'>
			<Header title={t<string>('merchantReview')} description={t<string>('merchantsReviewSubtitle')} />
			<Spin spinning={reviewLoading}>
				<div className='bg-white overflow-hidden'>
					<div className='info-container'>
						<div className='profile-icon'>
							<span className='profile-icon-text'>{getInitials(merchant?.fullName ?? '')}</span>
						</div>
						<div>
							<h2>{merchant?.fullName}</h2>
						</div>
						<div>
							<span className='text-[#333333]'>
								{merchant?.ordersCompleted} {t<string>('order')} | {merchant?.totalReviews} {t<string>('reviews')}
							</span>
							<div className='text-center mt-1'>
								{merchant?.currencyCode} {t<string>('currency')}
							</div>
						</div>
						<div className='center-container flex items-end mt-4'>
							<LikeOutlined className='h-[20px] w-[20px] mx-1 text-[#33A867]' />
							<span className='text-[green] mx-2'>{merchant?.rating}%</span>
						</div>
					</div>
					<div className='grid-container sm:grid'>
						<div className='grid-item'>
							<p className='my-2 text-[20px] font-[400]'>{t<string>('totalReview')}</p>
							<p className='flex justify-center text-[17px] m-0'>{reviews[0]?.totalReviews}</p>
						</div>
						<div className='grid-item'>
							<p className='my-2 text-[20px] font-[400]'>{t<string>('averageRating')}</p>
							<p className='flex justify-center text-[17px] m-0 text-green-800 '>
								<LikeOutlined className='h-[20px] w-[20px] mx-1 text-[#33A867] mx-1' />
								{reviews[0]?.averageRating}%
							</p>
						</div>
						<div className='grid-item'>
							<div className='flex my-2 items-center'>
								<LikeOutlined className='h-[20px] w-[20px] mx-2 text-[#33A867]' />
								<div
									style={{
										width: `${reviews[0]?.likePercentage}px`,
										backgroundColor: 'green',
										height: '7px',
										borderRadius: '5px',
									}}
								></div>
								<span className='text-[15px] px-2'>{reviews[0]?.likePercentage}%</span>
							</div>
							<div className='flex my-4 items-center'>
								<DislikeOutlined className='h-[16px] w-[16px] mx-2 text-[#F6465D] ' />
								<div
									style={{
										width: `${reviews[0]?.dislikePercentage}px`,
										backgroundColor: 'red',
										height: '7px',
										borderRadius: '5px',
									}}
								></div>
								<span className='text-[15px] px-2'>{reviews[0]?.dislikePercentage}%</span>
							</div>
						</div>
					</div>
					<div className='w-[90%] bg-[#dadada] h-[1px] m-auto'></div>
					<div className='flex justify-start p-5 w-[90%] m-auto'>
						<Form.Item label={t<string>('dateRange')}>
							<RangePicker
								defaultValue={[dayjs(selectedDates[0], DATE_FORMAT), dayjs(selectedDates[1], DATE_FORMAT)] as any}
								onChange={(values) => {
									if (values && values.length === 2) {
										const startDate = dayjs(values[0]).format('YYYY-MM-DD 00:00:00');
										const endDate = dayjs(values[1]).format('YYYY-MM-DD 23:59:00');
										setSelectedDates([dayjs(values[0]), dayjs(values[1])]);
										setStartDateFilter(startDate);
										setEndDateFilter(endDate);
									} else {
										setSelectedDates([dayjs(), dayjs()]);
										setStartDateFilter('');
										setEndDateFilter('');
									}
								}}
							/>
						</Form.Item>
					</div>
					<div className='w-[90%] m-auto my-4'>
						{reviews.map((review: Review, index) => {
							return (
								<>
									<div className='my-10 '>
										<div className='flex justify-between'>
											<div className='flex items-center '>
												<div
													style={{
														borderStyle: 'solid',
														borderWidth: '1px',
														borderColor: '#949090',
													}}
													className={`h-[30px] w-[30px] !rounded-full flex items-center justify-center p-4 `}
												>
													{getInitials(review.clientFirstName)}
												</div>
												<div className='mx-2'>
													<p className='m-0 text-[17px] font-[500]'>{review.clientFirstName}</p>
													<p className='m-0'>{dayjs(review.createdAt).format(DATE_TIME_FORMAT)}</p>
												</div>
											</div>
											{review.isLike ? (
												<LikeOutlined className='h-[20px] w-[20px] mx-1 text-[#33A867]' />
											) : (
												<DislikeOutlined className='h-[16px] w-[16px] text-[#F6465D]' />
											)}
										</div>
										<div className='my-2'>{review.comment} </div>
									</div>
									{index !== reviews.length - 1 && <div className='w-[100%] bg-[#dadada] h-[1px] m-auto'></div>}
								</>
							);
						})}
					</div>
					<div className='flex justify-end m-2 sm:flex-col md:flex-row'>
						<Pagination
							pageSize={pagesize}
							current={page}
							total={totalElements}
							onChange={(pageIndex) => setPage(pageIndex)}
						/>
						<div className='flex items-center sm:mt-4 md:mt-0'>
							<span className='text-[#000D1D99] text-sm '>{t<string>('show')}</span>
							<Dropdown className='mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
								<span className='bg-[#F5F5F5] h-10 w-24 flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer'>
									{pagesize}
									<DownOutlined className='w-2 ' style={{ fontSize: 8 }} />
								</span>
							</Dropdown>
							<span className='text-[#000D1D99] text-sm '>{t<string>('entries')}</span>
						</div>
					</div>
				</div>
			</Spin>
		</div>
	);
};

export default MerchantReview;
