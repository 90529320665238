import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { getProjectTheme } from './helpers/getProjectTheme';
import { MainContext } from './store/MainContext';
import { CountriesContext } from './store/CountriesContext';
import { MainState } from './models/main-state';
import { initialMainState } from './assets/config/initial-main-state';
import { BrowserRouter } from 'react-router-dom';
import Views from './routes/Views';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getUserLanguageInCookie } from './helpers/cookiesHandler';
import { englishPhrases } from './languages/englishPhrases';
import { arabicPhrases } from './languages/arabicPhrases';
import { frenchPhrases } from './languages/frenchPhrases';
import { polishPhrases } from './languages/polishPhrases';
import { initialCountriesState } from './assets/config/initial-countries-state';
import { CountriesState } from './models/countries-state';
import { DirectionContext } from './store/DirectionContext';
import { initialDirection } from './assets/config/initial-direction-state';
import { OrderLifeCycleContext } from './store/OrderLifeCycleContext';
import { MemoizedAxiosWrapper } from './assets/api/axiosInterceptor';

function App() {
	const [mainState, setMainState] = React.useState<MainState>(initialMainState);
	const [countriesState, setCountriesState] = React.useState<CountriesState>(initialCountriesState);
	const [directionState, setDirectionState] = React.useState<'ltr' | 'rtl' | undefined>(initialDirection);
	const [newAssignee, setNewAssignee] = React.useState<string>('');

	i18n.use(initReactI18next).init({
		resources: {
			en: { translation: englishPhrases },
			ar: { translation: arabicPhrases },
			fr: { translation: frenchPhrases },
			pl: { translation: polishPhrases },
		},
		lng: getUserLanguageInCookie(),
		fallbackLng: ['en', 'ar', 'pl', 'fr'].includes(navigator.language) ? navigator.language : 'en',
		interpolation: { escapeValue: false },
	});

	useEffect(() => {
		let lang = getUserLanguageInCookie();
		const body = document.body;
		body.className = lang === 'ar' ? 'rtl' : 'ltr';
		setDirectionState(lang === 'ar' ? 'rtl' : 'ltr');
	}, []);

	return (
		<ConfigProvider theme={getProjectTheme()} direction={directionState}>
			<CountriesContext.Provider value={{ countriesState, setCountriesState }}>
				<MainContext.Provider value={{ mainState, setMainState }}>
					<DirectionContext.Provider value={{ directionState, setDirectionState }}>
						<OrderLifeCycleContext.Provider value={{ newAssignee, setNewAssignee }}>
							<BrowserRouter>
								<MemoizedAxiosWrapper>
									<Views />
								</MemoizedAxiosWrapper>
							</BrowserRouter>
						</OrderLifeCycleContext.Provider>
					</DirectionContext.Provider>
				</MainContext.Provider>
			</CountriesContext.Provider>
		</ConfigProvider>
	);
}

export default App;
