export enum NotificationActionType {
	NEW_ORDER = 'NEW_ORDER',
	ORDER_NEW_MESSAGE = 'ORDER_NEW_MESSAGE',
	ORDER_EXPIRY_WARNING = 'ORDER_EXPIRY_WARNING',
	ORDER_EXPIRED = 'ORDER_EXPIRED',
	ORDER_STATUS_UPDATE = 'ORDER_STATUS_UPDATE',
	OFFER_DISABLED = 'OFFER_DISABLED',
	ACCOUNT_BALANCE_LOW = 'ACCOUNT_BALANCE_LOW',
	SELL_AVAILABLE_LOW = 'SELL_AVAILABLE_LOW',
	NEW_APPEAL_TASK_ASSIGNED = 'NEW_APPEAL_TASK_ASSIGNED',
	APPEAL_NEW_MESSAGE = 'APPEAL_NEW_MESSAGE',
	APPEAL_REMINDER = 'APPEAL_REMINDER',
	PENDING_APPEAL = 'PENDING_APPEAL',
	APPEAL_REASSIGNED = 'APPEAL_REASSIGNED',
	APPEAL_REASSIGNED_NEW_ADMIN = 'APPEAL_REASSIGNED_NEW_ADMIN',
}
