import { Dropdown, Table, MenuProps, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import './Countries.scss';
import Header from '../../components/Header/Header';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { getCountryAndPaymMethodColumn } from '../../helpers/countryAndPaymMethodColumn';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { CountryAndPaymentMethodFilters } from '../../models/CountryAndPaymentMethod';

const Countries = () => {
	const { t } = useTranslation();
	const [countriesAndPaymLoading, setCountriesAndPaymLoading] = useState(false);
	const [countryAndPayms, setCountryAndPayms] = useState([]);
	const [sortBy, setSortBy] = useState<string>('name-asc');
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [totalElements, setTotalElements] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const token = getUserInfo()?.token;
	useEffect(() => {
		setCountriesAndPaymLoading(true);
		axios
			.get(`${API_ENDPOINTS.countries}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				const sortedData = res.data
					.sort((a: any, b: any) => {
						switch (sortBy) {
							case CountryAndPaymentMethodFilters.nameDesc:
								return a.countryName.localeCompare(b.countryName);
							case CountryAndPaymentMethodFilters.nameAsc:
								return b.countryName.localeCompare(a.countryName);
							case CountryAndPaymentMethodFilters.countryCodeAsc:
								return a.countryCode.localeCompare(b.countryCode);
							case CountryAndPaymentMethodFilters.countryCodeDesc:
								return b.countryCode.localeCompare(a.countryCode);
							case CountryAndPaymentMethodFilters.currencyCodeAsc:
								return a.currencyCode.localeCompare(b.currencyCode);
							case CountryAndPaymentMethodFilters.currencyCodeDesc:
								return b.currencyCode.localeCompare(a.currencyCode);
							case CountryAndPaymentMethodFilters.maxProfitAcs:
								return a.maxProfitRatio - b.maxProfitRatio;
							case CountryAndPaymentMethodFilters.maxProfitDesc:
								return b.maxProfitRatio - a.maxProfitRatio;
							case CountryAndPaymentMethodFilters.isPublicAsc:
								return a.isPublic - b.isPublic;
							case CountryAndPaymentMethodFilters.isPublicDesc:
								return b.isPublic - a.isPublic;
							default:
								return 0;
						}
					})
					.filter((obj: any) =>
						Object.values(obj).some(
							(val: any) => val?.toString().toLocaleLowerCase().includes(searchValue.toString().toLocaleLowerCase()),
						),
					);
				setCountryAndPayms(sortedData);
				setTotalElements(sortedData.length);
			})
			.catch((err) => console.error(err))
			.finally(() => setCountriesAndPaymLoading(false));
	}, [sortBy, searchValue]);

	const changeOageSize = (pageSize: number) => {
		setPage(0);
		setPageSize(pageSize);
	};

	const pageSizeItems: MenuProps['items'] = [
		{ label: '10', key: '1', onClick: () => changeOageSize(10) },
		{ label: '20', key: '2', onClick: () => changeOageSize(20) },
		{ label: '50', key: '3', onClick: () => changeOageSize(50) },
		{ label: '100', key: '4', onClick: () => changeOageSize(100) },
	];

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			setSearchValue(searchTerm);
		}, 1000);
		return () => clearTimeout(delaySearch);
	}, [searchTerm]);

	useEffect(() => {
		setPage(0);
		setPageSize(10);
	}, [searchValue]);

	return (
		<div className='countries-and-paym-container'>
			<Header title={t<string>('countriesPaymentMethods')} description={t<string>('CountryAndPaymentMethods')} />
			<div className='max-w-[1440px] mx-auto xss:px-[10px] md:px-[75px] pt-[30px] pb-20 bg-white '>
				<div className='flex justify-between xss:flex-col sm:flex-row md:flex-wrap'>
					<div className='flex items-center my-2'>
						<span className='text-[#000D1D99] text-sm '>{t<string>('show')}</span>
						<Dropdown className='mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
							<span className='bg-[#F5F5F5] h-10 w-24 flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer'>
								{pageSize}
								<DownOutlined className='w-2 ' style={{ fontSize: 8 }} />
							</span>
						</Dropdown>
						<span className='text-[#000D1D99] text-sm '>{t<string>('entries')}</span>
					</div>
					<Input
						className='border-none h-10 bg-[#F5F5F5] search-input w-[200px] xss:my-4 lg:my-0'
						placeholder={t<string>('search')}
						value={searchTerm}
						onChange={(e) => {
							setSearchTerm(e.target.value);
						}}
						size='large'
						prefix={<SearchOutlined className='mr-2' />}
					/>
				</div>
				<Table
					columns={getCountryAndPaymMethodColumn(t, sortBy, setSortBy)}
					dataSource={countryAndPayms}
					pagination={{
						pageSize,
						total: totalElements,
						onChange: (pageIndex) => setPage(pageIndex - 1),
						current: page + 1,
					}}
					loading={countriesAndPaymLoading}
					tableLayout='auto'
				/>
			</div>
		</div>
	);
};

export default Countries;
