import { UserInfo } from '../models/UserInfo';

const USER_INFO_LOCAL_STORAGE = 'userInfo';

export function setUserInfo(userInfo: UserInfo | {}) {
	localStorage.setItem(USER_INFO_LOCAL_STORAGE, JSON.stringify(userInfo));
}

export function getUserInfo(): UserInfo | undefined {
	return JSON.parse(localStorage.getItem(USER_INFO_LOCAL_STORAGE) || '{}');
}

export function deleteUserInfo() {
	localStorage.removeItem(USER_INFO_LOCAL_STORAGE);
}
