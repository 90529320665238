import React, { createContext, useContext, useState, ReactNode } from 'react';

interface RouteContextProps {
	routeVariable: string;
	updateRouteVariable: (newValue: string) => void;
}

const RouteContext = createContext<RouteContextProps | undefined>(undefined);

interface RouteProviderProps {
	children: ReactNode;
	initialValue: string;
}

export const RouteProvider: React.FC<RouteProviderProps> = ({ children, initialValue }) => {
	const [routeVariable, setRouteVariable] = useState<string>(initialValue);

	const updateRouteVariable = (newValue: string) => {
		setRouteVariable(newValue);
	};

	return <RouteContext.Provider value={{ routeVariable, updateRouteVariable }}>{children}</RouteContext.Provider>;
};

export const useRouteContext = (): RouteContextProps => {
	const context = useContext(RouteContext);
	if (!context) {
		throw new Error('useRouteContext must be used within a RouteProvider');
	}
	return context;
};
