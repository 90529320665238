import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Modal, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './dialogs.scss';

export function ReverseDialog({
	showRevertDialog,
	setShowRevertDialog,
	handelRevertOrder,
	loading,
}: {
	showRevertDialog: boolean;
	setShowRevertDialog: (value: boolean) => void;
	handelRevertOrder: () => void;
	loading: boolean;
}) {
	const { t } = useTranslation();
	const [isFirstChecked, setIsFirstChecked] = useState(false);

	return (
		<Modal
			title={
				<div className='flex items-center'>
					<InfoCircleOutlined style={{ fontSize: '30px', textAlign: 'center', display: 'block' }} />
					<h4 className='m-0 px-2 text-center'>{t<string>('revertToPreviousStage')}</h4>
				</div>
			}
			open={showRevertDialog}
			onCancel={() => setShowRevertDialog(false)}
			footer={null}
		>
			<div className='checkBox-container'>
				<Checkbox onChange={(e) => setIsFirstChecked(e.target.checked)} style={{ marginBottom: '20px' }}>
					{t<string>('revertToPreviousStageMessage')}
				</Checkbox>
			</div>
			<div className='flex justify-end'>
				<button
					className='bg-[#e5f1ff] border-none py-2 px-4 m-2 text-[grey] rounded cursor-pointer'
					onClick={() => {
						setShowRevertDialog(false);
					}}
				>
					{t<string>('no')}
				</button>
				<Spin spinning={loading}>
					<button
						className='bg-[#0077ff] border-none py-2 px-4 m-2 text-[white] rounded cursor-pointer'
						disabled={!isFirstChecked}
						onClick={() => {
							handelRevertOrder();
							setShowRevertDialog(false);
						}}
					>
						{t<string>('yes')}
					</button>
				</Spin>
			</div>
		</Modal>
	);
}
