import { TFunction } from 'i18next';
import { OrderStateType } from '../types/OrderStateType';
import { ActionType, ActionTypeAdmin } from '../types/ActionType';

export function getStateConfiguration(
	orderState: OrderStateType,
	t: TFunction<'translation', undefined, 'translation'>,
): { label: string; color: string; bgColor: string } {
	switch (orderState) {
		case 'OPENED':
			return { label: t<string>('opened'), color: '#40af0c', bgColor: '#c6e7b6' };
		case 'TRANSFERRED':
			return { label: t<string>('transferred'), color: '#09bea8', bgColor: '#e6f6f4' };
		case 'COMPLETED':
			return { label: t<string>('completed'), color: '#33a867', bgColor: '#e9ffe5' };
		case 'APPEAL':
			return { label: t<string>('appeal'), color: '#b1982c', bgColor: '#fef3c2' };
		case 'REJECTED':
			return { label: t<string>('rejected'), color: '#f6465d', bgColor: '#ffdde1' };
		case 'EXPIRED':
			return { label: t<string>('expired'), color: '#e88b00', bgColor: '#fadfb7' };
		case 'CANCELLED':
			return { label: t<string>('cancelled'), color: '#707070', bgColor: '#e8e8e8' };
		case 'PLACED':
			return { label: t<string>('placed'), color: '#2da8e8', bgColor: '#b3e5ff' };
		default:
			return { label: t<string>('unknown'), color: '#575757', bgColor: '#FFE7E7' };
	}
}

export function getActionStateConfiguration(
	orderState: ActionType | ActionTypeAdmin,
	t: TFunction<'translation', undefined, 'translation'>,
): { label: string; color: string; bgColor: string } {
	switch (orderState) {
		case 'ACCEPT':
			return { label: t<string>('accepted'), color: '#40af0c', bgColor: '#c6e7b6' };
		case 'CONFIRM_SELLER_RECEIVED':
			return { label: t<string>('paymentReceived'), color: '#09BEA8', bgColor: '#E6F6F4' };
		case 'FINISHED_SUCCESSFULLY':
			return { label: t<string>('finishedSuccessfuly'), color: '#09BEA8', bgColor: '#E6F6F4' };
		case 'CONFIRM_BUYER_TRANSFERRED':
			return { label: t<string>('paymentTransfered'), color: '#33A867', bgColor: '#93FF82' };
		case 'APPEAL':
			return { label: t<string>('appealed'), color: '#B1982C', bgColor: '#fef3c2' };
		case 'REJECT':
			return { label: t<string>('rejected'), color: '#f6465d', bgColor: '#ffdde1' };
		case 'CANCEL':
			return { label: t<string>('cancelled'), color: '#707070', bgColor: '#e8e8e8' };
		case 'CANCEL_APPEAL':
			return { label: t<string>('cancelAppeal'), color: '#707070', bgColor: '#e8e8e8' };
		case 'REVERT':
			return { label: t<string>('revert'), color: '#707070', bgColor: '#e8e8e8' };
		case 'RELEASE_PAYMENT':
			return { label: t<string>('release'), color: '#707070', bgColor: '#e8e8e8' };
		case 'SUSPEND_CLIENT':
			return { label: t<string>('suspendClient'), color: '#575757', bgColor: '#FFE7E7' };
		case 'SUSPEND_MERCHANT':
			return { label: t<string>('suspendMerchant'), color: '#575757', bgColor: '#FFE7E7' };
		case 'UNSUSPEND_MERCHANT':
			return { label: t<string>('unsuspendMerchant'), color: '#575757', bgColor: '#FFE7E7' };
		case 'UNSUSPEND_CLIENT':
			return { label: t<string>('unsuspendClient'), color: '#575757', bgColor: '#FFE7E7' };
		default:
			return { label: t<string>('unknown'), color: '#575757', bgColor: '#FFE7E7' };
	}
}
