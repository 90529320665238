import React from 'react';

type Store = {
	newAssignee: string;
	setNewAssignee: React.Dispatch<React.SetStateAction<string>>;
};

export const OrderLifeCycleContext = React.createContext<Store>({
	newAssignee: '',
} as Store);

export const useOrderLifeCycleContext = () => React.useContext(OrderLifeCycleContext);
