import { LanguagePhrases } from '../models/LanguagePhrases';

export const arabicPhrases: LanguagePhrases = {
	signIn: 'تسجيل الدخول',
	home: 'الرئيسيه',
	signinTitle: 'قم بتسجيل الدخول إلى حساب الدفع الخاص بك',
	signinDesc: 'قم بتسجيل الدخول باستخدام بيانات الاعتماد الخاصة ببوابة العميل',
	email: 'البريد الإلكتروني',
	password: 'كلمة المرور',
	forgetPassword: 'نسيت كلمة المرور',
	emailRequired: 'يجب ألا يكون حقل البريد الإلكتروني فارغًا!',
	passwordRequired: 'يجب ألا يكون حقل كلمة المرور فارغًا!',
	somethingWrong: 'حدث خطأ ما!',
	emailMsg: 'يرجى التأكد من صحة عنوان البريد الإلكتروني الخاص بك!',
	passwordMsg:
		'يرجى التأكد من أن كلمة المرور الخاصة بك تحتوي على حرف كبير وحرف صغير وحرف خاص وأن طول كلمة المرور يجب أن يكون على الأقل 8 أحرف!',
	orders: 'طلبات',
	problemLoadingListOfOrders: 'هناك بعض المشاكل في تحميل قائمة الطلبات',
	date: 'تاريخ',
	orderType: 'نوع الطلب',
	country: 'دولة',
	amount: 'كمية',
	merchant: 'تاجر',
	rate: 'معدل',
	status: 'حالة',
	buy: 'يشتري',
	sell: 'يبيع',
	active: 'نشيط',
	appealed: 'استأنف',
	cancelled: 'ألغيت',
	closed: 'مغلق',
	pending: 'قيد الانتظار',
	problemLoadingCountries: 'هناك مشكلة تحميل قائمة الدول',
	becomeMerchant: 'كن تاجرًا',
	yourAccountIsInactive: 'حسابك غير نشط. يرجى الاتصال بالمسؤول الخاص بك لتنشيطه الخاص بك',
	regularUserHome: 'الصفحة الرئيسية للمستخدم العادي',
	merchantHome: 'منزل التاجر',
	merchantsOffer: 'عروض التجار',
	searchInMerchantsTable: 'البحث في جدول التجار',
	problemLoadingListOfMerchants: 'توجد مشكلة في تحميل قائمة التجا',
	name: 'اسم',
	rating: 'تقييم',
	totalReview: 'مراجعة كاملة',
	ordersCompleted: 'الطلبات المنجزة',
	price: 'سعر',
	available: 'متاح',
	minLimit: 'الحد الأدنى',
	maxLimit: 'حد اقصي',
	accountVerification: 'تأكيد الحساب',
	problemLoadingMerchant: 'هناك بعض المشاكل في تحميل معلومات التاجر',
	merchantInfo: 'معلومات التاجر',
	wantToBuy: 'أريد شراء',
	wantToSell: 'أريد أن أبيع',
	amountIsRequired: 'المبلغ مطلوب',
	willReceive: 'سوف استلم',
	willSend: 'سوف أرسل',
	paymentMethod: 'طريقة الدفع او السداد',
	paymentMethodIsRequired: 'طريقة الدفع مطلوبة',
	valueInRangeError: 'يجب أن يكون المبلغ في هذا النطاق',
	merchants: 'التجار',
	merchantId: 'معرف التاجر',
	merchantType: 'نوع التاجر',
	countryCurrency: 'الدولة / العملة',
	paymentMethods: 'طرق الدفع',
	myDetails: 'تفاصيلي',
	changePassword: 'تغيير كلمة المرور',
	myDetailsForm: 'نموذج التفاصيل الخاصة بي',
	myProfile: 'ملفي الشخصي',
	firstName: 'الاسم الأول',
	surname: 'اسم العائلة',
	fullName: 'الاسم الكامل',
	currency: 'العملة',
	phone: 'هاتف',
	address: 'عنوان',
	city: 'مدينة',
	zip: 'zip',
	state: 'ولاية',
	registrationDate: 'تاريخ التسجيل',
	newPassword: 'كلمة المرور الجديدة',
	currentPassword: 'كلمة السر الحالية',
	confirmPassword: 'تأكيد كلمة المرور',
	updatePassword: 'تحديث كلمة السر',
	changePasswordForm: 'نموذج تغيير كلمة المرور',
	yourPasswordGotUpdated: 'تم تحديث كلمة مرورك',
	somethingWentWrongWithUpdatingPassword: 'حدث خطأ في تحديث كلمة المرور',
	pleaseMakeSurePasswordIsValid:
		'يرجى التأكد من أن كلمة مرورك تتضمن على الأقل حرفًا كبيرًا وحرفًا صغيرًا وحرفًا خاصًا وأن يكون طول كلمة المرور 8 على الأقل وباللغة الإنكليزية',
	twoPasswordsDoNotMatch: 'يجب أن تكون كلمتا المرور متطابقتين',
	myOffers: 'عروضي',
	yourSellerFormGotUpdated: 'تم تحديث نموذج البائع الخاص بك',
	somethingWentWrongWithUpdatingSellerForm: 'حدث خطأ في تحديث نموذج البائع',
	yourBuyerFormGotUpdated: 'تم تحديث نموذج المشتري الخاص بك',
	somethingWentWrongWithUpdatingBuyerForm: 'حدث خطأ في تحديث نموذج المشتري',
	sellOffer: 'عرض بيع',
	'country/currency': 'الدولة / العملة',
	profitRatio: 'نسبة الربح',
	profitRatioIsRequired: 'نسبة الربح مطلوبة',
	profitRatioInRangeError: 'يجب أن تكون نسبة الربح بين',
	atleastSelectOneMethod: 'الرجاء تحديد طريقة دفع واحدة على الأقل',
	maximumAmount: 'الحد الأقصى للمبلغ',
	valueMustBePositive: 'يجب أن تكون القيمة موجبة',
	mustBeGreaterThanMinValue: 'يجب أن تكون القيمة أكبر من الحد الأدنى للقيمة',
	minimumAmount: 'الحد الأدنى للمبلغ',
	mustBeLessThanMaxValue: 'يجب أن تكون القيمة أقل من الحد الأقصى للقيمة',
	visibleForUsers: 'مرئي للمستخدمين',
	no: 'لا',
	yes: 'نعم',
	update: 'تحديث',
	buyOffer: 'عرض الشراء',
	logout: 'تسجيل خروج',
	privacyPolicy: 'سياسة الخصوصية',
	companies: 'شركات',
	news: 'أخبار',
	termsOfUse: 'شروط الاستخدام',
	merchantsOfferSubtitle:
		'عمليات تنقل سلسة، إمكانيات لا حدود: قم بتطوير عملك بفضل التميز في نظام الدفع نقطة لنقطة (P2P)',
	limit: 'حد',
	countries: 'الدول',
	min: 'الحد الأدنى',
	max: 'أقصى',
	all: 'الجميع',
	merchantProfile: 'ملف التاجر',
	clientProfile: 'ملف العميل',
	availableLimit: 'متاح / محدود',
	trade: 'تجارة',
	fees: 'مصاريف',
	reviews: 'المراجعات',
	buyers: 'المشترون',
	sellers: 'الباعة',
	buyer: 'مشتري',
	seller: 'بائع',
	termsAndConditions: 'البنود و الظروف',
	unitPrice: 'سعر الوحدة',
	youSale: 'أنت تبيع',
	youReceive: 'انت تستقبل',
	selectPaymentMethods: 'حدد طرق الدفع',
	account: 'حسابات',
	note: 'ملحوظة',
	pleaseWriteYourNote: 'يرجى كتابة ملاحظتك',
	certainPaymentMethodsMayHaveFees: 'قد يكون لبعض طرق الدفع رسوم وحدود يومية يحددها مزود الدفع.',
	youPay: 'انت تدفع',
	orderHeading: 'طلب',
	orderSubtitle: 'التاريخ يعيد نفسه: إعادة الطلب بسهولة، في كل مرة!',
	dateRange: 'نطاق التاريخ',
	show: 'عرض',
	entries: 'الإدخالات',
	bestSellers: 'أفضل الباعة',
	maxOrders: 'أكثر الطلبات',
	newSellers: 'الباعة الجدد',
	minOrders: 'الأقل طلبات',
	searchOrder: 'البحث عن الطلب',
	id: 'معرف',
	client: 'العميل',
	type: 'النوع',
	creationDate: 'تاريخ الإنشاء',
	availability: 'التوفر',
	buyersTerm: 'شروط وأحكام المشتري',
	sellersTerm: 'شروط وأحكام البائع',
	bankDeposit: 'الإيداع البنكي فقط',
	accHolder: 'صاحب الحساب الأصلي',
	thirdParty: 'غير مقبول الدفع من طرف ثالث',
	wedFri: 'الأربعاء-الجمعة',
	merchantReview: 'مراجعة التاجر',
	apiNotFound: 'لم يتم العثور على واجهة برمجة التطبيقات',
	unableToAuthenticateTheUser: 'جلسة غير صالحة أو منتهية الصلاحية',
	somethingWentWrong: 'هناك خطأ ما',
	constraintViolated: 'تم انتهاك القيد',
	recordIsLinked: 'السجل مرتبط',
	parametersCannotBeNull: 'لا يمكن أن تكون المعلمات فارغة',
	methodArgumentsAreInvalid: 'وسيطات الأسلوب غير صالحة',
	fileUploadingError: 'خطأ في تحميل الملف',
	parameterIsNotValid: 'معلمة الطلب غير صالحة',
	requiredParametersAreMissing: 'المعلمات المطلوبة مفقودة',
	pleaseEnterValidEmailAddress: 'يرجى إدخال عنوان بريد إلكتروني صالح',
	passwordDoesNotMatchRequirements:
		'كلمة المرور لا تتطابق مع المتطلبات: الحد الأدنى 8 والحد الأقصى 16 حرفًا، وتتضمن على الأقل حرفًا كبيرًا واحدًا وحرفًا صغيرًا واحدًا ورقمًا واحدًا وحرفًا خاصًا واحدًا',
	invalidMerchantOfferRequest: 'طلب عرض التاجر غير صالح',
	invalidTradingAccountGroup: 'مجموعة حسابات التداول غير صالحة',
	countryIsNotAllowed: 'البلد غير مسموح به في نظام P2P',
	orderIdProvidedIsInvalid: 'معرف الطلب المقدم غير صالح',
	orderIsClosed: 'الطلب مغلق',
	actionNotApplicable: 'الإجراء غير قابل للتطبيق',
	errorOccurredWhileProcessingYourRequest: 'حدث خطأ أثناء معالجة طلبك',
	problemOccurredWhileLoadingRecords: 'حدثت مشكلة أثناء تحميل السجلات',
	problemOccurredWhileLoadingRecord: 'حدثت مشكلة أثناء تحميل السجل',
	problemOccurredWhileAddingRecord: 'حدثت مشكلة أثناء إضافة السجل',
	problemOccurredWhileUpdatingRecord: 'حدثت مشكلة أثناء تحديث السجل',
	problemOccurredWhileDeletingRecord: 'حدثت مشكلة أثناء حذف السجل',
	recordAlreadyExists: 'السجل موجود من قبل',
	noRecordFound: 'لا يوجد سجلات',
	accessDeniedMissingKyc: 'تم رفض الوصول بسبب فقدان Kyc',
	invalidCredentials: 'بيانات الاعتماد غير صالحة',
	invalidPaymentMethod: 'طريقة الدفع غير صالحة',
	invalidOfferType: 'نوع العرض غير صالح',
	orderIsInProgress: 'الطلب قيد التقدم',
	orderReviewIsAlreadyPresent: 'مراجعة الطلب موجودة بالفعل',
	orderIsClosedMoreThanWeekAgo: 'الطلب مغلق منذ أكثر من أسبوع',
	userIsInactive: 'المستخدم غير نشط',
	userCountryNotAllowed: 'بلد المستخدم غير مسموح به',
	orderIsExpiredOrClosed: 'انتهت صلاحية الطلب أو تم إغلاقه',
	invalidOrderAction: 'إجراء أمر غير صالح',
	onlyOneOrderAllowedAtTime: 'يُسمح بطلب واحد فقط في كل مرة',
	invalidTradingAccount: 'حساب التداول غير صالح',
	merchantBelongsToDifferentCountry: 'لا يمكن التجارة! التاجر ينتمي إلى بلد مختلف',
	orderAmountIsMoreThanAvailableBalance: 'مبلغ الطلب أكثر من الرصيد المتاح!',
	merchantOfferNotAvailable: 'عرض التاجر غير متوفر!',
	amountDoesNotSatisfyAvailableLimit: 'المبلغ لا يلبي الحد المتاح!',
	countriesPaymentMethods: 'الدول / وسائل الدفع',
	countriesPaymentSubtitle: 'قم بإجراء صفقات P2P TetherUS اليوم بدون رسوم وبوسيلة الدفع المفضلة لديك!',
	countryCode: 'كود الدولة',
	currencyCode: 'كود العملة',
	maxProfitRatio: 'نسبة الربح القصوى',
	public: 'نشط',
	administration: 'الاعدادات',
	searchInCountriesAndPaymTable: 'البحث في الدول/طرق الدفع',
	welcomeBack: 'مرحبًا بعودتك',
	loginToAdminPortal: 'تسجيل الدخول إلى بوابة المشرف',
	enterYourEmail: 'أدخل بريدك الإلكتروني',
	enterPassword: 'ادخل رقمك السري',
	readOur: 'اقرأ لدينا',
	clients: 'العملاء',
	clientsPageHeading: 'مركز تمكين P2P: ربط المستخدمين، إطلاق الإمكانيات',
	searchInClientsTable: 'البحث في قائمة العملاء',
	orderWith: '{{type}} طلب من {{name}}',
	timeCreated: 'وقت الانشاء',
	orderNumber: 'رقم الطلب',
	clientDetails: 'تفاصيل العميل',
	merchantDetails: 'تفاصيل التاجر',
	orderDetails: 'تفاصيل الطلب',
	paymentDetails: 'تفاصيل الدفع',
	clientPay: 'الغميل يدفع',
	clientAccount: 'حساب العميل',
	merchantReceive: 'التاجر يستقبل',
	orderHistory: 'تاريخ الطلب',
	copiedSuccessfully: 'تم النسخ بنجاح',
	accountGroupLimits: 'حدود مجموع الحسابات',
	tradingAccountGroup: 'مجموعة الحسابات',
	tradingPlatform: 'منصة التداول',
	orderMin: 'الحد الأدني للطلب',
	orderMax: 'الحد الأقصي للطلب',
	addAccountGroupLimits: 'إضافة مجموعة حسابات جديدة',
	submit: 'إرسال',
	editAccountGroupLimits: 'تعديل مجموعة حسابات جديدة',
	deleteConfirmation: 'هل أنت متأكد أنك تريد حذف هذا السجل؟',
	confirmDelete: 'تأكيد الحذف',
	ok: 'موافق',
	cancel: 'إلغاء',
	supportRoom: 'الدعم',
	theorder: 'الطلب',
	color: 'اللون',
	backgroundColor: 'لون الخلفية',
	confirmNewPaymentMethodDelete: 'هل تريد بالتاكيد حذف طريقة الدفع هذه؟',
	currencyCodeIsRequired: 'يجب أدخال كود العملة',
	countryCodeIsRequired: 'يجب أدخال كود الدولة',
	countryNameIsRequired: 'يجب أدخال اسم الدولة',
	maxProfitRatioIsRequired: 'يجب أدخال نسبة الربح القصوى',
	reverseToPreviousStage: 'الرجوع للخطوه السابقه',
	releasePayment: 'الافراج عن الدفع',
	closeAppeal: 'قفل الطلب',
	cancelOrder: 'الغاء الطلب',
	cancelThisOrder: 'الغاء هذا الطلب',
	cancelOrderMessage: 'لقد تأكدت من عدم قيام العميل/التاجر بالدفع',
	closeOrderMessage: 'لقد تأكدت من أن كلا الطرفين قاما بتحويل الدفعة. سيتم وضع علامة على أمر التأكيد على أنه مكتمل.',
	closeThisAppeal: 'قفل الطلب',
	releaseMerchantPayment: 'الافراج عن الدفع التجاري',
	releaseMerchantPaymentMessage1: 'لقد أكدت أن العميل قد قام بتحويل الدفعة',
	releaseMerchantPaymentMessage2: 'أوافق على الإفراج عن مدفوعات التاجر للعميل.',
	releaseMerchantPaymentMessageSell: 'لقد أكدت أن التاجر قد قام بتحويل الدفعة',
	releaseMerchantPaymentMessageSell2: 'أوافق على الإفراج عن مدفوعات العميل للتاجر.',
	revertToPreviousStage: 'الرجوع للخطوه السابقه',
	revertToPreviousStageMessage: 'هل أنت متأكد أنك تريد تغيير حالة الطلب من الاستئناف إلى المنقول؟',
	Assignee: 'العميل',
	Resolved: 'تم الحل',
	AssignedOn: ' تم التعيين في',
	Reassign: 'إعادة التعيين',
	invalidmerchantOffer: 'عرض التاجر غير صالح',
	invalidtradingaccountgroup: 'مجموعة حسابات التداول غير صالحة',
	orderidisinvalid: 'معرف الطلب المقدم غير صالح',
	Orderisclosed: 'تم غلق الطلب',
	invalidorderaction: 'الامر غير صحيح',
	onlyoneorderallowed: 'طلب واحد متاح في المره الواحده',
	cannotttrade: 'لايمكن المتاجره',
	orderamountismorethanbalance: 'مبلغ الطلب أكثر من الرصيد المتاح!',
	merchantoffernotavailable: 'طلب التاجر غير متاح',
	Amountdoesnotsatisfyavailablelimit: 'المبلغ لا يلبي الحد المتاح!',
	ErroroccurredwhileprocessingMT5request: 'حدث خطأ أثناء معالجة طلب MT5.!',
	Amountdoesnotsatisfyofferallowedlimit: 'المبلغ لا يلبي الحد المسموح به للعرض!',
	cancelAppeal: 'إلغاء الاستئناف',
	revert: 'الرجوع',
	release: 'الإفراج',
	paymentMethodName: 'اسم طريقة الدفع',
	adminusers: 'حسابات الادمن',
	admin: 'المشرف',
	adminDetailsforAddEditAdmin: 'الوحدة الإدارية: إضافة القادة، رفع التميز معًا ',
	emailAddress: 'عنوان البريد',
	role: 'الدور',
	phoneNumber: 'رقم التليفون',
	canHandleAppeal: 'يستطيع الاستئناف',
	addNewAdmin: 'اضافه مشرف جديد',
	lastName: 'اسم الجد',
	accountGroups: 'الحسابات التداوليه',
	notificationsHeading: 'الاشعارات',
	noNotificationsFound: 'لم يتم العثور على اشعارات',
	opened: 'تم الفتح',
	appeal: 'المناشدات',
	expired: 'منتهي الصلاحية',
	placed: 'تم الوضع',
	unknown: 'غير معرف',
	quantity: 'الكميه',
	notAvailable: 'غير متاح',
	currentpassword: 'الرقم السري الحالي',
	newpassword: 'رقم سري جديد',
	matchedPassword: 'الرقم السري الجديد لايتطابق',
	appealTask: 'طلب مستانف',
	paymentReceived: 'تم استلام الدفع',
	activeTask: 'طلب نشط',
	paymentTransfered: 'تم تحويل الدفع',
	accepted: 'تمت الموافقه',
	notificationNewAppealTaskAssigned: 'تم فتح استئناف للطلب من نوع {{orderType}} رقم #{{orderNumber}} بمبلغ {{amount}}.',
	notificationAppealNewMessage: 'رسالة جديدة في الطلب #{{orderNumber}} في {{createdAt}}.',
	notificationAppealReminder: 'لديك استئناف معلق سيتم إعادة تعيينه في {{reassignedInMinutes}} ساعة.',
	notificationAppealReassigned:
		'تمت إعادة تعيين مهمة الاستئناف الخاصة بك إلى {{newAssigneeFullName}} بسبب {{reassignMethod}}.',
	seeMore: 'اظهار المزيد',
	finishedSuccessfuly: 'تم الانتهاء بنجاح',
	adminUser: 'المشرف',
	enterValidValue: 'ادخل قيمة صالحة',
	requiredField: 'هذا الحقل مطلوب',
	resolvedBy: 'تم حلها بواسطة',
	assigneToMe: 'تعيين لي',
	averageRating: 'متوسط الاراء',
	orderMaxShouldBeGreaterThanOrderMin: 'يجب ان يكون الطلب الحد الاقصى اكبر من الطلب الحد الادنى',
	orderMinShouldBeLessThanOrderMax: 'يجب ان يكون الطلب الادنى اقل من الطلب الحد الاقصى',
	merchantPay: 'التاجر يدفع',
	orderMaxShouldNotBeGreaterThanFiftyThousand: 'يجب ان يكون الطلب الحد الاقصى اصغر من 50,000',
	orderMinShouldNotBeGreaterThanFiftyThousand: 'يجب ان يكون الطلب الادنى اصغر من 50,000',
	buyMin: 'اقل شراء',
	buyMax: 'اكبر شراء',
	sellMin: 'اقل بيع',
	sellMax: 'اكبر بيع',
	accessDenied: 'تم الرفض',
	orderNumberProvidedIsInvalid: 'رقم الطلب المقدم غير صالح',
	errorProccessingMT5: 'حدث خطأ أثناء معالجة MT5',
	amountDosentSatisfyLimit: 'المبلغ لا يفي بالحد',
	chatHasBeenClosed: 'تم إغلاق الدردشة',
	emailAlreadyExist: 'البريد الالكتروني موجود مسبقا',
	phoneNumberDoesNotMatchTheRequirement: 'رقم الهاتف لا يطابق المتطلبات',
	newPasswordAndConfirmPasswordNotSame: 'كلمة المرور الجديدة وتأكيد كلمة المرور ليسا نفس الشيء',
	userIsSuspended: 'تم تعليق المستخدم',
	userIsBlocked: 'تم حظر المستخدم',
	merchantNotFound: 'لم يتم العثور على التاجر',
	invalidOTP: 'كلمة المرور غير صالحة لمرة واحدة',
	suspendedConfirmation: 'هل أنت متأكد أنك تريد تعليق',
	unsuspendedConfirmation: 'هل أنت متأكد أنك تريد إلغاء التعليق',
	blockConfirmation: 'هل أنت متأكد أنك تريد حظر',
	unblockConfirmation: 'هل أنت متأكد أنك تريد إلغاء الحظ',
	yesterday: 'أمس',
	daysAgo: 'أيام',
	today: 'اليوم',
	notificationNewOrderMessage: `طلب {{orderType}} جديد #{{orderNumber}} بقيمة {{amount}} تم فتحه.`,
	notificationOrderNewMessage: `رسالة جديدة في الطلب #{{orderNumber}} في {{createdAt}}`,
	notificationOrderExpiryWarning: `طلبك #{{orderNumber}} على وشك الانتهاء في {{orderExpiresTimestamp}}`,
	notificationOrderExpired: `انتهى الطلب #{{orderNumber}}.`,
	notificationOrderStatusUpdate: `تغيير في حالة الطلب #{{orderNumber}}. {{orderStatusBefore}} → {{orderStatusAfter}}`,
	notificationOfferDisabled: `تم سحب عرضك {{offerType}} بسبب {{reason}}`,
	notificationAccountBalanceLow: `رصيد حساب التاجر منخفض. الرصيد الحالي: {{amount}}`,
	notificationSellAvailableLow: `كمية عرض البيع التي حددتها على وشك النفاد. قم بتحديث الكمية لضمان صلاحية العرض. الرصيد الحالي للبيع المتاح: {{sellAvailable}}`,
	withdrawal: 'سحب',
	deposit: 'ايداع',
	dashboardActiveOrders: 'طلبات المستأنفه',
	dashboardAppealOrders: 'طلبات مباشرة',
	dashboardTotalOrders: 'مجموع الطلبات',
	dashboardTotalMerchants: 'مجموع التجار',
	dashboardTotalClients: 'مجموع العملاء',
	reports: 'التقارير',
	thisWeek: 'هذا الاسبوع',
	thisMonth: 'هذا الشهر',
	thisYear: 'هذا السنة',
	thisQuarter: 'هذا الربع',
	last60Days: 'اخر 60 يوم',
	totalAnalyticsOrder: 'مجموع الطلبات',
	buyOrders: 'طلبات الشراء',
	sellOrders: 'طلبات البيع',
	myOffersSubtitle: 'هذه هي العروض التي سيتم تقديمها لعملائك على Merchant.',
	calculatedPrice: 'السعر النهائي',
	notFound: 'الطلب غير موجود',
	actions: 'اجراءات',
	orderId: 'رقم الطلب',
	noPaymentMethod: 'لا توجد وسيله دفع',
	buyMaxShouldNotBeGreaterThanFiftyThousand: 'يجب ان يكون الطلب الحد الاقصى اصغر من 50,000',
	buyMinShouldNotBeGreaterThanFiftyThousand: 'يجب ان يكون الطلب الادنى اصغر من 50,000',
	sellMaxShouldNotBeGreaterThanFiftyThousand: 'يجب ان يكون الطلب الحد الاقصى اصغر من 50,000',
	sellMinShouldNotBeGreaterThanFiftyThousand: 'يجب ان يكون الطلب الادنى اصغر من 50,000',
	buyMaxShouldBeGreaterThanBuyMin: 'يجب ان يكون الطلب الحد الاقصى اكبر من الطلب الادنى',
	buyMinShouldBeLessThanBuyMax: 'يجب ان يكون الطلب الادنى اقل من الطلب الحد الاقصى',
	sellMaxShouldBeGreaterThanSellMin: 'يجب ان يكون الطلب الحد الاقصى اكبر من الطلب الادنى',
	sellMinShouldBeLessThanSellMax: 'يجب ان يكون الطلب الادنى اقل من الطلب الحد الاقصى',
	invalidType: 'نوع الملف غير صالح. يرجى تحميل ملف JPG أو JPEG أو PNG أو PDF.',
	fileExceedLimit: 'حجم الملف يتجاوز الحد المسموح به (5 ميجابايت).',
	expireIn: 'تنتهي صلاحية في',
	enterOtp: 'أدخل رمز OTP',
	exchangeRate: 'سعر الصرف',
	sortBy: 'ترتيب',
	enterYourOtp: 'أدخل رمز التحقق الخاص ببريدك الإلكتروني',
	notificationPendingAppeal:
		'الاستئناف مع {{orderNumber}} لم يتم الرد عليه بواسطة {{oldAssigneeFullName}} في {{inactiveTimeMinutes}} دقيقة. انقر هنا لإعادة التعيين.',
	cannotEnableOffer: 'لا يمكن تفعيل العرض بسبب رصيد منخفض',
	ActivateConfirmation: 'هل أنت متأكد أنك تريد تنشيط',
	deactivateConfirmation: 'هل أنت متأكد أنك تريد إلغاء التنشيط',
	passwordChange: 'تغيير كلمة المرور',
	didNotreceiveOtp: 'لم تستلم الرمز؟',
	resend: 'اعاده ارسال',
	biggerThanZero: 'يجب أن يكون القيمة أكبر من 0',
	lessThanhundred: 'يجب أن تكون القيمة أقل من او يساوي 100',
	inactive: 'غير نشط',
	appealReassignNewAdmin:
		'تم إعادة تعيين مهمة الاستئناف الجديدة لك بواسطة {{oldAssigneeFullName}} بواسطة {{reassignMethod}} التعيين',
	NEW_ORDER: 'طلب جديد',
	ORDER_NEW_MESSAGE: 'رسالة جديدة في الطلب',
	ORDER_EXPIRY_WARNING: 'تحذير انتهاء الطلب',
	ORDER_EXPIRED: 'انتهت صلاحية الطلب',
	ORDER_STATUS_UPDATE: 'تحديث حالة الطلب',
	OFFER_DISABLED: 'العرض تم تعطيله',
	ACCOUNT_BALANCE_LOW: 'رصيد الحساب منخفض',
	SELL_AVAILABLE_LOW: 'توفر البيع منخفض',
	NEW_APPEAL_TASK_ASSIGNED: 'تم تعيين مهمة استئناف جديدة',
	APPEAL_NEW_MESSAGE: 'رسالة جديدة في الاستئناف',
	APPEAL_REMINDER: 'تذكير بالاستئناف',
	PENDING_APPEAL: 'استئناف معلق',
	APPEAL_REASSIGNED: 'تم إعادة تعيين الاستئناف',
	APPEAL_REASSIGNED_NEW_ADMIN: 'تم إعادة تعيين الاستئناف لمسؤول جديد',
	handleAppeal: 'معالجة الاستئناف',
	appealTasks: 'الطلبات المعلقه',
	transferred: 'تم التحويل',
	completed: 'تم الاكتمال',
	rejected: 'تم الرفض',
	INSUFFICIENT_BALANCE: 'رصيد غير كافٍ',
	COOLDOWN: 'فترة خمول',
	BY_ADMIN: 'بواسطة المشرف',
	MANUAL: 'يدوي',
	INACTIVE: 'غير نشط',
	typeMessageHere: 'اكتب رسالة هنا',
	OTPIsrequired: 'الرمز السري مطلوب!',
	Pleaseuploadthepaymentproof: 'يرجى تحميل إثبات الدفع',
	agent: 'العميل',
	chatIsDisabled: 'الدردشة غير متاحة',
	sessionExpired: 'انتهت الجلسة',
	block: 'حظر',
	unblock: 'رفع الحظر',
	suspend: 'تعليق',
	unsuspend: 'إلغاء التعليق',
	search: 'بحث',
	allRightsReserved: 'كل الحقوق محفوظة',
	customeRange: 'نطاق مخصص',
	confirm: 'تاكيد',
	suspendClient: 'تعليق العميل',
	suspendMerchant: 'تعليق التاجر',
	unsuspendMerchant: 'رفع تعليق التاجر',
	unsuspendClient: 'رفع تعليق العميل',
	download: 'تحميل',
	chatnow: 'تواصل الان',
	dashboardTotalWithdrawalAmount: 'إجمالي مبلغ السحب',
	dashboardTotalDepositAmount: 'إجمالي مبلغ الإيداع',
	completionRate: 'نسبة الطلبات المكتمله',
	merchantDetailsDescription:
		'استكشاف أفضل سمات التجار وآرائهم، تقديم تحليلات قيمة لتعزيز تجربتك في التداول بنظام التجارة الذي توفره لك انزو',
	merchantsReviewSubtitle: ' تعزيز الثقة والجودة عبر المنصة',
	appealTaskSubtitle: 'مركز الاستئناف P2P: حيث تلتقي التحديات بحلول ممكنة',
	CountryAndPaymentMethods: ' الدقة في كل معاملة: تخصيص البلدان وطرق الدفع لنجاحك',
	accountGroupLimitsSubtitle: ' إمكانيات لا حدود، تحكم محدد: صياغة النجاح من خلال حدود الطلبات لدى المسؤول',
	tradingPaltform: 'منصة التداول',
	accountGroup: 'مجموعة الحساب',
	accountNumber: 'رقم الحساب',
	balance: 'الرصيد',
	onHoldBalance: 'الرصيد المعلق',
	otpexpired: 'لقد انتهت صلاحية رمز OTP!',
	reachedotplimit: 'لقد وصلت إلى الحد الأقصى لمحاولات رمز OTP!!',
	maintenance: 'صيانة',
	maintenanceSubtitle: 'ربط الأيدي لصيانه سلسة',
	startTime: 'وقت البدء',
	endTime: 'وقت الانتهاء',
	enabled: 'مفعل',
	deleteMaintanceConfirmation: 'هل أنت متأكد أنك تريد حذف هذه الصيانة؟',
	annoncement: 'سيكون هنالك صيانة في النظام مجدولة من {{from}} - {{to}}',
	ordersAmountStatistic: 'إحصائيات مبلغ الطلبات',
	buyAmount: 'مبلغ الإيداع',
	sellAmount: 'مبلغ البيع',
	validationChatMessage: ' الأحرف الخاصة <, >, & غير مسموح بها.',
};
