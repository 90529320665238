import { notification, Button, Card, Form, Input, Select, Switch, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { isMyBuyOfferFormInValid, isMySellOfferFormInValid } from '../../helpers/isMyOfferFormValid';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { getCountryByCountryCode } from '../../helpers/countriesMap';
import { MyCountry } from '../../models/Country';
import { PaymentMethod as PaymentMethodItem } from '../../models/PaymentMethod';
import './MyOffers.scss';
import { useSearchParams } from 'react-router-dom';
import Header from '../../components/Header/Header';

const MyOffers = () => {
	const { t } = useTranslation();
	const [sellOfferForm] = Form.useForm();
	const [buyOfferForm] = Form.useForm();
	const [myCountry, setMyCountry] = React.useState<MyCountry>({
		name: '',
		code: '',
		currencyCode: '',
	});
	const [myPaymentMethods, setMyPaymentMethods] = React.useState<PaymentMethodItem[]>([]);
	const [sellerFormSubmitDisabled, setSellerFormSubmitDisabled] = React.useState(true);
	const [buyerFormSubmitDisabled, setBuyerFormSubmitDisabled] = React.useState(true);
	const [sellCalculatedPrice, setSellCalculatedPrice] = useState(0);
	const [buyCalculatedPrice, setBuyCalculatedPrice] = useState(0);
	const [exchangeBaseCurrency, setExchangeBaseCurrency] = useState('');
	const [maxProfitRatio, setMaxProfitRatio] = useState(0);
	const [livePrice, setLivePrice] = useState(0);
	const [dataLoading, setDataLoading] = useState(false);
	const token = getUserInfo()?.token;
	const [sellFormErrors, setSellFormErrors] = useState('');
	const [buyFormErrors, setBuyFormErrors] = useState({});
	const [merchant, setMerchant] = useState();
	const VALIDATE_TRIGGER = ['onChange', 'onBlur', 'onTextChange', 'onClick', 'onFocus'];

	const [searchParams] = useSearchParams();
	const merchantId = searchParams.get('merchantId') || '';
	const status = searchParams.get('status');
	const isSuspended = status === 'SUSPENDED';

	React.useEffect(() => {
		setDataLoading(true);

		Promise.all([
			axios.get(API_ENDPOINTS.myOffers.replace('%type%', 'SELL').replace('%merchantId%', merchantId), {
				headers: { Authorization: `Bearer ${token}` },
			}),
			axios.get(API_ENDPOINTS.myOffers.replace('%type%', 'BUY').replace('%merchantId%', merchantId), {
				headers: { Authorization: `Bearer ${token}` },
			}),
			axios.get(API_ENDPOINTS.livePirce, {
				headers: { Authorization: `Bearer ${token}` },
			}),
			axios.get(API_ENDPOINTS.countries, {
				headers: { Authorization: `Bearer ${token}` },
			}),
			axios.get(API_ENDPOINTS.getMerchant.replace('%merchantId%', merchantId), {
				headers: { Authorization: `Bearer ${token}` },
			}),
		])
			.then(([sellOffer, buyOffer, livePrices, countries, merchant]: any) => {
				const merchantData = merchant.data;
				const myCountryByCode = getCountryByCountryCode(merchantData?.countryCode ?? '', t);
				const myCountry = countries.data.find((country: any) => country.countryCode === myCountryByCode.code);
				const sellerData = sellOffer.data;
				const buyerData = buyOffer.data;
				const livePriceValue = livePrices.data.find(
					(livePrice: any) => livePrice.currencyCode === myCountryByCode.currencyCode,
				)?.rate;

				setDataLoading(false);
				setExchangeBaseCurrency(sellerData.exchangeBaseCurrency);
				setMyCountry(myCountryByCode);
				setLivePrice(livePriceValue);
				setSellCalculatedPrice(livePriceValue - livePriceValue * sellerData.profitRatio);
				setBuyCalculatedPrice(livePriceValue + livePriceValue * sellerData.profitRatio);
				setMaxProfitRatio(myCountry?.maxProfitRatio);
				setMyPaymentMethods(
					myCountry.paymentMethods?.map((paymentMethod: any) => ({
						key: paymentMethod.methodId.toString(),
						value: paymentMethod.methodId,
						label: paymentMethod.methodName,
					})),
				);
				sellOfferForm.setFieldsValue({
					...sellerData,
					profitRatio: sellerData.profitRatio,
					currency: `${myCountryByCode.name}/${myCountryByCode.currencyCode}`,
					methods: sellerData.paymentMethods,
				});
				buyOfferForm.setFieldsValue({
					...buyerData,
					profitRatio: buyerData.profitRatio,
					currency: `${myCountryByCode.name}/${myCountryByCode.currencyCode}`,
					methods: buyerData.paymentMethods,
				});
			})
			.catch((_) => setDataLoading(false));

		return () => {
			sellOfferForm.resetFields();
			buyOfferForm.resetFields();
		};
	}, [buyOfferForm, sellOfferForm]);

	const onSellerFormChange = () => {
		const sellOfferValues = sellOfferForm.getFieldsValue();

		if (sellOfferValues.min && sellOfferValues.max) {
			let min = parseInt(sellOfferValues.min);
			let max = parseInt(sellOfferValues.max);

			if (min >= max) {
				setSellFormErrors(t<string>('mustBeLessThanMaxValue'));
			} else if (max < min) {
				setSellFormErrors(t<string>('mustBeGreaterThanMinValue'));
			} else {
				setSellFormErrors('');
			}
		}

		if (sellOfferValues.profitRatio !== undefined && sellOfferValues.profitRatio !== null) {
			const profitRatio = sellOfferValues.profitRatio;
			const value = profitRatio ? livePrice - livePrice * (profitRatio / 100) : 0;
			setSellCalculatedPrice(Number(value?.toFixed(2)));
		}
		setSellerFormSubmitDisabled(isMySellOfferFormInValid(sellOfferValues));
	};

	const onBuyerFormChange = () => {
		const buyOfferValues = buyOfferForm.getFieldsValue();

		if (buyOfferValues.min && buyOfferValues.max) {
			let min = parseFloat(buyOfferValues.min);
			let max = parseFloat(buyOfferValues.max);

			if (min >= max) {
				setBuyFormErrors(t<string>('mustBeLessThanMaxValue'));
			} else if (max < min) {
				setBuyFormErrors(t<string>('mustBeGreaterThanMinValue'));
			} else {
				setBuyFormErrors('');
			}
		}

		if (buyOfferValues.profitRatio !== undefined && buyOfferValues.profitRatio !== null) {
			const profitRatio = buyOfferValues.profitRatio;
			const value = profitRatio ? livePrice + livePrice * (profitRatio / 100) : 0;
			setBuyCalculatedPrice(Number(value?.toFixed(2)));
		}
		setBuyerFormSubmitDisabled(isMyBuyOfferFormInValid(buyOfferValues));
	};

	const onSellFormSubmit = React.useCallback((values: any) => {
		const { isPublic, max, min, termsNote, profitRatio, methods, available } = values;
		setDataLoading(true);
		console.log(values);
		axios
			.put(
				API_ENDPOINTS.myOffers.replace('%type%', 'SELL').replace('%merchantId%', merchantId),
				{
					isPublic,
					max: Number(max),
					min: Number(min),
					termsNote,
					methods,
					available,
					profitRatio: Number(profitRatio) / 100,
				},
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.then(() => {
				setDataLoading(false);
				setSellerFormSubmitDisabled(true);

				notification['success']({
					message: '',
					description: t<string>('yourSellerFormGotUpdated'),
				});
			})
			.catch((error) => {
				console.error(error);
				setDataLoading(false);
				setSellerFormSubmitDisabled(true);
			});
	}, []);

	const onBuyFormSubmit = React.useCallback((values: any) => {
		setDataLoading(true);
		axios
			.put(
				API_ENDPOINTS.myOffers.replace('%type%', 'BUY').replace('%merchantId%', merchantId),
				{ ...values, profitRatio: Number(values.profitRatio) / 100 },
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.then(() => {
				setDataLoading(false);
				setBuyerFormSubmitDisabled(true);

				notification['success']({
					message: '',
					description: t<string>('yourBuyerFormGotUpdated'),
				});
			})
			.catch((error) => {
				console.error(error);
				setDataLoading(false);
				setBuyerFormSubmitDisabled(true);
			});
	}, []);

	return (
		<section className='my-offers-container bg-white'>
			<Header title={t<string>('myOffers')} description={t<string>('myOffersSubtitle')} />
			<div className='my-offers-forms-continer'>
				<div>
					<Card title={t<string>('buyOffer')}>
						<Form
							name='basic'
							form={buyOfferForm}
							layout='vertical'
							onValuesChange={onBuyerFormChange}
							autoComplete='off'
							disabled={dataLoading}
							onFinish={onBuyFormSubmit}
						>
							<div className='my-offers-form-content mx-4'>
								<div>
									<Form.Item label={t<string>('countryCurrency')} name='currency'>
										<Input disabled />
									</Form.Item>
									<Form.Item
										label={t<string>('profitRatio')}
										name='profitRatio'
										rules={[
											{ required: true, message: t<string>('profitRatioIsRequired') },
											() => ({
												validator(_, value) {
													return value > maxProfitRatio || value < 0
														? Promise.reject(`${t<string>('profitRatioInRangeError')} 0% - ${maxProfitRatio}%`)
														: Promise.resolve();
												},
											}),
										]}
										validateTrigger={VALIDATE_TRIGGER}
									>
										<Input type='number' suffix='%' />
									</Form.Item>
									<Form.Item label={t<string>('calculatedPrice')}>
										<Input disabled value={buyCalculatedPrice?.toFixed(3)} suffix={myCountry.currencyCode} />
									</Form.Item>
									<Form.Item
										label={t<string>('paymentMethods')}
										name='methods'
										rules={[{ required: true, message: t<string>('atleastSelectOneMethod') }]}
									>
										<Select mode='multiple' allowClear style={{ width: '100%' }} options={myPaymentMethods} />
									</Form.Item>
									<Form.Item label={t<string>('available')} name='available'>
										<Input disabled suffix={exchangeBaseCurrency} />
									</Form.Item>
									<Form.Item
										label={t<string>('maximumAmount')}
										name='max'
										validateTrigger={VALIDATE_TRIGGER}
										rules={[
											{ required: true },
											() => ({
												validator(_, value) {
													if (value === '') {
														return Promise.reject(`${t<string>('amountIsRequired')}`);
													} else if (parseFloat(value) > 1000000) {
														return Promise.reject(`${t<string>('maxValueRange')}`);
													}
													return value < 0
														? Promise.reject(`${t<string>('valueMustBePositive')}`)
														: parseFloat(buyOfferForm.getFieldValue('max')) <=
														  parseFloat(buyOfferForm.getFieldValue('min'))
														? Promise.reject(`${buyFormErrors} - ${buyOfferForm.getFieldValue('min')}`)
														: Promise.resolve();
												},
											}),
										]}
									>
										<Input type='number' min={0} max={1000000} step={'any'} suffix={myCountry.currencyCode} />
									</Form.Item>
									<Form.Item
										label={t<string>('minimumAmount')}
										name='min'
										validateTrigger={VALIDATE_TRIGGER}
										rules={[
											{ required: true },
											() => ({
												validator(_, value) {
													if (value === '') {
														return Promise.reject(`${t<string>('amountIsRequired')}`);
													}
													return value < 0
														? Promise.reject(`${t<string>('valueMustBePositive')}`)
														: parseFloat(buyOfferForm.getFieldValue('min')) >=
														  parseFloat(buyOfferForm.getFieldValue('max'))
														? Promise.reject(`${buyFormErrors} - ${buyOfferForm.getFieldValue('max')}`)
														: Promise.resolve();
												},
											}),
										]}
									>
										<Input type='number' min={0} step={'any'} suffix={myCountry.currencyCode} />
									</Form.Item>
									<Form.Item valuePropName='checked' label={t<string>('visibleForUsers')} name='isPublic'>
										<Switch checkedChildren={t<string>('yes')} unCheckedChildren={t<string>('no')} />
									</Form.Item>
									<Form.Item
										label={t<string>('note')}
										name='termsNote'
										rules={[
											{ required: true },
											{
												validator(_, value) {
													if (value.length < 20 && value.length > 0) {
														setBuyerFormSubmitDisabled(true);
														return Promise.reject(`${t<string>('noteMinLength')}`);
													} else if (value.length > 1000) {
														setBuyerFormSubmitDisabled(true);
														return Promise.reject(`${t<string>('noteMaxLength')}`);
													} else {
														setBuyerFormSubmitDisabled(false);
														return Promise.resolve();
													}
												},
											},
										]}
									>
										<TextArea rows={4} />
									</Form.Item>
									<Form.Item wrapperCol={{ offset: 8, span: 32 }}>
										<Tooltip placement='top' title={isSuspended ? t<string>('userSuspended') : ''}>
											<Button
												type='primary'
												htmlType='submit'
												disabled={buyerFormSubmitDisabled || isSuspended}
												loading={dataLoading}
											>
												{t<string>('update')}
											</Button>
										</Tooltip>
									</Form.Item>
								</div>
							</div>
						</Form>
					</Card>
				</div>
				<div>
					<Card title={t<string>('sellOffer')}>
						<Form
							name='basic'
							form={sellOfferForm}
							layout='vertical'
							onValuesChange={onSellerFormChange}
							autoComplete='off'
							disabled={dataLoading}
							onFinish={onSellFormSubmit}
						>
							<div className='my-offers-form-content'>
								<div>
									<Form.Item label={t<string>('countryCurrency')} name='currency'>
										<Input disabled />
									</Form.Item>
									<Form.Item
										label={t<string>('profitRatio')}
										name='profitRatio'
										rules={[
											{ required: true, message: t<string>('profitRatioIsRequired') },
											() => ({
												validator(_, value) {
													return value > maxProfitRatio || value < 0
														? Promise.reject(`${t<string>('profitRatioInRangeError')} 0% - ${maxProfitRatio}%`)
														: Promise.resolve();
												},
											}),
										]}
									>
										<Input type='number' suffix='%' />
									</Form.Item>
									<Form.Item label={t<string>('calculatedPrice')}>
										<Input disabled value={sellCalculatedPrice?.toFixed(3)} suffix={myCountry.currencyCode} />
									</Form.Item>
									<Form.Item
										label={t<string>('paymentMethods')}
										name='methods'
										rules={[{ required: true, message: t<string>('atleastSelectOneMethod') }]}
									>
										<Select mode='multiple' allowClear style={{ width: '100%' }} options={myPaymentMethods} />
									</Form.Item>
									<Form.Item
										label={t<string>('available')}
										name='available'
										rules={[
											{ required: true, message: t<string>('availableIsRequired') },
											() => ({
												validator(_, value) {
													return value < 0 ? Promise.reject(`${t<string>('valueMustBePositive')}`) : Promise.resolve();
												},
											}),
										]}
									>
										<Input type='number' min={0} step={'any'} suffix={exchangeBaseCurrency} />
									</Form.Item>
									<div className='flex items-center justify-between gap-2 '>
										<Form.Item
											label={t<string>('maximumAmount')}
											name='max'
											validateTrigger={VALIDATE_TRIGGER}
											rules={[
												{ required: true },
												{
													validator(_, value) {
														if (value === '') {
															return Promise.reject(`${t<string>('amountIsRequired')}`);
														} else if (parseFloat(value) > 1000000) {
															return Promise.reject(`${t<string>('maxValueRange')}`);
														}
														return value < 0 && value !== ''
															? Promise.reject(`${t<string>('valueMustBePositive')}`)
															: parseFloat(sellOfferForm.getFieldValue('max')) <=
															  parseFloat(sellOfferForm.getFieldValue('min'))
															? Promise.reject(`${sellFormErrors} - ${sellOfferForm.getFieldValue('min')}`)
															: Number.isNaN(sellOfferForm.getFieldValue('max'))
															? Promise.reject(t<string>('amountIsRequired'))
															: Promise.resolve();
													},
												},
											]}
										>
											<Input type='number' min={0} step={'any'} suffix={myCountry.currencyCode} />
										</Form.Item>
										<Form.Item className='pt-[30px]'>
											<Input
												disabled
												value={(Number(sellOfferForm.getFieldsValue().max || 0) / livePrice).toFixed(3)}
												suffix={exchangeBaseCurrency}
											/>
										</Form.Item>
									</div>
									<div className='flex items-center justify-between gap-2'>
										<Form.Item
											label={t<string>('minimumAmount')}
											name='min'
											rules={[
												{ required: true },
												{
													validator(_, value) {
														if (value === '') {
															return Promise.reject(`${t<string>('amountIsRequired')}`);
														}
														return value < 0
															? Promise.reject(`${t<string>('valueMustBePositive')}`)
															: parseInt(sellOfferForm.getFieldValue('min')) >=
															  parseInt(sellOfferForm.getFieldValue('max'))
															? Promise.reject(`${sellFormErrors} - ${sellOfferForm.getFieldValue('max')}`)
															: Promise.resolve();
													},
												},
											]}
											validateTrigger={VALIDATE_TRIGGER}
										>
											<Input type='number' min={0} suffix={myCountry.currencyCode} />
										</Form.Item>
										<Form.Item className='pt-[30px]'>
											<Input
												disabled
												value={((Number(sellOfferForm.getFieldsValue().min) || 0) / livePrice)?.toFixed(3)}
												suffix={exchangeBaseCurrency}
											/>
										</Form.Item>
									</div>
									<Form.Item valuePropName='checked' label={t<string>('visibleForUsers')} name='isPublic'>
										<Switch checkedChildren={t<string>('yes')} unCheckedChildren={t<string>('no')} />
									</Form.Item>
									<Form.Item
										label={t<string>('note')}
										name='termsNote'
										rules={[
											{ required: true },
											{
												validator(_, value) {
													if (value.length < 20 && value.length > 0) {
														setSellerFormSubmitDisabled(true);
														return Promise.reject(`${t<string>('noteMinLength')}`);
													} else if (value.length > 1000) {
														setSellerFormSubmitDisabled(true);
														return Promise.reject(`${t<string>('noteMaxLength')}`);
													} else {
														setSellerFormSubmitDisabled(false);
														return Promise.resolve();
													}
												},
											},
										]}
									>
										<TextArea rows={4} />
									</Form.Item>
									<Form.Item wrapperCol={{ offset: 8, span: 32 }}>
										<Tooltip placement='top' title={isSuspended ? t<string>('userSuspended') : ''}>
											<Button
												type='primary'
												htmlType='submit'
												disabled={sellerFormSubmitDisabled || isSuspended}
												loading={dataLoading}
											>
												{t<string>('update')}
											</Button>
										</Tooltip>
									</Form.Item>
								</div>
							</div>
						</Form>
					</Card>
				</div>
			</div>
		</section>
	);
};

export default MyOffers;
