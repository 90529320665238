import { Dropdown, MenuProps, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import './AdminUsers.scss';
import Header from '../../components/Header/Header';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { DownOutlined } from '@ant-design/icons';
import { getAdminUsersColumn } from '../../helpers/AdminUsersColumn';
import { AdminUser, AdminUserFilters } from '../../models/AdminUsers';
import { UserStatusModal } from '../../components/UserStatusModal/UserStatusModal';
import { UserState } from '../../models/UserState';
import { useMainContext } from '../../store/MainContext';

const AdminUsers = () => {
	const { t } = useTranslation();
	const [adminLoading, setadminLoading] = useState(false);
	const [adminUsers, setadminUsers] = useState([]);
	const [sortBy, setSortBy] = useState<string>('name-asc');
	const [pagesize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [totalElements, setTotalElements] = useState(0);
	const [user, setUser] = useState<AdminUser>();
	const [showModal, setShowModal] = useState(false);
	const [showData, setShowData] = useState(false);
	const token = getUserInfo()?.token;
	const { mainState, setMainState } = useMainContext();
	useEffect(() => {
		setadminLoading(true);
		axios
			.get(`${API_ENDPOINTS.getAdminUsers}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				const sortedData = res.data.sort((a: any, b: any) => {
					console.log(sortBy);
					switch (sortBy) {
						case AdminUserFilters.nameAsc:
							return a.firstname.localeCompare(b.firstname);
						case AdminUserFilters.nameDesc:
							return b.firstname.localeCompare(a.firstname);
						case AdminUserFilters.countryAsc:
							return a.countryCode.localeCompare(b.countryCode);
						case AdminUserFilters.emailAddressAsc:
							return a.email.localeCompare(b.email);
						case AdminUserFilters.emailAddressDesc:
							return b.email.localeCompare(a.email);
						case AdminUserFilters.phoneNumberAsc:
							return a.phone.localeCompare(b.phone);
						case AdminUserFilters.phoneNumberDesc:
							return b.phone.localeCompare(a.phone);
						case AdminUserFilters.roleAsc:
							return a.role.localeCompare(b.role);
						case AdminUserFilters.roleDesc:
							return b.role.localeCompare(a.role);
						case AdminUserFilters.canHandleAppealAsc:
							return a.canHandleAppeals - b.canHandleAppeals;
						case AdminUserFilters.canHandleAppealAsc:
							return b.canHandleAppeals - a.canHandleAppeals;
						case AdminUserFilters.userStatusDesc:
							return a.userStatus.localeCompare(b.userStatus);
						case AdminUserFilters.userStatusAsc:
							return b.userStatus.localeCompare(a.userStatus);
						default:
							return 0;
					}
				});
				setadminUsers(sortedData);
				setTotalElements(res.data.length);
			})
			.catch((err) => console.error(err))
			.finally(() => setadminLoading(false));
	}, [sortBy, showData]);

	const changeOageSize = (pageSize: number) => {
		setPage(0);
		setPageSize(pageSize);
	};

	const pageSizeItems: MenuProps['items'] = [
		{ label: '10', key: '1', onClick: () => changeOageSize(10) },
		{ label: '20', key: '2', onClick: () => changeOageSize(20) },
		{ label: '50', key: '3', onClick: () => changeOageSize(50) },
		{ label: '100', key: '4', onClick: () => changeOageSize(100) },
	];

	const handleActiveState = () => {
		let state: UserState = user?.userStatus.startsWith('ACTIVE') ? 'DEACTIVATE' : 'ACTIVATE';
		let userId = `${user?.id ?? ''}`;
		axios(`${API_ENDPOINTS.performAdminUserAction.replace('%userId%', userId).replace('%action%', state)}`, {
			method: 'POST',
			headers: { Authorization: `Bearer ${token}` },
		})
			.then((_) => setShowData(!showData))
			.catch((err) => console.error(err));
	};
	return (
		<div className='countries-and-paym-container'>
			<Header title={t<string>('admin')} description={t<string>('adminDetailsforAddEditAdmin')} />
			<div className='max-w-[1440px] mx-auto xss:px-[10px] md:px-[75px] pt-[30px] pb-20 bg-white'>
				<div className='flex items-center my-2'>
					<span className='text-[#000D1D99] text-sm '>{t<string>('show')}</span>
					<Dropdown className='mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
						<span className='bg-[#F5F5F5] h-10 w-24 flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer'>
							{pagesize}
							<DownOutlined className='w-2 ' style={{ fontSize: 8 }} />
						</span>
					</Dropdown>
					<span className='text-[#000D1D99] text-sm '>{t<string>('entries')}</span>
				</div>
				<Table
					columns={getAdminUsersColumn(t, sortBy, setSortBy, setUser, setShowModal, mainState.role)}
					dataSource={adminUsers}
					pagination={{ pageSize: pagesize, total: totalElements, onChange: (pageIndex) => setPage(pageIndex - 1) }}
					loading={adminLoading}
					tableLayout='auto'
				/>
			</div>
			{showModal && (
				<UserStatusModal
					showModal={showModal}
					setShowModal={setShowModal}
					handleUserState={handleActiveState}
					state={user?.userStatus.startsWith('ACTIVE') ? 'DEACTIVATE' : 'ACTIVATE'}
					user={''}
				/>
			)}
		</div>
	);
};

export default AdminUsers;
