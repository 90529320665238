import './Header.scss';
import { useTranslation } from 'react-i18next';

interface SubHeaderProps {
	title: string;
	description: string;
	subDescription?: string;
}

const Header = ({ title, description, subDescription }: SubHeaderProps) => {
	const { t } = useTranslation();

	return (
		<div className='header py-10'>
			<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center'>{title}</h1>
			<p className='text-white text-sm text-center'>{description}</p>
			<p className='text-white text-sm text-center'>{subDescription}</p>
		</div>
	);
};

export default Header;
