import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';

import { Client } from '../models/Clients';

import { PauseCircleOutlined, StopOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import './merchantsTableColumns.scss';
import { getInitials } from './getStringInitials';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { handleSortClick } from './sorterColumn';

export const getClientsColumns = (
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
	setShowSuspended: (value: boolean) => void,
	setShowBlock: (value: boolean) => void,
	setClient: (client: Client) => void,
) => {
	const columns: ColumnsType<Client> = [
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('clientProfile')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'fullName-asc'))}
							style={{ color: sortBy === 'fullName-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'fullName-desc'))}
							style={{ color: sortBy === 'fullName-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),

			dataIndex: 'fullName',
			ellipsis: true,
			responsive: ['lg'],
			render: (value, record) => {
				const blocked = record.status === 'BLOCKED';
				const suspended = record.status === 'SUSPENDED';
				const blockedText = blocked ? t('unblock') : t('block');
				const suspendedText = suspended ? t('unsuspend') : t('suspend');

				return (
					<div className='flex items-baseline'>
						<div className='m-2'>
							<Tooltip title={suspendedText}>
								<PauseCircleOutlined
									style={{ color: suspended ? '#f00' : '#000' }}
									className='px-1'
									onClick={() => {
										setShowSuspended(true);
										setClient(record);
									}}
								/>
							</Tooltip>
							<Tooltip title={blockedText}>
								<StopOutlined
									style={{ color: blocked ? '#f00' : '#000' }}
									className='px-1'
									onClick={() => {
										setShowBlock(true);
										setClient(record);
									}}
								/>
							</Tooltip>
						</div>
						<div
							style={{
								borderStyle: 'solid',
								borderWidth: '1px',
								borderColor: '#000',
							}}
							className={'h-[30px] w-[20px] mx-2 !rounded-full flex items-center justify-center p-4 '}
						>
							{getInitials(value)}
						</div>
						<div>
							<Link
								to={`/client/?id=${record.clientId}`}
								className='text-xs flex items-center m-0 text-[#0ea5e9] underline'
							>
								<p className='text-[#0ea5e9]  text-sm font-medium mb-1'>{value}</p>
							</Link>
							<p className='text-xs text-[#01081E99] m-0 flex items-center mb-1'>
								{record.ordersCompleted} {t<string>('orders')}
								<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' />
								{(
									(Number(record.ordersCompleted) /
										(record.totalOrders === null || record.totalOrders === 0 ? 1 : Number(record.totalOrders))) *
									100
								).toFixed()}
								% {t<string>('completionRate')}
							</p>
						</div>
					</div>
				);
			},
		},
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('email')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'email-asc'))}
							style={{ color: sortBy === 'email-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'email-desc'))}
							style={{ color: sortBy === 'email-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'emailAddress',
			key: 'emailAddress',
			responsive: ['lg'],
			render: (value) => {
				return <p className='text-[14px] text-[#999999]'>{value}</p>;
			},
		},
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('phone')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'phone-asc'))}
							style={{ color: sortBy === 'phone-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'phone-desc'))}
							style={{ color: sortBy === 'phone-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'phoneNumber',
			responsive: ['lg'],
			key: 'phoneNumber',
			render: (value) => {
				return <p className='text-[19px] font-medium text-lightBlue flex items-center'>{value}</p>;
			},
		},
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('countryCurrency')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'countryCode-asc'))}
							style={{ color: sortBy === 'countryCode-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'countryCode-desc'))}
							style={{ color: sortBy === 'countryCode-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'country',
			key: 'country',
			responsive: ['lg'],
			render: (_: string, row: Client) => (
				<p className='text-[19px] font-medium text-lightBlue flex items-center'>
					{row.country.code}/{row.currency}
				</p>
			),
			ellipsis: true,
		},
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('creationDate')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'createdAt-asc'))}
							style={{ color: sortBy === 'createdAt-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'createdAt-desc'))}
							style={{ color: sortBy === 'createdAt-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'createdAt',
			key: 'createdAt',
			responsive: ['lg'],
			render: (value) => {
				return <p className='text-[14px] text-[#999999]'>{value}</p>;
			},
		},
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('status')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'status-asc'))}
							style={{ color: sortBy === 'status-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'status-desc'))}
							style={{ color: sortBy === 'status-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'status',
			key: 'status',
			responsive: ['lg'],
			render: (value) => {
				return (
					<p
						style={{ color: value === 'SUSPENDED' || value === 'BLOCKED' ? 'red' : 'green' }}
						className='text-[14px] text-[#999999]'
					>
						{value}
					</p>
				);
			},
		},
		{
			dataIndex: 'record',
			key: 'record',
			className: 'small-screen-row',
			render: (value, record) => {
				return (
					<div className='text-xs text-[#01081E99] flex flex-row xs:flex-col mb-1 w-100 justify-between items-center '>
						<div className='w-full'>
							<div className='flex flex-row py-2 justify-between'>
								<div> {t<string>('client')}:</div>
								<div className='text-lightBlue text-[15px] font-medium flex items-center'>
									<div
										style={{
											borderStyle: 'solid',
											borderWidth: '1px',
											borderColor: '#949090',
										}}
										className={'h-[30px] w-[20px] mx-2 !rounded-full flex items-center justify-center p-4 '}
									>
										{getInitials(record.fullName)}
									</div>
									<Link
										to={`/client/?id=${record.clientId}`}
										className='text-xs text-[#01081E99] flex items-center mb-1 text-[#0ea5e9] underline'
									>
										<p className='text-lightBlue text-sm font-medium mb-1'>{record.fullName}</p>
									</Link>
								</div>
							</div>

							{/* TODO: change the curency if needed to local currency */}
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('actions')}:</div>
								<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>
									<PauseCircleOutlined
										className='px-1'
										onClick={() => {
											setShowSuspended(true);
											setClient(record);
										}}
									/>
									<StopOutlined
										className='px-1'
										onClick={() => {
											setShowBlock(true);
											setClient(record);
										}}
									/>
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('email')}:</div>
								<div className='text-[15px] font-medium text-lightBlue flex items-center'>{record.emailAddress}</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('phone')}:</div>
								<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>
									{record.phoneNumber}
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('status')}:</div>
								<div
									className='flex items-center  flex-wrap rounded-sm text-[14px] font-medium text-[#000] px-2 '
									style={{ color: value === 'SUSPENDED' || value === 'BLOCKED' ? 'red' : 'green' }}
								>
									{record.status}
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('countryCurrency')}:</div>
								<div className='flex flex-row items-center text-lightBlue text-[15px] font-medium'>
									{record.country.code}/{record.currency}
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('Date&Time')}:</div>
								<div className='text-xs text-[15px] font-medium text-lightBlue  flex items-center '>
									{record.createdAt}
								</div>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return columns;
};
