import { Order, OrderType } from '../models/Orders';

export const ordersMock: Order[] = [
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
	{
		orderNumber: 324223553645,
		clientFullName: 'Ahsan Almani',
		merchantFullName: 'Ismoil Atajanov',
		type: OrderType.buy,
		amount: '124',
		price: '2.26346',
		status: 'Active',
		createdAt: '07/05/2023',
		quantity: '1200',
		note: 'hhhhhh',
		clientId: 23,
		merchantId: 566,
	},
];
