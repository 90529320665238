import React from 'react';

const SortArrow = ({ active, position }: { active: boolean; position: string }) => {
	return (
		<div
			className={`h-2 w-2 ${position === 'up' ? 'rotate-180' : ''}`}
			style={{
				transform: position === 'up' ? 'rotate(180deg)' : 'none',
				fill: active ? '#000000' : '#bbb',
				display: 'flex',
				justifyContent: 'space-around',
				margin: '2px 0',
			}}
		>
			<svg
				version='1.1'
				id='Layer_1'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
				viewBox='0 0 386.257 386.257'
				xmlSpace='preserve'
			>
				<polygon points='0,96.879 193.129,289.379 386.257,96.879 ' />
			</svg>
		</div>
	);
};

export default SortArrow;
