import { Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './UserStatusModal.scss';
import { UserState } from '../../models/UserState';
import { getState } from '../../helpers/stateConfirmationTranslation';

export function UserStatusModal({
	showModal,
	setShowModal,
	handleUserState,
	state,
	user,
}: {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	handleUserState: () => void;
	state: UserState;
	user: string;
}) {
	const { t } = useTranslation();
	return (
		<Modal
			title={
				<div className='flex justify-center'>
					<p className='m-0 px-2 text-[20px] text-[grey] font-[500]  text-center'>{t<string>('confirm')}</p>
				</div>
			}
			open={showModal}
			onCancel={() => setShowModal(false)}
			footer={null}
		>
			<div className='container'>
				{getState(t, state)} {user} ?
			</div>
			<div className='flex justify-end'>
				<button
					className='bg-[#e5f1ff] border-none py-2 px-4 m-2 text-[grey] rounded cursor-pointer'
					onClick={() => {
						setShowModal(false);
					}}
				>
					{t<string>('no')}
				</button>
				<button
					className='bg-[#0077ff] border-none py-2 px-4 m-2 text-[white] rounded cursor-pointer'
					onClick={() => {
						handleUserState();
						setShowModal(false);
					}}
				>
					{t<string>('yes')}
				</button>
			</div>
		</Modal>
	);
}
