import { ClientFilters } from '../models/Clients';

export const handleCreatedAtSortClick = (sortBy: string) => {
	var sortValue = '';
	if (sortBy !== ClientFilters.createdAtDesc && sortBy !== ClientFilters.createdAtAsc) {
		sortValue = ClientFilters.createdAtDesc;
	} else if (sortBy === ClientFilters.createdAtDesc) {
		sortValue = ClientFilters.createdAtAsc;
	} else {
		sortValue = '';
	}
	return sortValue;
};
export const handleFullNameSortClick = (sortBy: string) => {
	var sortValue = '';
	if (sortBy !== ClientFilters.fullNameDesc && sortBy !== ClientFilters.fullNameAsc) {
		sortValue = ClientFilters.fullNameDesc;
	} else if (sortBy === ClientFilters.fullNameDesc) {
		sortValue = ClientFilters.fullNameAsc;
	} else {
		sortValue = '';
	}
	return sortValue;
};
export const handleCountrySortClick = (sortBy: string) => {
	var sortValue = '';
	if (sortBy !== ClientFilters.countryCodeDesc && sortBy !== ClientFilters.countryCodeAsc) {
		sortValue = ClientFilters.countryCodeDesc;
	} else if (sortBy === ClientFilters.countryCodeDesc) {
		sortValue = ClientFilters.countryCodeAsc;
	} else {
		sortValue = '';
	}
	return sortValue;
};
export const handlePhoneNumberSortClick = (sortBy: string) => {
	var sortValue = '';
	if (sortBy !== ClientFilters.phoneNumberDesc && sortBy !== ClientFilters.phoneNumberAsc) {
		sortValue = ClientFilters.phoneNumberDesc;
	} else if (sortBy === ClientFilters.phoneNumberDesc) {
		sortValue = ClientFilters.phoneNumberAsc;
	} else {
		sortValue = '';
	}
	return sortValue;
};

export const handleEmailAddressSortClick = (sortBy: string) => {
	var sortValue = '';
	if (sortBy !== ClientFilters.emailAddressDesc && sortBy !== ClientFilters.emailAddressAsc) {
		sortValue = ClientFilters.emailAddressDesc;
	} else if (sortBy === ClientFilters.emailAddressDesc) {
		sortValue = ClientFilters.emailAddressAsc;
	} else {
		sortValue = '';
	}
	return sortValue;
};

export const handleStatusSortClick = (sortBy: string) => {
	let sortValue = '';
	if (sortBy !== ClientFilters.statusDesc && sortBy !== ClientFilters.statusAsc) {
		sortValue = ClientFilters.statusDesc;
	} else if (sortBy === ClientFilters.statusDesc) {
		sortValue = ClientFilters.statusAsc;
	}
	return sortValue;
};
