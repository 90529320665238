import { Input, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Country.scss';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getUserInfo } from '../../helpers/localStorageHandler';

const numberFormatter = (num: number) => {
	return `${num > 9 ? '' : '0'}${num}`;
};
const OTP_LENGTH = 6;
export function OTPModal({
	showOTPModal,
	setOTPModal,
	handelAccept,
	countryCode,
	accountGroup,
}: {
	showOTPModal: boolean;
	setOTPModal: (value: boolean) => void;
	handelAccept: () => void;
	countryCode: string;
	accountGroup: string;
}) {
	const { t } = useTranslation();
	const [minutes, setMinutes] = useState('0');
	const [seconds, setSeconds] = useState('0');
	const [expireTime, setExpireTime] = useState(0);
	const [currentCountdown, setCurrentCountdown] = useState(0);
	const [otp, setOtp] = useState(['', '', '', '', '', '']);

	const token = getUserInfo()?.token;
	let intervalId: any;
	const handleOtpChange = (index: number, value: string) => {
		const newOtp = [...otp];
		newOtp[index] = value;
		// Move to the next input field if a digit is entered
		if (value !== '' && index < 6) {
			const nextInput = document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}
		if (value == '') {
			const nextInput = document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}

		setOtp(newOtp);
	};

	const sendOtp = () => {
		if (countryCode) {
			axios(`${API_ENDPOINTS.getOtp.replace('%countryCode%', countryCode)}`, {
				method: 'POST',
				headers: { Authorization: `Bearer ${token}` },
			}).then((res) => {
				setExpireTime(res.data.remainingExpiryTime);
				setCurrentCountdown(res.data.remainingExpiryTime);
			});
		} else {
			axios(API_ENDPOINTS.getAccountGroupOTP, {
				data: { group: accountGroup },
				method: 'POST',
				headers: { Authorization: `Bearer ${token}` },
			}).then((res) => {
				setExpireTime(res.data.remainingExpiryTime);
				setCurrentCountdown(res.data.remainingExpiryTime);
			});
		}
	};
	useEffect(() => {
		setOtp(['', '', '', '', '', '']);
		if (showOTPModal) {
			sendOtp();
		}
	}, [showOTPModal]);

	useEffect(() => {
		clearInterval(intervalId);
		intervalId = setInterval(() => {
			setCurrentCountdown((prevCountdown) => prevCountdown - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [expireTime]);

	useEffect(() => {
		let Minutes = Math.floor((currentCountdown % 3600) / 60);
		let Seconds = currentCountdown % 60;
		if (Minutes <= 0 && Seconds <= 0) {
			setOTPModal(false);
			clearInterval(intervalId);
			return;
		}
		setMinutes(numberFormatter(Math.floor((currentCountdown % 3600) / 60)));
		setSeconds(numberFormatter(currentCountdown % 60));
	}, [currentCountdown]);

	const handleSubmit = () => {
		const otpNumber = otp.join('');
		if (countryCode) {
			axios(`${API_ENDPOINTS.validateOtp.replace('%countryCode%', countryCode).replace('%otpNumber%', otpNumber)}`, {
				method: 'POST',
				headers: { Authorization: `Bearer ${token}` },
			}).then((res) => {
				if (res.data.validity) {
					handelAccept();
					setOTPModal(false);
				} else {
					notification['error']({ message: '', description: t<string>('InvalidOTP') });
				}
			});
		} else {
			axios(`${API_ENDPOINTS.validateAccountGroupOtp.replace('%otpNumber%', otpNumber)}`, {
				data: { group: accountGroup },
				method: 'POST',
				headers: { Authorization: `Bearer ${token}` },
			}).then((res) => {
				if (res.data.validity) {
					handelAccept();
					setOTPModal(false);
				} else {
					notification['error']({ message: '', description: t<string>('InvalidOTP') });
				}
			});
		}
	};

	return (
		<Modal onCancel={() => setOTPModal(false)} open={showOTPModal} footer={null} maskClosable={false}>
			<div className='flex justify-center flex-col items-center w-full'>
				<p className='text-[16px] font-bold'>{t<string>('enterOtp')}</p>
				<div className='timer-container mb-4'>
					{t<string>('expireIn')}:<span>{minutes}</span> : <span>{seconds}</span>
				</div>
				<div>
					{otp.map((digit, index) => (
						<Input
							key={index}
							id={`otp-input-${index}`}
							value={digit}
							onChange={(e) => handleOtpChange(index, e.target.value)}
							maxLength={1}
							style={{ width: '2rem', marginRight: '1rem', textAlign: 'center' }}
							onPaste={(e) => {
								const copiedValue = e.clipboardData.getData('text/plain').split('');
								const otpValue =
									copiedValue.length > OTP_LENGTH
										? copiedValue.slice(0, OTP_LENGTH)
										: [...copiedValue, ...Array(OTP_LENGTH - copiedValue.length).fill('')];
								setOtp(otpValue);
							}}
						/>
					))}
				</div>
				<div>
					<p>
						{t<string>('didNotreceiveOtp')}
						<span className='text-[#0184ff] underline cursor-pointer' onClick={sendOtp}>
							{t<string>('resend')}
						</span>
					</p>
				</div>
				<div className='flex justify-end m-4 w-[50%]'>
					<button
						className='p-[10px] border-none rounded border-[1px] w-[120px] mr-[10px] bg-[#0192ff] text-white'
						style={{ cursor: 'pointer', margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
						onClick={handleSubmit}
					>
						{t<string>('submit')}
					</button>
					<button
						className='p-[10px] border-solid rounded border-[1px] w-[120px] bg-transparent mr-[10px]'
						style={{ cursor: 'pointer', margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
						onClick={() => {
							setOTPModal(false);
						}}
					>
						{t<string>('cancel')}
					</button>
				</div>
			</div>
		</Modal>
	);
}
