import { Merchant } from '../models/Merchants';
import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { getInitials } from './getStringInitials';
import {
	DislikeFilled,
	LikeFilled,
	CaretDownOutlined,
	CaretUpOutlined,
	PauseCircleOutlined,
	StopOutlined,
	EditFilled,
} from '@ant-design/icons';
import './merchantsTableColumns.scss';
import { Button, Tooltip } from 'antd';
import { getUserLanguageInCookie } from './cookiesHandler';

import { Link } from 'react-router-dom';
import { handleSortClick } from './sorterColumn';

export const getMerchantsColumns = (
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
	setShowSuspended: (value: boolean) => void,
	setShowBlock: (value: boolean) => void,
	setMerchant: (merchant: Merchant) => void,
) => {
	const columns: ColumnsType<Merchant> = [
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('merchantProfile')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'fullName-asc'))}
							style={{ color: sortBy === 'fullName-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'fullName-desc'))}
							style={{ color: sortBy === 'fullName-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'fullName',
			ellipsis: true,
			key: 'fullName',
			responsive: ['lg'],
			render: (value, record) => {
				const blocked = record.status === 'BLOCKED';
				const suspended = record.status === 'SUSPENDED';
				const blockedText = blocked ? t('unblock') : t('block');
				const suspendedText = suspended ? t('unsuspend') : t('suspend');

				return (
					<div className='flex py-2'>
						<div className='m-2'>
							<Tooltip title={t<string>('myOffers')}>
								<Link to={`/myOffers/?merchantId=${record.merchantId}&status=${record.status}`}>
									<EditFilled className='cursor-pointer text-black font-bold' />
								</Link>
							</Tooltip>
							<Tooltip title={suspendedText}>
								<PauseCircleOutlined
									style={{ color: suspended ? '#f00' : '#000' }}
									className='px-1'
									onClick={() => {
										setShowSuspended(true);
										setMerchant(record);
									}}
								/>
							</Tooltip>
							<Tooltip title={blockedText}>
								<StopOutlined
									style={{ color: blocked ? '#f00' : '#000' }}
									className='px-1'
									onClick={() => {
										setShowBlock(true);
										setMerchant(record);
									}}
								/>
							</Tooltip>
						</div>
						<div
							style={{
								borderStyle: 'solid',
								borderWidth: '1px',
								borderColor: '#000',
							}}
							className={`h-[30px] w-[30px] !rounded-full flex items-center justify-center p-4  ${
								getUserLanguageInCookie() === 'ar' ? 'ml-2' : 'mr-2'
							}`}
						>
							{getInitials(value)}
						</div>
						<div>
							<Link
								to={`/merchant/?id=${record.merchantId}`}
								className='text-xs text-[#01081E99] flex items-center mb-1 text-[#0ea5e9] underline'
							>
								{value}
							</Link>
							<p className='text-xs text-[#01081E99] m-0 flex items-center'>
								{record.ordersCompleted} {t<string>('orders')}{' '}
								<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' />
								{(
									(Number(record?.ordersCompleted) /
										(record?.totalOrders === null || record?.totalOrders === 0 ? 1 : Number(record?.totalOrders))) *
									100
								).toFixed()}
								% {t<string>('completionRate')}
							</p>
							<div className='flex items-baseline'>
								<Link to={`/review/?merchantId=${record.merchantId}`} className='text-xs'>
									{record.totalReviews} {t<string>('reviews')}
								</Link>
								<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' />
								<p
									className={`text-xs flex mt-1 items-center ${
										record.rating < 80 ? 'text-[#F6465D]' : 'text-[#33A867]'
									}`}
								>
									{record.rating < 80 ? (
										<DislikeFilled
											className={`h-[13px] w-[13px]  ${getUserLanguageInCookie() === 'ar' ? 'ml-[6px]' : 'mr-[6px]'}`}
										/>
									) : (
										<LikeFilled
											className={`h-[13px] w-[13px]  ${getUserLanguageInCookie() === 'ar' ? 'ml-[6px]' : 'mr-[6px]'}`}
										/>
									)}
									{record.rating}%
								</p>
							</div>
						</div>
					</div>
				);
			},
		},
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('email')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'email-asc'))}
							style={{ color: sortBy === 'email-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'email-desc'))}
							style={{ color: sortBy === 'email-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'emailAddress',
			key: 'emailAddress',
			render: (value) => {
				return <p className='text-[14px] text-[#999999]'>{value}</p>;
			},
		},
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('phone')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'phone-asc'))}
							style={{ color: sortBy === 'phone-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'phone-desc'))}
							style={{ color: sortBy === 'phone-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			responsive: ['lg'],
			render: (value) => {
				return <p className='text-[19px] font-medium text-lightBlue flex items-center'>{value}</p>;
			},
		},
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('countryCurrency')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'countryCode-asc'))}
							style={{ color: sortBy === 'countryCode-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'countryCode-desc'))}
							style={{ color: sortBy === 'countryCode-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'country',
			key: 'country',
			responsive: ['lg'],
			render: (_: string, row: Merchant) => (
				<p className='text-[19px] font-medium text-lightBlue flex items-center'>
					{row.country.code}/{row.currency}
				</p>
			),
			ellipsis: true,
		},
		{
			title: <div className='py-2 text-[#000D1D99] text-sm mx-[10px]'>{t<string>('type')}</div>,
			responsive: ['lg'],
			render: (_: string, row: Merchant) => (
				<div className='flex items-center'>
					{row.activeBuyer ? (
						<Button className={` mr-[5px] ${'bg-[#00AFFF20] text-[#00AFFF] font-bold border-none'}`}>
							{t<string>('buyer')}
						</Button>
					) : (
						<></>
					)}
					{row.activeSeller ? (
						<Button className={` ${'bg-[#00AFFF20] text-[#00AFFF] font-bold border-none'}`}>
							{t<string>('seller')}
						</Button>
					) : (
						<></>
					)}
				</div>
			),
			align: 'center' as any,
		},
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('creationDate')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'registerationDate-asc'))}
							style={{ color: sortBy === 'registerationDate-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'registerationDate-desc'))}
							style={{ color: sortBy === 'registerationDate-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (value) => {
				return <p className='text-[14px] text-[#999999]'>{value}</p>;
			},
		},
		{
			title: (
				<div className='py-2 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('status')}
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'status-asc'))}
							style={{ color: sortBy === 'status-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'status-desc'))}
							style={{ color: sortBy === 'status-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'status',
			key: 'status',
			render: (value) => {
				return (
					<p
						style={{ color: value === 'SUSPENDED' || value === 'BLOCKED' ? 'red' : 'green' }}
						className='text-[14px] text-[#999999]'
					>
						{value}
					</p>
				);
			},
		},
		{
			dataIndex: 'record',
			key: 'record',
			className: 'small-screen-row',
			render: (value, record) => {
				return (
					<div className='text-xs text-[#01081E99] flex flex-row xs:flex-col mb-1 w-100 justify-between items-center '>
						<div className='w-full'>
							<div className='flex flex-row py-2 justify-between'>
								<div> {t<string>('merchant')}:</div>
								<div>
									<div className='flex'>
										<div
											style={{
												borderStyle: 'solid',
												borderWidth: '1px',
												borderColor: '#949090',
											}}
											className={`h-[30px] w-[30px] !rounded-full flex items-center justify-center p-4  ${
												getUserLanguageInCookie() === 'ar' ? 'ml-2' : 'mr-2'
											}`}
										>
											{getInitials(record.fullName)}
										</div>
										<Link
											to={`/merchant/?id=${record.merchantId}`}
											className='text-xs text-[#01081E99] flex items-center mb-1 text-[#0ea5e9] underline'
										>
											{record.fullName}
										</Link>
									</div>
									<div className={`mt-[-8px] ${getUserLanguageInCookie() === 'ar' ? 'mr-8' : 'ml-8'}`}>
										<p className='text-xs text-[#01081E99] m-0 flex items-center'>
											{record.ordersCompleted} {t<string>('orders')}{' '}
											<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' />
											{(
												(Number(record?.ordersCompleted) /
													(record?.totalOrders === null || record?.totalOrders === 0
														? 1
														: Number(record?.totalOrders))) *
												100
											).toFixed()}
											% {t<string>('completionRate')}
										</p>

										<p
											className={`text-xs flex m-0 mt-1 items-center ${
												record.rating < 80 ? 'text-[#F6465D]' : 'text-[#33A867]'
											}`}
										>
											{record.rating < 80 ? (
												<DislikeFilled
													className={`h-[13px] w-[13px]  ${
														getUserLanguageInCookie() === 'ar' ? 'ml-[6px]' : 'mr-[6px]'
													}`}
												/>
											) : (
												<LikeFilled
													className={`h-[13px] w-[13px]  ${
														getUserLanguageInCookie() === 'ar' ? 'ml-[6px]' : 'mr-[6px]'
													}`}
												/>
											)}
											{record.rating}%
											<Link to={`/review/?merchantId=${record.merchantId}`}>
												<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' /> {record.totalReviews}{' '}
												{t<string>('reviews')}
											</Link>
										</p>
									</div>
								</div>
							</div>

							{/* TODO: change the curency if needed to local currency */}
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('actions')}:</div>
								<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center justify-center'>
									<Tooltip title={t<string>('myOffers')}>
										<Link to={`/myOffers/?merchantId=${record.merchantId}&status=${record.status}`} className='mx-2'>
											<EditFilled className='cursor-pointer text-black font-bold' />
										</Link>
									</Tooltip>
									<PauseCircleOutlined
										className='px-1'
										onClick={() => {
											setShowSuspended(true);
											setMerchant(record);
										}}
									/>
									<StopOutlined
										className='px-1'
										onClick={() => {
											setShowBlock(true);
											setMerchant(record);
										}}
									/>
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('email')}:</div>
								<div className='text-[15px] font-medium text-lightBlue flex items-center'>{record.emailAddress}</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('phone')}:</div>
								<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>
									{record.phoneNumber}
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('status')}:</div>
								<div
									className='flex items-center  flex-wrap rounded-sm text-[14px] font-medium text-[#000] px-2 '
									style={{ color: value === 'SUSPENDED' || value === 'BLOCKED' ? 'red' : 'green' }}
								>
									{record.status}
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('countryCurrency')}:</div>
								<div className='flex flex-row items-center text-[15px] font-medium text-lightBlue'>
									{record.country.code}/{record.currency}
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('type')}:</div>
								<div className='flex items-center'>
									{record.activeBuyer ? (
										<Button
											className={` mr-[5px] ${'bg-[#00AFFF20] text-[#00AFFF] font-bold border-none text-[13px]'}`}
										>
											{t<string>('buyer')}
										</Button>
									) : (
										<></>
									)}
									{record.activeSeller ? (
										<Button className={` ${'bg-[#00AFFF20] text-[#00AFFF] font-bold border-none text-[13px]'}`}>
											{t<string>('seller')}
										</Button>
									) : (
										<></>
									)}
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('Date&Time')}:</div>
								<div className='flex items-center text-[15px] font-medium text-lightBlue'>{record.createdAt}</div>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return columns;
};
