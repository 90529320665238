import { Country } from './Country';

export enum ClientFilters {
	fullNameAsc = 'fullName-asc',
	fullNameDesc = 'fullName-desc',
	emailAddressAsc = 'email-asc',
	emailAddressDesc = 'email-desc',
	phoneNumberAsc = 'phone-asc',
	phoneNumberDesc = 'phone-desc',
	createdAtAsc = 'createdAt-asc',
	createdAtDesc = 'createdAt-desc',
	countryCodeAsc = 'countryCode-asc',
	countryCodeDesc = 'countryCode-desc',
	statusAsc = 'status-asc',
	statusDesc = 'status-desc',
}
export type Account = {
	accountGroup: string;
	balance: number;
	buyMax: number;
	buyMin: number;
	number: string;
	onHold: number;
	sellMax: number;
	sellMin: number;
	tradingPlatform: string;
};
export type Client = {
	clientId: number;
	fullName: string;
	rating: number;
	totalReviews: number;
	ordersCompleted: number;
	rate: number;
	available: number;
	onHold: number;
	min: number;
	max: number;
	createdAt?: string;
	country: Country;
	currency: string;
	emailAddress?: string;
	phoneNumber?: string;
	activeBuyer?: boolean;
	activeSeller?: boolean;
	status: string;
	totalOrders: number;
	exchangeBaseCurrency: string;
	accounts: Account[];
};
