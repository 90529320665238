export enum CountryAndPaymentMethodFilters {
	nameDesc = 'name-desc',
	nameAsc = 'name-asc',
	isPublicAsc = 'is-public-asc',
	isPublicDesc = 'is-public-desc',
	countryCodeDesc = 'country-code-desc',
	countryCodeAsc = 'country-code-asc',
	currencyCodeDesc = 'currency-code-desc',
	currencyCodeAsc = 'currency-code-asc',
	maxProfitDesc = 'max-profit-desc',
	maxProfitAcs = 'max-profit-asc',
	paymentMethodDesc = 'payment-method-desc',
	paymentMethodAsc = 'payment-method-asc',
}

export type CountryAndPaymentMethod = {
	countryName: string;
	countryCode: string;
	currencyCode: string;
	maxProfitRatio: number;
	paymentMethods: any[];
	isPublic: boolean;
};
