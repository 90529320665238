import { Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Orders from '../pages/Orders/Orders';
import Merchants from '../pages/Merchants/Merchants';
import SingleMerchant from '../pages/SingleMerchant/SingleMerchant';
import OrderLifeCycle from '../pages/OrderLifeCycle/OrderLifeCycle';
import CountriesAndPaymentMethods from '../pages/CountriesAndPaymentMethods/CountriesAndPaymentMethods';
import Clients from '../pages/Clients/Clients';
import ChangePassword from '../pages/ChangePassw0rd/ChangePassword';
import Notifications from '../pages/Notifications/Notifications';
import AppealTasks from '../pages/TaskAppeals/AppealTasks';
import MerchantReview from '../pages/Reviews/Review';
import MyOffers from '../pages/MyOffers/MyOffers';
import SingleClient from '../pages/SingleClient/SingleClient';

const AdminRoute = (
	<>
		<Route path='/' element={<Navigate to='/home' />} />
		<Route path='/orders' element={<Orders />} />
		<Route path='/order-life-cycle' element={<OrderLifeCycle />} />
		<Route path='/merchants' element={<Merchants />} />
		<Route path='/merchant' element={<SingleMerchant />} />
		<Route path='/home' element={<Home />} />
		<Route path='/clients' element={<Clients />} />
		<Route path='/client' element={<SingleClient />} />
		<Route path='/notifications' element={<Notifications />} />
		<Route path='/countries-and-payment-methods' element={<CountriesAndPaymentMethods />} />
		<Route path='/update-password' element={<ChangePassword />} />
		<Route path='/appeal-task' element={<AppealTasks />} />
		<Route path='/review' element={<MerchantReview />} />
		<Route path='/myOffers' element={<MyOffers />} />
	</>
);

export default AdminRoute;
