import { Country } from './Country';
import { PaymentMethod } from './PaymentMethod';

export enum OfferType {
	sell = 'sell',
	buy = 'buy',
}

export enum MerchantFilters {
	merchantIdAsc = 'merchantId',
	merchantIdDesc = 'merchantId-asc',
	fullNameAsc = 'fullName-asc',
	emailAddressAsc = 'email-asc',
	phoneNumberAsc = 'phone-asc',
	ratingAsc = 'rating-asc',
	currencyAsc = 'currency-asc',
	ordersCompletedAsc = 'ordersCompleted-asc',
	regionAsc = 'region-asc',
	totalReviewsAsc = 'totalReviews-asc',
	createdAtAsc = 'registerationDate-asc',
	fullNameDesc = 'fullName-desc',
	emailAddressDesc = 'email-desc',
	phoneNumberDesc = 'phone-desc',
	ratingDesc = 'rating-desc',
	currencyDesc = 'currency-desc',
	ordersCompletedDesc = 'ordersCompleted-desc',
	regionDesc = 'region-desc',
	totalReviewsDesc = 'totalReviews-desc',
	createdAtDesc = 'registerationDate-desc',
	bestSellers = ' rating-desc',
	newSellers = 'registerationDate-desc',
	maxOrders = 'completedOrders-desc',
	minOrders = 'completedOrders-asc',
	priceDesc = 'price-desc',
	priceAsc = 'price-asc',
	availableDesc = 'available-desc',
	availableAsc = 'available-asc',
	countryAsc = 'countryCode-asc',
	countryDesc = 'countryCode-desc',
	statusAsc = 'status-asc',
	statusDesc = 'status-desc',
}

export type Merchant = {
	merchantId: number;
	fullName: string;
	rating: number;
	totalReviews: number;
	ordersCompleted: number;
	rate: number;
	available: number;
	onHold: number;
	min: number;
	max: number;
	createdAt?: string;
	country: Country;
	currency: string;
	methods: PaymentMethod[];
	emailAddress?: string;
	phoneNumber?: string;
	activeBuyer?: boolean;
	activeSeller?: boolean;
	status: string;
	totalOrders: number;
};
