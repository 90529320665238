import { useEffect } from 'react';

/**
 * Keeps the Chat component hidden while the component is still mounted.
 *
 * Triggered when component is mounted, and on each update.
 *
 * @returns {void}
 */
export function useHideTidioChat(): void {
	useEffect(() => {
		document.getElementById('tidio-chat-iframe')?.style.setProperty('display', 'none');

		return () => {
			document.getElementById('tidio-chat-iframe')?.style.setProperty('display', 'block');
		};
	});
}
