import { InitialTheme } from '../../models/InitialTheme';

export const initialTheme: InitialTheme = {
	companyName: '',
	companyLogo: '',
	backgroundImage: '',
	colorPrimary: '#0f2147',
	colorPrimaryHover: '#6fa374',
	colorError: '#ff4d4f',
	colorSuccess: '#52c41a',
	colorInfo: '#1677ff',
	colorTextBase: '#000000',
	colorBgLayout: '#f5f5f5',
	colorBgMenu: '#0f2147',
	languageCode: 'en',
};
