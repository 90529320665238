import { Dropdown, MenuProps, Table, Modal, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../../../../assets/api/endpoints';
import './AdminList.scss';
import axios from 'axios';
import { getUserInfo } from '../../../../../helpers/localStorageHandler';
import { getAdminListColumn } from '../../../../../helpers/AdminListColumn';
import { AdminListFilters } from '../../../../../models/AdminList';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
const AdminList = ({
	showClosePopup,
	setShowClosePopup,
	handelAssign,
}: {
	showClosePopup: boolean;
	setShowClosePopup: (value: boolean) => void;
	handelAssign: (userId: number) => void;
}) => {
	const { t } = useTranslation();
	const [adminLoading, setadminLoading] = useState(false);
	const [adminUsers, setadminUsers] = useState([]);
	const [userId, setUserId] = useState<number>(0);
	const [sortBy, setSortBy] = useState<string>('name-asc');
	const [pagesize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [totalElements, setTotalElements] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const token = getUserInfo()?.token;
	useEffect(() => {
		setadminLoading(true);
		axios
			.get(`${API_ENDPOINTS.getAdminList}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				const sortedData = res.data
					.sort((a: any, b: any) => {
						switch (sortBy) {
							case AdminListFilters.nameAsc:
								return a.fullName.localeCompare(b.fullName);
							case AdminListFilters.nameDesc:
								return b.fullName.localeCompare(a.fullName);
							case AdminListFilters.canHandleAppealAsc:
								return a.canHandleAppeals - b.canHandleAppeals;
							case AdminListFilters.canHandleAppealAsc:
								return b.canHandleAppeals - a.canHandleAppeals;
							case AdminListFilters.activeTasksDesc:
								return a.canHandleAppeals - b.canHandleAppeals;
							case AdminListFilters.activeTasksAsc:
								return b.activeTasks - a.activeTasks;
							default:
								return 0;
						}
					})
					.filter((item: any) => item.fullName.toLowerCase().includes(searchTerm.toLowerCase()));
				setadminUsers(sortedData);
				setTotalElements(res.data.length);
			})
			.catch((err) => console.error(err))
			.finally(() => setadminLoading(false));
	}, [sortBy, searchTerm]);

	const changeOageSize = (pageSize: number) => {
		setPage(0);
		setPageSize(pageSize);
	};

	const pageSizeItems: MenuProps['items'] = [
		{ label: '10', key: '1', onClick: () => changeOageSize(10) },
		{ label: '20', key: '2', onClick: () => changeOageSize(20) },
		{ label: '50', key: '3', onClick: () => changeOageSize(50) },
		{ label: '100', key: '4', onClick: () => changeOageSize(100) },
	];

	const assign = () => {
		handelAssign(userId);
	};

	const getRowClassName = (record: {
		id: number;
		fullName: string;
		canHandleAppeals: boolean;
		activeTasks: number;
	}) => {
		return userId === record.id ? 'selected-row' : '';
	};

	return (
		<Modal
			title={<div className='flex justify-center'>{t<string>('admin')}</div>}
			open={showClosePopup}
			onCancel={() => setShowClosePopup(false)}
			footer={null}
		>
			<div className='user-list-container'>
				<div className=' pt-[20px] bg-white'>
					<div className='flex items-center my-2 justify-between'>
						<div className='flex items-center'>
							<span className='text-[#000D1D99] text-sm '>{t<string>('show')}</span>
							<Dropdown className='mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
								<span className='bg-[#F5F5F5] h-10 w-10 flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer '>
									{pagesize}
									<DownOutlined className='w-2 ' style={{ fontSize: 8 }} />
								</span>
							</Dropdown>
							<span className='text-[#000D1D99] text-sm '>{t<string>('entries')}</span>
						</div>
						<Input
							className='border-none h-10 bg-[#F5F5F5] search-input w-[200px]'
							disabled={adminLoading}
							placeholder={t<string>('searchUser')}
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
							size='large'
							prefix={<SearchOutlined className='mr-2' />}
						/>
					</div>
					<Table
						onRow={(record: { id: number; fullName: string; canHandleAppeals: boolean; activeTasks: number }) => ({
							onClick: (event: React.MouseEvent<HTMLElement>) => {
								setUserId(record.id);
							},
						})}
						columns={getAdminListColumn(t, sortBy, setSortBy, setUserId)}
						dataSource={adminUsers}
						pagination={{
							pageSize: pagesize,
							total: totalElements,
							showSizeChanger: false,
							onChange: (pageIndex) => setPage(pageIndex - 1),
						}}
						loading={adminLoading}
						tableLayout='auto'
						rowClassName={getRowClassName}
					/>
				</div>
				<div className='flex justify-end'>
					<button className='assignBtn' onClick={assign}>
						{t<string>('assign')}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default AdminList;
