import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './SingleMerchant.scss';
import { LikeFilled, DislikeFilled } from '@ant-design/icons';
import MerchantReviewCarousal from '../../components/Merchants/MerchantReviewCarousal/MerchantReviewCarousal';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Merchant } from '../../models/merchant';
import { getInitials } from '../../helpers/getStringInitials';
import { useCountriesContext } from '../../store/CountriesContext';
import { DashOutlined, PhoneFilled, RedEnvelopeFilled } from '@ant-design/icons';
import Header from '../../components/Header/Header';
import { Skeleton, Typography } from 'antd';
const REVIEW_PERCENTAGE = 80;
const SingleMerchant = () => {
	const [merchant, setMerchant] = useState<Merchant>();
	const [dataLoading, setDataLoading] = useState(false);
	const [searchParams] = useSearchParams();
	const merchantId = useMemo(() => searchParams.get('id') || '', [searchParams]);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const token = getUserInfo()?.token;
	const { Text } = Typography;

	const { countriesState } = useCountriesContext();
	const buyOfferPaymMethods = countriesState.paymentMethods.filter(
		(method) => merchant?.buyerOffer.paymentMethods.includes(method.methodId || 0),
	);

	const sellOfferPaymMethods = countriesState.paymentMethods.filter(
		(method) => merchant?.sellerOffer.paymentMethods.includes(method.methodId || 0),
	);

	useEffect(() => {
		if (!merchantId) {
			navigate('/merchants');
		}
	}, [merchantId, navigate]);

	useEffect(() => {
		setDataLoading(true);
		axios
			.get(`${API_ENDPOINTS.getMerchant.replace('%merchantId%', merchantId)}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				setMerchant(res.data);
			})
			.finally(() => setDataLoading(false));
	}, []);
	return (
		<>
			<section>
				<Header title={t<string>('merchantDetails')} description={t<string>('merchantDetailsDescription')} />
				<div className='single-merchant'>
					{dataLoading ? (
						<>
							<Skeleton />
							<Skeleton />
							<Skeleton />
							<Skeleton />
						</>
					) : (
						<>
							<article className='flex flex-col items-center justify-center m-3'>
								<h2 className='name-initials'>{getInitials(merchant?.fullName ?? '')}</h2>
								<h2 className='m-1'>{merchant?.fullName}</h2>
								<p className='text-[#333333] text-[13px] m-1'>
									{merchant?.tradingAccount.tradingGroup}| {merchant?.tradingAccount?.tpAccountNumber} |{' '}
									{merchant?.tradingAccount.tradingPlatform} | {merchant?.tradingAccount.onHold}{' '}
									{merchant?.exchangeBaseCurrency}
								</p>
								<p className='text-[#333333] text-[13px] m-1'>
									<PhoneFilled className='mx-1 text-gray' />
									{merchant?.phone}
									<span className='inline-block mx-1'> </span>
									<RedEnvelopeFilled className='mx-1 text-gray' />
									{merchant?.email}
								</p>
								<div>
									<span className='text-[#333333]  m-1 flex items-baseline'>
										<span>
											{merchant?.ordersCompleted} {t<string>('orders')}
										</span>
										<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40] mx-2' />
										{(
											(Number(merchant?.ordersCompleted) /
												(merchant?.totalOrders === null || merchant?.totalOrders === 0
													? 1
													: Number(merchant?.totalOrders))) *
											100
										).toFixed()}
										% {t<string>('completionRate')}
									</span>
								</div>
								{merchant && (
									<div className='flex  items-baseline'>
										<div>
											{merchant?.totalReviews} {t<string>('reviews')}
										</div>
										<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40] mx-2' />
										<div
											className={`center-container ${
												merchant.rating < REVIEW_PERCENTAGE ? 'text-[#F6465D]' : 'text-[#33A867]'
											}`}
										>
											{merchant.rating < REVIEW_PERCENTAGE ? (
												<DislikeFilled className={`h-[13px] w-[13px] mx-1`} />
											) : (
												<LikeFilled className={`h-[13px] w-[13px] mx-1`} />
											)}
											<span>{merchant?.rating}%</span>
										</div>
									</div>
								)}
								<span className='text-[#333333] m-1'>
									{merchant?.currencyCode} {t<string>('currency')}
								</span>
							</article>
							<div className='section-container w-[100%] xss:px-5'>
								<section className='section'>
									<div className='text-card h-[230px]'>
										<div className='flex justify-between items-baseline'>
											<h3 className='heading'>{t<string>('buyOffer')}</h3>
											<div
												style={{ backgroundColor: merchant?.buyerOffer?.isPublic ? '#33a867' : '#f6465d' }}
												className={`px-[10px] py-[5px] rounded  text-white shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]`}
											>
												{merchant?.buyerOffer?.isPublic ? t<string>('active') : t<string>('inactive')}
											</div>
										</div>
										{merchant?.buyerOffer ? (
											<>
												<div className='text-container'>
													<span className='info'>{t<string>('price')}</span>
													<span className='content'>
														{merchant?.buyerOffer?.rate?.toFixed(3)?.toLocaleString()}{' '}
														{merchant?.buyerOffer?.rate ? merchant.currencyCode : ''}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('availability')}</span>
													<span className='content'>
														{merchant?.buyerOffer?.available?.toFixed(3)?.toLocaleString()}{' '}
														{merchant?.buyerOffer?.available ? merchant.exchangeBaseCurrency : ''}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('limit')}</span>
													<span className='content'>
														{merchant?.buyerOffer?.min?.toLocaleString()}-{merchant?.buyerOffer?.max?.toLocaleString()}{' '}
														{merchant?.buyerOffer?.min ? merchant.currencyCode : ''}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('paymentMethod')}</span>
													<span className='content flex justify-center flex-wrap'>
														{buyOfferPaymMethods.map((method) => (
															<div className='mr-[5px] p-[5px]  bg-[#d9ecff] rounded-sm text-[12px] text-[#0187ff] flex mb-[5px] h-fit items-center w-max'>
																{method.methodName}
															</div>
														))}
													</span>
												</div>
											</>
										) : (
											<Text className='flex items-center m-auto text-[16px] font-[700] text-[#6d6c6c]'>
												{' '}
												{t<string>('offerNotAvailable')}
											</Text>
										)}
									</div>
									{merchant?.buyerOffer && (
										<>
											<div className='horizontal-separator' />
											<div className='text-card'>
												<h3 className='heading'>{t<string>('buyersTerm')}</h3>
												{merchant?.buyerOffer?.termsNote ? <p>{merchant?.buyerOffer?.termsNote}</p> : <DashOutlined />}
											</div>
										</>
									)}
								</section>
								<hr />
								<section className='section'>
									<div className='text-card h-[230px]'>
										<div className='flex justify-between items-baseline'>
											<h3 className='heading'>{t<string>('sellOffer')}</h3>
											<div
												style={{ backgroundColor: merchant?.sellerOffer?.isPublic ? '#33a867' : '#f6465d' }}
												className={`px-[10px] py-[5px] rounded  text-white shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]`}
											>
												{merchant?.sellerOffer?.isPublic ? t<string>('active') : t<string>('inactive')}
											</div>
										</div>
										{merchant?.sellerOffer ? (
											<>
												<div className='text-container'>
													<span className='info'>{t<string>('price')}</span>
													<span className='content'>
														{merchant?.sellerOffer?.rate?.toFixed(3)?.toLocaleString()}{' '}
														{merchant?.sellerOffer?.rate ? merchant.currencyCode : ''}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('availability')}</span>
													<span className='content'>
														{merchant?.sellerOffer?.available?.toFixed(3)} {merchant.exchangeBaseCurrency}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('limit')}</span>
													<span className='content'>
														{merchant?.sellerOffer?.min?.toLocaleString()}-
														{merchant?.sellerOffer?.max?.toLocaleString()}{' '}
														{merchant?.sellerOffer?.min ? merchant.currencyCode : ''}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('paymentMethod')}</span>
													<span className='content flex justify-center flex-wrap'>
														{sellOfferPaymMethods.map((method) => (
															<div className='mr-[5px] p-[5px] bg-[#d9ecff] text-[12px] text-[#0187ff] flex h-fit mb-[5px] items-center w-max'>
																{method.methodName}
															</div>
														))}
													</span>
												</div>
											</>
										) : (
											<Text className='flex items-center m-auto text-[16px] font-[700] text-[#6d6c6c]'>
												{' '}
												{t<string>('offerNotAvailable')}
											</Text>
										)}
									</div>
									{merchant?.sellerOffer && (
										<>
											<div className='horizontal-separator' />
											<div className='text-card'>
												<h3 className='heading'>{t<string>('sellersTerm')}</h3>
												{merchant?.sellerOffer?.termsNote ? (
													<p>{merchant?.sellerOffer?.termsNote}</p>
												) : (
													<DashOutlined />
												)}
											</div>
										</>
									)}
								</section>
							</div>
							<div className='full-width-separator' />
							<div className='reviews-section'>
								<MerchantReviewCarousal merchantId={merchantId} />
							</div>
						</>
					)}
				</div>
			</section>
		</>
	);
};

export default SingleMerchant;
