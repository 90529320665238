import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import SortArrow from '../assets/images/icons/SortArrow';

import './countryAndPaymMethodColumn.scss';
import { AdminList } from '../models/AdminList';
import { handleActiveTaskSortClick, handleCanAppealSortClick, handleNametSortClick } from './handleAdminListSortClick';

export const getAdminListColumn = (
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
	setUserId: (userId: number) => void,
) => {
	const columns: ColumnsType<AdminList> = [
		{
			onHeaderCell: () => {
				return {
					onClick: () => {
						setSortBy(handleNametSortClick(sortBy));
					},
				};
			},
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('name')}
					<div className='flex flex-col mx-2'>
						<SortArrow position='up' active={sortBy === 'name-asc'} />
						<SortArrow position='down' active={sortBy === 'name-desc'} />
					</div>
				</div>
			),
			dataIndex: 'canHandleAppeal',
			key: 'canHandleAppeal',
			render: (value, record) => {
				return <div className='text-[14px] font-medium text-[#000D1D99] flex items-center'>{`${record.fullName}`}</div>;
			},
		},
		{
			onHeaderCell: () => {
				return {
					onClick: () => {
						setSortBy(handleCanAppealSortClick(sortBy));
					},
				};
			},
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('canHandleAppeal')}
					<div className='flex flex-col mx-2'>
						<SortArrow position='up' active={sortBy === 'canHandleAppeal-asc'} />
						<SortArrow position='down' active={sortBy === 'canHandleAppeal-desc'} />
					</div>
				</div>
			),
			dataIndex: 'canHandleAppeal',
			key: 'canHandleAppeal',
			render: (value, record) => {
				return (
					<div className='text-[14px] font-medium text-[#000D1D99] flex items-center'>
						{record.canHandleAppeals ? t<string>('yes') : t<string>('no')}
					</div>
				);
			},
		},
		{
			onHeaderCell: () => {
				return {
					onClick: () => {
						setSortBy(handleActiveTaskSortClick(sortBy));
					},
				};
			},
			responsive: ['lg'],
			title: (
				<div className='py-1 text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('activeTask')}
					<div className='flex flex-col mx-2'>
						<SortArrow position='up' active={sortBy === 'activeTasks-asc'} />
						<SortArrow position='down' active={sortBy === 'activeTasks-desc'} />
					</div>
				</div>
			),
			dataIndex: 'activeTask',
			key: 'activeTask',
			render: (value, record) => {
				return (
					<div className='text-[14px] font-medium text-[#000D1D99] flex items-center'>{`${record.activeTasks}`}</div>
				);
			},
		},
		{
			dataIndex: 'record',
			key: 'record',
			className: 'small-screen-row',
			render: (value, record) => {
				return (
					<div className='text-xs text-[#01081E99] flex flex-row xs:flex-col mb-1 w-100 justify-between items-center '>
						<div className='w-full'>
							<div className='flex flex-row py-2 justify-between'>
								<div> {t<string>('name')}:</div>
								<div>{record.fullName}</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('canHandleAppeal')}:</div>
								<div className='text-lightBlue text-[15px] font-medium'>
									{record.canHandleAppeals ? t<string>('yes') : t<string>('no')}
								</div>
							</div>
							<div className='flex flex-row py-2 justify-between'>
								<div>{t<string>('activeTask')}:</div>
								<div className='text-lightBlue text-[15px] font-medium'>{record.activeTasks}</div>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return columns;
};
