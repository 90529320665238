import React from 'react';
import { initialCountriesState } from '../assets/config/initial-countries-state';
import { CountriesState } from '../models/countries-state';

type Store = {
	countriesState: CountriesState;
	setCountriesState: React.Dispatch<React.SetStateAction<CountriesState>>;
};

export const CountriesContext = React.createContext<Store>({
	countriesState: initialCountriesState,
} as Store);

export const useCountriesContext = () => React.useContext(CountriesContext);
