import { AdminListFilters } from '../models/AdminList';

export const handleNametSortClick = (sortBy: string) => {
	let sortValue = '';
	if (sortBy !== AdminListFilters.nameDesc && sortBy !== AdminListFilters.nameDesc) {
		sortValue = AdminListFilters.nameDesc;
	} else if (sortBy === AdminListFilters.nameDesc) {
		sortValue = AdminListFilters.nameAsc;
	}
	return sortValue;
};

export const handleCanAppealSortClick = (sortBy: string) => {
	let sortValue = '';
	if (sortBy !== AdminListFilters.canHandleAppealDesc && sortBy !== AdminListFilters.canHandleAppealAsc) {
		sortValue = AdminListFilters.canHandleAppealDesc;
	} else if (sortBy === AdminListFilters.canHandleAppealDesc) {
		sortValue = AdminListFilters.canHandleAppealAsc;
	}
	return sortValue;
};

export const handleActiveTaskSortClick = (sortBy: string) => {
	let sortValue = '';
	if (sortBy !== AdminListFilters.activeTasksDesc && sortBy !== AdminListFilters.activeTasksAsc) {
		sortValue = AdminListFilters.activeTasksDesc;
	} else if (sortBy === AdminListFilters.activeTasksDesc) {
		sortValue = AdminListFilters.activeTasksAsc;
	}
	return sortValue;
};
