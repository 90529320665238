import React, { useLayoutEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import SignIn from '../pages/SignIn/SignIn';
import ProtectedRoutes from './ProtectedRoutes';
import NotFound from '../pages/NotFound/NotFound';

import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import { getUserInfo } from '../helpers/localStorageHandler';
import AdminRoute from './AdminRoute';
import SuperAdminRoute from './SuperAdminRoute';
import { useMainContext } from '../store/MainContext';
import { RouteProvider } from '../components/CurrentRouteProvider';
import { NotificationProvider } from '../components/NotificationContextProvider';
import AnnoncemetBanner from '../components/AnnoncemetBanner/AnnoncemetBanner';

import { Helmet } from 'react-helmet';

import inzo from '../assets/logos/inzo.png';
import webbats from '../assets/logos/webbats.png';

function Views() {
	const { mainState } = useMainContext();
	const userRole = React.useMemo(() => getUserInfo()?.role || 'ADMIN', [mainState.isLoggedIn]);

	const location = useLocation();

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	const title = process.env.REACT_APP_TITLE || 'INZO P2P';
	const logo = process.env.REACT_APP_ICON || 'inzo';

	const getLogo = () => {
		return logo === 'webbats' ? webbats : inzo;
	};
	return (
		<>
			<Helmet>
				<title>{title}</title>
				{logo && <link rel='icon' type='image/x-icon' href={getLogo()} />}
			</Helmet>
			<NotificationProvider>
				<AnnoncemetBanner />
				<Navbar />
				<RouteProvider initialValue={'/'}>
					<Routes>
						{/* Visible for all users */}
						<Route path='/sign-in' element={<SignIn />} />
						{/* Visible for all authorized users */}
						<Route element={<ProtectedRoutes />}>
							{userRole === 'SUPER_USER' ? SuperAdminRoute : AdminRoute}
							<Route path='*' element={<NotFound />} />
						</Route>
					</Routes>
				</RouteProvider>
			</NotificationProvider>
			<Footer />
		</>
	);
}

export default Views;
