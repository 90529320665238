import { SettingOutlined, AimOutlined, HomeOutlined, ControlOutlined, TeamOutlined } from '@ant-design/icons';

export function getSideSuperUserPanelItems(t: (value: string) => void): any[] {
	return [
		{ key: 'home', label: t('home'), icon: <HomeOutlined />, style: { fontSize: 12 } },
		{ key: 'merchants', label: t('merchants'), icon: <TeamOutlined />, style: { fontSize: 12 } },
		{ key: 'clients', label: t('clients'), icon: <AimOutlined />, style: { fontSize: 12 } },
		{ key: 'orders', label: t('orders'), icon: <ControlOutlined />, style: { fontSize: 12 } },
		{ key: 'appeal-task', label: t('appealTasks'), icon: <ControlOutlined />, style: { fontSize: 12 } },
		{
			key: 'administration',
			label: t('administration'),
			icon: <SettingOutlined />,
			style: { fontSize: 12 },
			children: [
				{
					label: t('countries'),
					key: 'countries',
					style: { fontSize: 12 },
				},
				{ label: t('accountGroups'), key: 'account-groups', style: { fontSize: 12 } },
				{ label: t('adminusers'), key: 'admin-users', style: { fontSize: 12 } },
				{ label: t('maintenance'), key: 'maintenance', style: { fontSize: 12 } },
			],
		},
	];
}

export function getSideAminPanelItems(t: (value: string) => void): any[] {
	return [
		{ key: 'home', label: t('home'), icon: <HomeOutlined />, style: { fontSize: 12 } },
		{ key: 'merchants', label: t('merchants'), icon: <TeamOutlined />, style: { fontSize: 12 } },
		{ key: 'clients', label: t('clients'), icon: <AimOutlined />, style: { fontSize: 12 } },
		{ key: 'orders', label: t('orders'), icon: <ControlOutlined />, style: { fontSize: 12 } },
		{ key: 'appeal-task', label: t('appealTask'), icon: <ControlOutlined />, style: { fontSize: 12 } },
	];
}
