import { HistoryOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import './OrderHistory.scss';
import OrderIcons from '../../../../constants/icons';
import { OrderHistory } from '../../../../types/OrderHistory';

const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm:ss A';

type OrderHistoryDetailsProps = {
	orderHistory: OrderHistory[];
};

function OrderHistoryDetails({ orderHistory }: OrderHistoryDetailsProps) {
	const { t } = useTranslation();

	return (
		<div className='order-history-wrapper'>
			<div className='flex mb-4'>
				<HistoryOutlined className='mx-2' style={{ fontSize: '30px', textAlign: 'center', display: 'block' }} />
				<h3 className='order-history-title'>{t<string>('orderHistory')}</h3>
			</div>
			<div className='order-history-details'>
				{orderHistory.map((order: OrderHistory) => (
					<div className='flex justify-between mb-5'>
						<p className='logMessage'>{order.logMessage}</p>
						<p>{dayjs(order?.actionOn).format(DATE_TIME_FORMAT)}</p>
					</div>
				))}
			</div>
			<></>
		</div>
	);
}

export default OrderHistoryDetails;
