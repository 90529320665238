import React from 'react';
import { Card, Avatar, Row, Col } from 'antd';
import { BellOutlined, InfoOutlined } from '@ant-design/icons';

import { notificationContentGenerator } from '../../../helpers/notificationContent';
import { NotificationActionType } from '../../../helpers/getNotificationsActionTypes';
interface NotificationCardProps {
	type: string;
	notification: NotificationActionType;
	properties: any;
	timestamp: string;
}
enum NotificationAction {
	INFO = 'INFO',
	WARNING = 'WARNING',
	ACTION = 'ACTION',
}
const NotificationCard: React.FC<NotificationCardProps> = ({ type, notification, properties, timestamp }) => {
	return (
		<Card className='notification-card' style={{ width: '300px' }}>
			<Row align='middle' className='notification-content'>
				<Col span={4}>
					<div className='avatar-container'>
						<Avatar>Ai</Avatar>
						{type === NotificationAction.ACTION ? (
							<div className='notifications-feed-avatar-badge notifications-feed-green-badge'>
								<BellOutlined size={0.05} />
							</div>
						) : type === NotificationAction.WARNING ? (
							<div className='notifications-feed-avatar-badge notifications-feed-warning-badge'>
								<InfoOutlined size={0.05} />
							</div>
						) : (
							<></>
						)}
					</div>
				</Col>
				<Col span={20}>
					<p className='notification-message font-medium'>{notificationContentGenerator(notification, properties)}</p>
					<p className='notification-timestamp'>{timestamp}</p>
				</Col>
			</Row>
		</Card>
	);
};

export default NotificationCard;
