import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './SingleClient.scss';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';

import { getInitials } from '../../helpers/getStringInitials';

import { PhoneFilled, RedEnvelopeFilled } from '@ant-design/icons';
import { Skeleton, Table } from 'antd';
import { Client } from '../../models/Clients';
import { getAccontClientsColumn } from '../../helpers/ClientAccountColumn';

const SingleClient = () => {
	const [client, setClient] = useState<Client>();
	const [dataLoading, setDataLoading] = useState(false);
	const [totalElements, setTotalElements] = useState(0);
	const [page, setPage] = useState(0);
	const [searchParams] = useSearchParams();
	const clientId = searchParams.get('id') || '';
	const navigate = useNavigate();
	const { t } = useTranslation();
	const token = getUserInfo()?.token;

	useEffect(() => {
		if (!clientId) {
			navigate('/clients');
		}
	}, [clientId, navigate]);

	useEffect(() => {
		setDataLoading(true);
		axios
			.get(`${API_ENDPOINTS.getClient.replace('%clientId%', clientId)}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				setClient(res.data);
				setTotalElements(res?.data?.accounts?.length ?? 0);
			})
			.finally(() => setDataLoading(false));
	}, []);
	return (
		<section className='p-5 bg-white h-[100vh]'>
			<div className='single-client'>
				{dataLoading ? (
					<>
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
					</>
				) : (
					<>
						<article className='flex flex-col items-center justify-center m-3 mb-8'>
							<h2 className='name-initials'>{getInitials(client?.fullName ?? '')}</h2>
							<h2 className='m-1'>{client?.fullName}</h2>
							<p className='text-[#333333] text-[13px] m-1'>
								<PhoneFilled className='mx-1 text-gray' />
								{client?.phoneNumber}
								<span className='inline-block mx-1'> </span>
								<RedEnvelopeFilled className='mx-1 text-gray' />
								{client?.emailAddress}
							</p>
							<div>
								<span className='text-[#333333] flex flex-row gap-2 m-1'>
									<span>
										{client?.ordersCompleted} {t<string>('orders')}
									</span>
									<span>|</span>
									<span>
										{(
											(Number(client?.ordersCompleted) /
												(client?.totalOrders === null || client?.totalOrders === 0 ? 1 : Number(client?.totalOrders))) *
											100
										).toFixed()}
										% {t<string>('completionRate')}
									</span>
								</span>
							</div>
						</article>
						<div className='m-5'>
							<Table
								columns={getAccontClientsColumn(t, client?.exchangeBaseCurrency ?? 'USD')}
								dataSource={client?.accounts}
								loading={dataLoading}
								pagination={{ pageSize: 10, total: totalElements, onChange: (pageIndex) => setPage(pageIndex - 1) }}
								tableLayout='auto'
							/>
						</div>
					</>
				)}
			</div>
		</section>
	);
};

export default SingleClient;
