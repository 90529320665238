import { UserState } from '../models/UserState';

export const getState = (t: (value: string) => void, state: UserState): any => {
	switch (state) {
		case 'SUSPEND':
			return t('suspendedConfirmation');
		case 'UNSUSPEND':
			return t('unsuspendedConfirmation');
		case 'BLOCK':
			return t('blockConfirmation');
		case 'UNBLOCK':
			return t('unblockConfirmation');
		case 'ACTIVATE':
			return t('ActivateConfirmation');
		case 'DEACTIVATE':
			return t('deactivateConfirmation');
		default:
			return '';
	}
};
