import c3 from 'c3';
import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Card, Form, DatePicker, Select, Button } from 'antd';
import './Home.scss';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Table } from 'antd';
import { getOrderColumns } from '../../helpers/ordersTableColumns';
import { Order } from '../../models/Orders';
import { Link } from 'react-router-dom';

import Deposit from '../../assets/images/icons/deposit.svg';
import Withdraw from '../../assets/images/icons/withdraw.svg';
import merchants from '../../assets/images/icons/merchants.svg';
import ActiveOrders from '../../assets/images/icons/activeorders.svg';
import AppealsOrders from '../../assets/images/icons/appealsorders.svg';
import Orders from '../../assets/images/icons/orders.svg';
import Clients from '../../assets/images/icons/totalclient.svg';
import { useDirectionContext } from '../../store/DirectionContext';

const DATE_FORMAT = 'YYYY-MM-DD';

enum Operation {
	withdraw = 'withdraw',
	deposit = 'deposit',
}
function Home() {
	const { t } = useTranslation();
	const today = new Date();
	const token = getUserInfo()?.token;
	const lastWeek = new Date(today);
	lastWeek.setDate(today.getDate() - 7);
	const { RangePicker } = DatePicker;
	const [totalClients, setTotalClients] = React.useState(0);
	const [totalMerchants, setTotalMerchants] = React.useState(0);
	const [totalOrders, setTotalOrders] = React.useState(0);
	const [totalAppealOrders, setTotalAppealOrders] = React.useState(0);
	const [totalActiveOrders, setTotalActiveOrders] = React.useState(0);
	const [totalWithdrawalAmount, setTotalWithdrawalAmount] = React.useState(0);
	const [totalDepositAmount, setTotalDepositAmount] = React.useState(0);
	const [isBuy, setIsBuy] = useState(true);
	const [operation, setOperation] = useState('');
	const { directionState } = useDirectionContext();
	const validSelectedRanges = [
		{ value: 'TODAY', label: t('today') },
		{ value: 'YESTERDAY', label: t('yesterday') },
		{ value: 'THIS_WEEK', label: t('thisWeek') },
		{ value: 'THIS_MONTH', label: t('thisMonth') },
		{ value: 'LAST_60_DAYS', label: t('last60Days') },
		{ value: 'THIS_QUARTER', label: t('thisQuarter') },
		{ value: 'THIS_YEAR', label: t('thisYear') },
		{ value: 'DATE_RANGE', label: t('dateRange') },
	];
	const [sortBy, setSortBy] = React.useState<string>('');
	const [orders, setOrders] = React.useState([]);
	const [selectedDates, setSelectedDates] = React.useState<[Dayjs, Dayjs]>([dayjs(today), dayjs(lastWeek)]);
	const [selectedRange, setSelectedRange] = React.useState('THIS_WEEK');
	const [startDate, setStartDate] = React.useState('');
	const [endDate, setEndDate] = React.useState('');
	const [order, setOrder] = React.useState<Order | null>(null);
	React.useEffect(() => {
		if (selectedRange === 'DATE_RANGE') {
			if (startDate && endDate) {
				axios
					.get(`${API_ENDPOINTS.dashboard}`, {
						params: {
							criteria: selectedRange,
							startDate: startDate,
							endDate: endDate,
						},
						headers: { Authorization: `Bearer ${token}` },
					})
					.then((res) => {
						const dates = res.data.data.map((row: any) => row.date);
						const sellOrders = res.data.data.map((row: any) => row.sell);
						const sellOrdersTotal = sellOrders.reduce((a: any, b: any) => a + b, 0);
						const buyOrders = res.data.data.map((row: any) => row.buy);
						const buyOrdersTotal = buyOrders.reduce((a: any, b: any) => a + b, 0);
						const activeOrders = res.data.activeOrders;
						const totalClients = res.data.totalClients;
						const totalMerchants = res.data.totalMerchants;
						const totalOrders = res.data.totalOrders;
						const appealOrdersCount = res.data.appealOrdersCount;
						setTotalActiveOrders(activeOrders);
						setTotalClients(totalClients);
						setTotalMerchants(totalMerchants);
						setTotalOrders(totalOrders);
						setTotalAppealOrders(appealOrdersCount);
						setOrders(res.data.activeOrdersData);
						setTotalWithdrawalAmount(res.data.totalWithdrawalAmount);
						setTotalDepositAmount(res.data.totalDepositAmount);
						let columns: any = [];
						if (operation === Operation.withdraw) {
							columns = [['sell', ...sellOrders]];
						}
						if (operation === Operation.deposit) {
							columns = [['buy', ...buyOrders]];
						}
						if (!operation) {
							columns = [
								['sell', ...sellOrders],
								['buy', ...buyOrders],
							];
						}
						c3.generate({
							bindto: '#chart1',
							data: {
								columns: columns,
								types: { buy: 'spline', sell: 'spline' },
								colors: { buy: '#33A867', sell: '#F6465D' },
							},
							axis: {
								x: {
									type: 'category',
									tick: { rotate: -30 },
								},
								y: { min: 0 },
							},
							legend: { show: false },
							tooltip: {
								grouped: false,
								contents: (value) => {
									const selectedIndex = value[0].index;
									const buy = buyOrders;
									const sell = sellOrders;
									const date = dates;
									return `<div class='tooltip-container'>
							<p class='tooltip-text'>Deposit: <span class='value buy'>${buy[selectedIndex]}</span></p>
							<p class='tooltip-text'>Withdrawal: <span class='value sell'>${sell[selectedIndex]}</span></p>
							<p class='tooltip-text'>Date: <span class='value'>${date[selectedIndex]}</span></p>
						</div>`;
								},
							},
							grid: { y: { show: true } },
						});
						let totalBuy = res.data.analytics.totalBuy;
						let totalSell = res.data.analytics.totalSell;
						c3.generate({
							bindto: '#chart2',
							data: {
								columns: [
									buyOrdersTotal === 0 && sellOrdersTotal === 0 ? ['Empty', 1] : ['Deposit', buyOrdersTotal],
									['Withdrawal', sellOrdersTotal],
								],
								type: 'donut',

								colors: { Deposit: '#33A867', Withdrawal: '#F6465D', Empty: '#f5f5f6' },
							},
							legend: { show: true },
							axis: { x: { show: false }, y: { show: false } },
							tooltip: {
								contents: (value) => {
									return '';
								},
							},
							donut: {
								width: 8,
								title: `${Math.floor(isBuy ? totalBuy : totalSell)}%`,
								label: {
									show: false, // Hide labels outside the donut
								},
							},
							// Add onclick event handler
							onrendered: function () {
								document.querySelectorAll('#chart2 .c3-chart-arc path').forEach((path, index) => {
									path.addEventListener('click', () => {
										setIsBuy(index === 0);
									});
								});
							},
						});
					})
					.catch((err) => console.error(err));
			}
		} else if (selectedRange !== 'DATE_RANGE') {
			axios
				.get(`${API_ENDPOINTS.dashboard}`, {
					params: {
						criteria: selectedRange,
					},
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((res) => {
					const dates = res.data.data.map((row: any) => row.date);
					const sellOrders = res.data.data.map((row: any) => row.sell);
					const sellOrdersTotal = sellOrders.reduce((a: any, b: any) => a + b, 0);
					const buyOrders = res.data.data.map((row: any) => row.buy);
					const buyOrdersTotal = buyOrders.reduce((a: any, b: any) => a + b, 0);
					const activeOrders = res.data.activeOrders;
					const totalClients = res.data.totalClients;
					const totalMerchants = res.data.totalMerchants;
					const totalOrders = res.data.totalOrders;
					const appealOrdersCount = res.data.appealOrdersCount;
					setTotalActiveOrders(activeOrders);
					setTotalClients(totalClients);
					setTotalMerchants(totalMerchants);
					setTotalOrders(totalOrders);
					setTotalAppealOrders(appealOrdersCount);
					setOrders(res.data.activeOrdersData);
					setTotalWithdrawalAmount(res.data.totalWithdrawalAmount);
					setTotalDepositAmount(res.data.totalDepositAmount);
					let columns: any = [];
					if (operation === Operation.withdraw) {
						columns = [['sell', ...sellOrders]];
					}
					if (operation === Operation.deposit) {
						columns = [['buy', ...buyOrders]];
					}
					if (!operation) {
						columns = [
							['sell', ...sellOrders],
							['buy', ...buyOrders],
						];
					}
					c3.generate({
						bindto: '#chart1',
						padding: {
							left: 50, // Adjust the value as needed
							right: 20, // Adjust the value as needed
						},
						data: {
							columns: columns,
							types: { buy: 'spline', sell: 'spline' },
							colors: { buy: '#33A867', sell: '#F6465D' },
						},
						axis: {
							x: {
								type: 'indexed', // Use 'indexed' type for simple numeric index
								tick: {
									format: function (x) {
										return '';
									}, // Empty function to remove labels
								},
							},
							y: { min: 0 },
						},
						legend: { show: false },
						tooltip: {
							grouped: false,
							contents: (value) => {
								const selectedIndex = value[0].index;
								const buy = buyOrders;
								const sell = sellOrders;
								const date = dates;
								return `<div class='tooltip-container'>
							<p class='tooltip-text'>Deposit: <span class='value buy'>${buy[selectedIndex]}</span></p>
							<p class='tooltip-text'>Withdrawal: <span class='value sell'>${sell[selectedIndex]}</span></p>
							<p class='tooltip-text'>Date: <span class='value'>${date[selectedIndex]}</span></p>
						</div>`;
							},
						},
						grid: { y: { show: true } },
					});
					let totalBuy = res.data.analytics.totalBuy;
					let totalSell = res.data.analytics.totalSell;
					c3.generate({
						bindto: '#chart2',
						size: {
							height: 175, // Set the desired height
						},
						data: {
							columns: [
								buyOrdersTotal === 0 && sellOrdersTotal === 0 ? ['Empty', 1] : ['Deposit', buyOrdersTotal],
								['Withdrawal', sellOrdersTotal],
							],
							type: 'donut',
							colors: { Deposit: '#33A867', Withdrawal: '#F6465D', Empty: '#f5f5f6' },
						},
						legend: { show: false },
						axis: { x: { show: false }, y: { show: false } },
						tooltip: {
							contents: (value) => {
								return '';
							},
						},
						donut: {
							width: 10,
							title: `${Math.floor(isBuy ? totalBuy : totalSell)}%`,
							label: {
								show: false, // Hide labels outside the donut
							},
						},
						onrendered: function () {
							document.querySelectorAll('#chart2 .c3-chart-arc path').forEach((path, index) => {
								path.addEventListener('click', () => {
									setIsBuy(index === 0); // Log the clicked index
								});
							});
						},
					});
				})
				.catch((err) => console.error(err));
		}
	}, [selectedRange, startDate, endDate, sortBy, isBuy, operation]);

	const handleDateChange = (value: string) => {
		setSelectedRange(value);
	};
	return (
		<div className='home-container'>
			<section className='home'>
				<div className='filters-container'>
					<Form layout='vertical'>
						<div className='flex gap-3'>
							<div className='xss:w-[100%] sm:w-[300px]'>
								<Form.Item label={t<string>('dateRange')} className='mx-5'>
									<Select
										defaultValue={selectedRange}
										onChange={handleDateChange}
										options={validSelectedRanges.map((range) => ({
											value: range.value,
											label: range.label,
										}))}
									/>
								</Form.Item>
							</div>
							<div>
								<Form.Item label={t<string>('customeRange')}>
									<RangePicker
										className='w-[100%]'
										disabled={selectedRange !== 'DATE_RANGE'}
										defaultValue={[dayjs(selectedDates[0], DATE_FORMAT), dayjs(selectedDates[1], DATE_FORMAT)] as any}
										onChange={(values) => {
											if (values) {
												setStartDate(dayjs(values[0]).format('YYYY-MM-DD'));
												setEndDate(dayjs(values[1]).format('YYYY-MM-DD'));
											}
										}}
									/>
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
				<div className='lg:flex justify-between items-center  xss:grid grid-cols-3 lg:flex-row gap-2'>
					{[
						{
							item: totalActiveOrders,
							color: '#fbe6ce',
							title: t<string>('dashboardActiveOrders'),
							icon: ActiveOrders,
						},
						{
							item: totalAppealOrders,
							color: '#efead5',
							title: t<string>('dashboardAppealOrders'),
							icon: AppealsOrders,
						},
						{ item: totalOrders, color: '#ccefff', title: t<string>('dashboardTotalOrders'), icon: Orders },
						{ item: totalMerchants, color: '#fff7cc', title: t<string>('dashboardTotalMerchants'), icon: merchants },
						{ item: totalClients, color: '#fccfcf', title: t<string>('dashboardTotalClients'), icon: Clients },
					].map((obj) => (
						<Card className='flex lg:w-[19%] xss:h-[70px] md:h-[100px] xss:mb-1 md:mb-0 top-cards text-[13px] '>
							<div className='flex items-center justify-center w-full px-2 xss:text-[9px] md:text-sm'>
								<div
									className='circle xss:w-[1.5rem] xss:h-[1.5rem] md:w-[3rem] md:h-[3rem]'
									style={{
										borderRadius: '50%',
										backgroundColor: obj.color,
										alignContent: 'center',
										textAlign: 'center',
									}}
								>
									<img src={obj.icon} alt='item-icon' className='xss:w-3 md:w-6' />
								</div>
								<div
									className='flex flex-col'
									style={directionState === 'rtl' ? { paddingRight: '5px' } : { paddingLeft: '5px' }}
								>
									<p style={{ fontWeight: 'bold' }} className='circle-value m-0'>
										{obj.item}
									</p>
									<p className='label m-0'>{obj.title}</p>
								</div>
							</div>
						</Card>
					))}
				</div>
				<div className='charts-container xss:block lg:grid my-2'>
					<div className='grid-item1 min-h-[445px] '>
						<Card style={{ boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)' }}>
							<h1 className='px-4'>{t('reports')}</h1>
							<p className='px-2 flex flex-wrap'>
								<p
									className='px-2  flex flex-wrap  font-bold'
									onClick={() => setOperation(operation === Operation.deposit ? '' : Operation.deposit)}
								>
									<div
										style={{
											width: '20px',
											height: '20px',
											borderRadius: '50%',
											border: '3px solid #33A867',
											margin: '0 8px',
										}}
									></div>
									{t('buyOrders')}
								</p>
								<p
									className='px-2  flex flex-wrap font-bold'
									onClick={() => setOperation(operation === Operation.withdraw ? '' : Operation.withdraw)}
								>
									<div
										style={{
											width: '20px',
											height: '20px',
											borderRadius: '50%',
											border: '3px solid #F6465D',
											margin: '0 8px',
										}}
									></div>
									{t('sellOrders')}
								</p>
							</p>
							<div id='chart1' className='area-chart'></div>
						</Card>
					</div>
					<div className='grid-item2 min-h-[445px]'>
						<Card
							style={{ boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)' }}
							className='xss:mt-2 lg:mt-0 h-[40%]'
						>
							<div className='m-8'>
								<p className='text-[#4f4e69] font-bold text-[16px]'>{t<string>('ordersAmountStatistic')}</p>
								<div className='flex items-center my-3 justify-between'>
									<span className='flex items-center'>
										<img src={Deposit} className='mx-1' alt='item-icon' />
										{t<string>('buyAmount')}
									</span>
									<span className='font-bold'>{totalDepositAmount}</span>
								</div>
								<div className='flex items-center my-3 justify-between'>
									<span className='flex items-center'>
										<img src={Withdraw} className='mx-1' alt='item-icon' />
										{t<string>('sellAmount')}
									</span>
									<span className='font-bold'>{totalWithdrawalAmount}</span>
								</div>
							</div>
						</Card>
						<Card style={{ boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)', marginTop: '13px', height: '57%' }}>
							<h1 className='text-[18px] text-[#4f4e69] font-bold flex sm:justify-center xss:px-7'>
								{t('totalAnalyticsOrder')}
							</h1>
							<div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
								<div id='chart2' className='donut-chart w-[160px] top-0 '></div>
								<div>
									<div className='flex'>
										<div className='w-[18px] h-[20px] rounded bg-[#33A867] m-2 mt-0' onClick={() => setIsBuy(true)} />
										{t<string>('buy')}
									</div>
									<div className='flex'>
										<div className='w-[18px] h-[20px] rounded bg-[#F6465D] m-2 mt-0' onClick={() => setIsBuy(false)} />{' '}
										{t<string>('sell')}{' '}
									</div>
								</div>
							</div>
						</Card>
					</div>
				</div>
				<div>
					<div
						style={{ boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)', borderRadius: '10px' }}
						className='px-4 pt-[30px] pb-20 bg-white'
					>
						<h1>{t('dashboardActiveOrders')}</h1>
						<Table
							columns={getOrderColumns(t, sortBy, setSortBy, setOrder)}
							dataSource={orders}
							pagination={false}
							tableLayout='auto'
						/>
						<div className='pt-4 w-full flex justify-end'>
							<Button>
								<Link to='/orders'>{t('seeMore')}</Link>
							</Button>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Home;
