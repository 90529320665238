import './MerchantsHead.scss';
import { useTranslation } from 'react-i18next';

const MerchantsHead = () => {
	const { t } = useTranslation();

	return (
		<div className='merchants-head py-10'>
			<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center'>{t<string>('merchantsOffer')}</h1>
			<p className='text-white text-sm text-center'>{t<string>('merchantsOfferSubtitle')}</p>
		</div>
	);
};

export default MerchantsHead;
