import React, { useState, useEffect } from 'react';
import './MerchantReviewCarousal.scss';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { DislikeFilled, LikeFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Review } from '../../../models/Review';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';

import axios from 'axios';
import { getUserInfo } from '../../../helpers/localStorageHandler';
import { getInitials } from '../../../helpers/getStringInitials';

const ReviewCard = ({ name, text, isLike }: { name: string; text: string; isLike: boolean }) => (
	<div className='single-review-container'>
		<div className='single-review-header justify-between'>
			<div className='flex items-center'>
				<div className='merchant-initials font-bold mx-2'>{getInitials(name)}</div>
				<h4>{name}</h4>
			</div>
			{!isLike ? (
				<DislikeFilled className={`h-[20px] w-[20px] text-[#F6465D]`} />
			) : (
				<LikeFilled className={`h-[20px] w-[20px] text-[#33A867]`} />
			)}
		</div>
		<p style={{ lineBreak: 'anywhere' }}>{text}</p>
	</div>
);

const MerchantReviewCarousal = ({ merchantId }: { merchantId: string }) => {
	const { t } = useTranslation();
	const [reviewLoading, setReviewLoading] = useState(false);
	const [reviews, setReviews] = useState<Review[]>([]);

	const token = getUserInfo()?.token;

	useEffect(() => {
		setReviewLoading(true);
		axios
			.get(`${API_ENDPOINTS.getReviews.replace('%merchantId%', merchantId)}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				setReviews(res.data.data);
			})
			.catch((err) => console.error(err))
			.finally(() => setReviewLoading(false));
	}, []);
	return (
		<div className='carousel-container xss:w-[85%] sm:w-full m-auto my-2'>
			<div className='mx-4'>
				<h2 className='review-heading'>{t<string>('merchantReview')}</h2>
			</div>
			<div className='review-container contents'>
				<Splide
					options={{
						type: 'slide',
						perPage: 3,
						breakpoints: {
							1124: { perPage: 2 },
							767: { perPage: 1 },
						},
						perMove: 1,
						rewind: false,
						arrows: true,
					}}
				>
					{reviews.map((review, index) => (
						<SplideSlide key={index}>
							<ReviewCard name={review.clientFirstName} text={review.comment} isLike={review.isLike} />
						</SplideSlide>
					))}
				</Splide>
			</div>
		</div>
	);
};

export default MerchantReviewCarousal;
