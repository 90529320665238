export const countriesMock = [
	{
		name: 'Iraq',
		code: 'iq',
	},
	{
		name: 'Egypt',
		code: 'eg',
	},
	{
		name: 'Lebanon',
		code: 'lb',
	},
	{
		name: 'Saudi Arabia',
		code: 'ksa',
	},
	{
		name: 'Jordan',
		code: 'jor',
	},
];
