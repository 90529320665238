import { Alert, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import './OrderDetails.scss';

type OrderDetailsProps = {
	unitPrice: string;
	quantity: string;
	amount: string;
	note: string;
};

function OrderDetails({ unitPrice, quantity, amount, note }: OrderDetailsProps) {
	const { t } = useTranslation();

	return (
		<div className='order-details-wrapper'>
			<h3 className='order-details-title'>{t<string>('orderDetails')}</h3>
			{amount !== 'undefined' ? (
				<>
					<div className='details-row'>
						<p>{t<string>('unitPrice')}</p>
						<p className='value'>{unitPrice}</p>
					</div>
					<div className='details-row'>
						<p>{t<string>('quantity')}</p>
						<p className='value'>{quantity}</p>
					</div>
					<div className='details-row'>
						<p>{t<string>('amount')}</p>
						<p className='value'>{amount}</p>
					</div>
				</>
			) : (
				<Skeleton active paragraph={{ rows: 1 }} />
			)}
		</div>
	);
}

export default OrderDetails;
