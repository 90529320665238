export enum AdminListFilters {
	nameAsc = 'name-asc',
	nameDesc = 'name-desc',
	canHandleAppealAsc = 'canHandleAppeal-asc',
	canHandleAppealDesc = 'canHandleAppeal-desc',
	activeTasksAsc = 'activeTasks-asc',
	activeTasksDesc = 'activeTasks-desc',
}

export type AdminList = {
	id: number;
	fullName: string;
	canHandleAppeals: boolean;
	activeTasks: number;
};
