export const paymentMehodsMock = [
	{
		key: 'asd-qwe-123-asd',
		name: 'ZainCash',
		color: '#0077FF',
		bgColor: '#d9f0ff',
	},
	{
		key: '567-qwe-999-asd',
		name: 'millennium',
		color: '#0077FF',
		bgColor: '#d9f0ff',
	},
	{
		key: 'vvn-rre-123-asd',
		name: 'Hawalli',
		color: '#0077FF',
		bgColor: '#d9f0ff',
	},
];
