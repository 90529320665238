import { Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';

export function PhotoPreview({
	showModal,
	setShowModal,
	paymentProofFileId,
	photo,
	pdfUrl,
}: {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	paymentProofFileId: string;
	photo: string;
	pdfUrl: string;
}) {
	const { t } = useTranslation();
	const downloadFile = () => {
		const a = document.createElement('a');
		a.href = `data:image/jpeg;base64,${photo}`;
		a.download = paymentProofFileId;
		a.click();
		setShowModal(false);
	};
	return (
		<Modal onCancel={() => setShowModal(false)} open={showModal} footer={null} width={1000}>
			<div className='flex justify-center flex-col align-middle items-center'>
				<div className='uploadedFile rounded-2xl mb-3' onClick={downloadFile} style={{ cursor: 'pointer' }}>
					<DownloadOutlined style={{ color: '#018aff', fontSize: '18px', margin: '5px 8px' }} />
					{t<string>('download')}
				</div>
				{!pdfUrl && !photo ? (
					<Spin spinning={!pdfUrl && !photo}></Spin>
				) : (
					<>
						<div className='flex justify-center items-center text-2xl m-auto'>
							{!pdfUrl ? (
								<img
									src={`data:image/jpeg;base64,${photo}`}
									alt=''
									style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '0 auto' }}
								/>
							) : (
								<iframe title='pdf-preview' src={pdfUrl} width='100%' height='500px' />
							)}
						</div>
					</>
				)}
			</div>
		</Modal>
	);
}
