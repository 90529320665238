import { getUserInfo } from './localStorageHandler';

export function getIsUserLogedIn(): boolean {
	const userInfo = getUserInfo();

	if (!userInfo?.expirationTime) {
		return false;
	}

	const currentDate = new Date();
	const expirationDate = new Date(userInfo?.expirationTime);

	return Boolean((expirationDate as any) - (currentDate as any));
}
