import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { EMAIL_VALIDATION, PASSWORD_VALIDATION } from '../../assets/config/validation-regex';
import { SignInValues } from '../../models/SignIn';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { deleteUserInfo, getUserInfo, setUserInfo } from '../../helpers/localStorageHandler';
import { useMainContext } from '../../store/MainContext';
import { resetMainState } from '../../assets/config/initial-main-state';
import './SignIn.scss';
import { getIsUserLogedIn } from '../../helpers/getIsUserLogedIn';
import { encryptData } from '../../helpers/passwordEncryptionHandler';
import { getUserLanguageInCookie } from '../../helpers/cookiesHandler';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHideTidioChat } from '../../hooks/useHideTidioChat';

const siteKey = process.env.REACT_APP_SITE_KEY || '';

function SignIn() {
	const { t, ...options } = useTranslation();
	const navigate = useNavigate();
	const { mainState, setMainState } = useMainContext();
	const [signInFormLoading, setSignInFormLoading] = React.useState(false);
	const [signInFormDisabled, setSignInFormDisabled] = React.useState(true);
	const [recaptcha, setRecaptcha] = useState(false);
	const [isExpired, setIsExpired] = useState(false);
	const currentYear = React.useMemo(() => new Date().getFullYear(), []);

	useHideTidioChat();

	useEffect(() => (getIsUserLogedIn() ? navigate('/merchants') : deleteUserInfo()), []);

	const onSubmit = (signInValues: SignInValues) => {
		const encryptedPassword = encryptData(signInValues.password, signInValues.email.toLocaleLowerCase());
		axios
			.post(API_ENDPOINTS.signin, {
				...signInValues,
				email: signInValues.email.toLocaleLowerCase(),
				password: encryptedPassword,
			})
			.then((res) => {
				const userData = res.data;
				setMainState({
					...mainState,
					email: userData.email,
					userName: userData.userName,
					countryCode: userData.countryCode,
					isLoggedIn: true,
					role: userData.role,
					canHandleAppeal: userData.canHandleAppeal,
				});
				setUserInfo(res.data);
				// Calculate the delay for token refresh (e.g., 10 seconds before token expiration)
				setSignInFormLoading(false);
				navigate('/merchants');
			})
			.catch((_) => {
				deleteUserInfo();
				setMainState(resetMainState);
				setSignInFormLoading(false);
			});
	};

	const onFormChange = (_: any, signInValues: SignInValues) => {
		setSignInFormDisabled(
			!EMAIL_VALIDATION.test(signInValues?.email) || !PASSWORD_VALIDATION.test(signInValues?.password),
		);
	};
	const handleRecaptchaChange = (value: string | null) => {
		if (value) {
			setRecaptcha(true);
			setIsExpired(false);
		}
	};

	return (
		<>
			<section className='signin-section'>
				<div className='form-container w-100 sm:w-[420px] m-5'>
					<div>
						<h1 className='form-container-heading'>{t<string>('welcomeBack')}👋</h1>
						<p className='form-container-subheading'>{t<string>('loginToAdminPortal')}</p>
					</div>
					<Form
						name='basic'
						layout='vertical'
						onFinish={onSubmit}
						onValuesChange={onFormChange}
						autoComplete='off'
						requiredMark='optional'
					>
						<Form.Item
							label={t<string>('email')}
							name='email'
							rules={[
								{ required: true, message: t<string>('emailRequired') },
								{ pattern: EMAIL_VALIDATION, message: t<string>('emailMsg') },
							]}
						>
							<Input placeholder={t<string>('enterYourEmail')} />
						</Form.Item>
						<Form.Item
							label={
								<div className='password-label-container'>
									<span>{t<string>('password')}</span>
								</div>
							}
							name='password'
							rules={[
								{ required: true, message: t<string>('passwordRequired') },
								{
									pattern: PASSWORD_VALIDATION,
									message: t<string>('passwordMsg'),
								},
							]}
						>
							<Input.Password placeholder={t<string>('enterPassword')} />
						</Form.Item>
						<Form.Item>
							<Button
								className='form-btn'
								type='primary'
								htmlType='submit'
								disabled={signInFormDisabled || !recaptcha || isExpired}
								loading={signInFormLoading}
							>
								{t<string>('signIn')}
							</Button>
						</Form.Item>
						<div className='links'>
							{t<string>('readOur')}{' '}
							<Link
								target='_blank'
								to='https://my.inzo.co/public_media/03/d0/03d6b120-8365-11ec-9556-415712c08dd0.pdf'
								className='link'
							>
								{t<string>('termsAndConditions')}
							</Link>
						</div>
						<p className='all-rights-reserved'>
							© {currentYear} Webbats.com. {t<string>('allRightsReserved')}
						</p>
					</Form>
				</div>
				<ReCAPTCHA
					sitekey={siteKey}
					onChange={handleRecaptchaChange}
					onExpired={() => setIsExpired(true)}
					hl={options.i18n.language}
					key={options.i18n.language}
				/>
			</section>
		</>
	);
}

export default SignIn;
