import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './AccountGroup.scss';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Button, Form, InputNumber, Select, Spin } from 'antd';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRouteContext } from '../../components/CurrentRouteProvider';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { OTPModal } from '../Country/OTPModal';
interface FieldItem {
	value: string;
	errorMessage: string;
	validationStatus: ValidateStatus;
}
const AccountGroup = () => {
	const params = useParams();
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const [groups, setGroups] = useState<any[]>([]);
	const [tradingPlatforms, setTradingPlatforms] = useState<string[]>([]);
	const [tradingGroups, setTradingGroups] = useState<any[]>([]);
	const [tradingGroup, setTradingGroup] = useState('');
	const [tradingPlatform, setTradingPlatform] = useState('');
	const [selectedBuyMin, setSelectedBuyMin] = useState<FieldItem>({
		validationStatus: 'success',
		errorMessage: '',
		value: '',
	});
	const [selectedBuyMax, setSelectedBuyMax] = useState<FieldItem>({
		validationStatus: 'success',
		errorMessage: '',
		value: '',
	});
	const [selectedSellMax, setSelectedSellMax] = useState<FieldItem>({
		validationStatus: 'success',
		errorMessage: '',
		value: '',
	});
	const [selectedSellMin, setSelectedSellMin] = useState<FieldItem>({
		validationStatus: 'success',
		errorMessage: '',
		value: '',
	});
	const maxOrderLimit = 50000;
	const [loading, setLoading] = useState(false);
	const [showOTPModal, setShowOTPModal] = useState(false);
	const token = getUserInfo()?.token;
	const queryParams = new URLSearchParams(location.search);
	const { routeVariable, updateRouteVariable } = useRouteContext();
	useEffect(() => {
		updateRouteVariable('/account-group');
	}, [location.pathname]);
	const submitRecord = () => {
		setLoading(true);
		axios(`${API_ENDPOINTS.accountGroupsLimits}`, {
			method: params.tradingPlatform ? 'PUT' : 'POST',
			headers: { Authorization: `Bearer ${token}` },
			data: {
				sellMin: selectedSellMin.value,
				sellMax: selectedSellMax.value,
				buyMin: selectedBuyMin.value,
				buyMax: selectedBuyMax.value,
				group: tradingGroup,
				tradingPlatform: tradingPlatform,
			},
		})
			.then((_) => navigate('/account-groups'))
			.catch((err) => console.error(err))
			.finally(() => setLoading(false));
	};
	useEffect(() => {
		if (params.tradingPlatform) {
			setTradingPlatform(params.tradingPlatform);
			const tradingAccountGroup = queryParams.get('tradingAccountGroup');
			if (tradingAccountGroup) {
				setTradingGroup(tradingAccountGroup);
			}
			const buyMin = queryParams.get('buyMin') ?? '';
			const buyMax = queryParams.get('buyMax') ?? '';
			const sellMin = queryParams.get('sellMin') ?? '';
			const sellMax = queryParams.get('sellMax') ?? '';

			setSelectedBuyMin({ value: buyMin, errorMessage: '', validationStatus: 'success' });
			setSelectedSellMin({ value: sellMin, errorMessage: '', validationStatus: 'success' });
			setSelectedBuyMax({ value: buyMax, errorMessage: '', validationStatus: 'success' });
			setSelectedSellMax({ value: sellMax, errorMessage: '', validationStatus: 'success' });
		}
	}, [params.tradingPlatform]);

	useEffect(() => {
		axios
			.get(`${API_ENDPOINTS.accountGroups}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				setGroups(res.data);
				setTradingPlatforms(
					res.data.map((el: any) => {
						return el.tradingPlatform;
					}),
				);
			})
			.catch((err) => console.error(err));
	}, []);

	useEffect(() => {
		const group = groups.find((el: any) => el.tradingPlatform === tradingPlatform)?.groups;
		setTradingGroups(group);
	}, [tradingPlatform]);
	return (
		<>
			<div>
				<div className='merchants-head py-10'>
					<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center'>
						{params.tradingPlatform ? t<string>('editAccountGroupLimits') : t<string>('addAccountGroupLimits')}
					</h1>
					<p className='text-white text-sm text-center'>{t<string>('clientsPageHeading')}</p>
				</div>
				<div className='border border-solid border-[#E6E6E6] rounded-md p-4 max-w-[900px] mx-auto px-[75px] pt-[20px] pb-20 mt-16 bg-white'>
					<Spin spinning={loading}>
						<div>
							<Form layout='vertical'>
								<div>
									<div className='w-full flex justify-center'>
										<Form.Item required label={<b>{t<string>('tradingPlatform')}</b>} className='w-[300px]'>
											<Select
												defaultValue={''}
												value={tradingPlatform}
												loading={false}
												onChange={(e: string) => {
													setTradingPlatform(e);
												}}
												disabled={Boolean(params.tradingPlatform)}
											>
												{tradingPlatforms.map((item, index) => (
													<Select.Option key={index} value={item}>
														{item}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item required label={<b>{t<string>('tradingAccountGroup')}</b>} className='w-[300px]'>
											<Select
												defaultValue={''}
												disabled={!tradingPlatform || Boolean(params.tradingPlatform)}
												value={tradingGroup}
												loading={false}
												onChange={(e: string) => {
													setTradingGroup(e);
												}}
											>
												{tradingGroups?.map((item, index) => (
													<Select.Option key={index} value={item}>
														{item}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											required
											validateStatus={selectedBuyMin.validationStatus}
											help={selectedBuyMin.validationStatus !== 'success' ? selectedBuyMin.errorMessage : null}
											label={<b>{t<string>('buyMin')}</b>}
											className='w-[300px]'
										>
											<InputNumber
												className='w-full'
												value={selectedBuyMin.value}
												onChange={(value) => {
													if (value) {
														if (isNaN(parseFloat(value))) {
															setSelectedBuyMin({
																value: '0',
																errorMessage: t<string>('enterValidValue'),
																validationStatus: 'error',
															});
														}
														if (parseFloat(value) > parseFloat(selectedBuyMax.value)) {
															setSelectedBuyMin({
																value,
																errorMessage: t<string>('buyMinShouldBeLessThanBuyMax'),
																validationStatus: 'error',
															});
														} else if (parseFloat(value) > maxOrderLimit) {
															setSelectedBuyMin({
																value,
																errorMessage: t<string>('buyMinShouldNotBeGreaterThanFiftyThousand'),
																validationStatus: 'error',
															});
														} else {
															setSelectedBuyMin({ value, errorMessage: '', validationStatus: 'success' });
														}
													}
												}}
											/>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											required
											validateStatus={selectedBuyMax.validationStatus}
											help={selectedBuyMax.validationStatus !== 'success' ? selectedBuyMax.errorMessage : null}
											label={<b>{t<string>('buyMax')}</b>}
											className='w-[300px]'
										>
											<InputNumber
												className='w-full'
												value={selectedBuyMax.value}
												onChange={(value) => {
													if (value) {
														if (isNaN(parseFloat(value))) {
															setSelectedBuyMax({
																value: '0',
																errorMessage: t<string>('enterValidValue'),
																validationStatus: 'error',
															});
														}
														if (parseFloat(value) < parseFloat(selectedBuyMin.value)) {
															setSelectedBuyMax({
																value,
																errorMessage: t<string>('buyMaxShouldBeGreaterThanBuyMin'),
																validationStatus: 'error',
															});
														} else if (parseFloat(value) > maxOrderLimit) {
															setSelectedBuyMax({
																value,
																errorMessage: t<string>('buyMaxShouldNotBeGreaterThanFiftyThousand'),
																validationStatus: 'error',
															});
														} else {
															setSelectedBuyMax({ value, errorMessage: '', validationStatus: 'success' });
														}
													}
												}}
											/>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											required
											validateStatus={selectedSellMin.validationStatus}
											help={selectedSellMin.validationStatus !== 'success' ? selectedSellMin.errorMessage : null}
											label={<b>{t<string>('sellMin')}</b>}
											className='w-[300px]'
										>
											<InputNumber
												className='w-full'
												value={selectedSellMin.value}
												onChange={(value) => {
													if (value) {
														if (isNaN(parseFloat(value))) {
															setSelectedSellMin({
																value: '0',
																errorMessage: t<string>('enterValidValue'),
																validationStatus: 'error',
															});
														}
														if (parseFloat(value) > parseFloat(selectedSellMax.value)) {
															setSelectedSellMin({
																value,
																errorMessage: t<string>('sellMinShouldBeLessThanSellMax'),
																validationStatus: 'error',
															});
														} else if (parseFloat(value) > maxOrderLimit) {
															setSelectedSellMin({
																value,
																errorMessage: t<string>('sellMinShouldNotBeGreaterThanFiftyThousand'),
																validationStatus: 'error',
															});
														} else {
															setSelectedSellMin({ value, errorMessage: '', validationStatus: 'success' });
														}
													}
												}}
											/>
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											required
											validateStatus={selectedSellMax.validationStatus}
											help={selectedSellMax.validationStatus !== 'success' ? selectedSellMax.errorMessage : null}
											label={<b>{t<string>('sellMax')}</b>}
											className='w-[300px]'
										>
											<InputNumber
												className='w-full'
												value={selectedSellMax.value}
												onChange={(value) => {
													if (value) {
														if (isNaN(parseFloat(value))) {
															setSelectedSellMax({
																value: '0',
																errorMessage: t<string>('enterValidValue'),
																validationStatus: 'error',
															});
														}
														if (parseFloat(value) < parseFloat(selectedSellMin.value)) {
															setSelectedSellMax({
																value,
																errorMessage: t<string>('sellMaxShouldBeGreaterThanSellMin'),
																validationStatus: 'error',
															});
														} else if (parseFloat(value) > maxOrderLimit) {
															setSelectedSellMax({
																value,
																errorMessage: t<string>('sellMaxShouldNotBeGreaterThanFiftyThousand'),
																validationStatus: 'error',
															});
														} else {
															setSelectedSellMax({ value, errorMessage: '', validationStatus: 'success' });
														}
													}
												}}
											/>
										</Form.Item>
									</div>
								</div>
							</Form>
							<div className='flex justify-center'>
								<Button
									disabled={
										!(
											selectedBuyMax.validationStatus === 'success' &&
											selectedBuyMin.validationStatus === 'success' &&
											selectedSellMax.validationStatus === 'success' &&
											selectedSellMin.validationStatus === 'success'
										) || !tradingGroup
									}
									onClick={() => setShowOTPModal(true)}
									className='px-10 bg-[#01A2FF] font-bold'
									style={{ boxShadow: 'none' }}
									type='primary'
								>
									{t<string>('submit')}
								</Button>
							</div>
						</div>
					</Spin>
				</div>
			</div>
			<OTPModal
				showOTPModal={showOTPModal}
				setOTPModal={setShowOTPModal}
				handelAccept={submitRecord}
				countryCode={''}
				accountGroup={tradingGroup}
			/>
		</>
	);
};

export default AccountGroup;
