import React, { useEffect } from 'react';
import './Navbar.scss';
import Logo from '../../assets/images/logo.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Switch } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { getUserLanguageInCookie } from '../../helpers/cookiesHandler';
import Languages from '../Languages/Languages';
import { useMainContext } from '../../store/MainContext';
import { deleteUserInfo, getUserInfo, setUserInfo } from '../../helpers/localStorageHandler';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getInitials } from '../../helpers/getStringInitials';
import Notification from '../Notification/Notification';
const Navbar = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mainState, setMainState } = useMainContext();
	const isLoggedIn = React.useMemo(() => mainState.isLoggedIn, [mainState]);
	const [canHandleAppeal, setHandleAppeal] = React.useState(mainState.canHandleAppeal);
	const userInfo = React.useMemo(() => getUserInfo(), [mainState.email]);
	const userMenuDetails = React.useMemo(
		() => ({
			email: mainState?.email ?? '',
			userName: mainState?.userName ?? '',
			countryCode: mainState?.countryCode ?? '',
		}),
		[mainState.email, mainState.userName, mainState.isLoggedIn],
	);
	const hanelLogOut = React.useCallback(() => {
		const token = userInfo?.token;

		if (token) {
			axios.post(API_ENDPOINTS.logout, { token }, { headers: { Authorization: `Bearer ${token}` } }).then((_) => {
				deleteUserInfo();
				setMainState({ ...mainState, isLoggedIn: false });
				navigate('/sign-in');
			});
		}
	}, [getUserInfo, navigate]);

	useEffect(() => {
		setHandleAppeal(mainState.canHandleAppeal);
	}, [mainState.canHandleAppeal]);

	const handleAppeal = (value: boolean) => {
		setHandleAppeal(value);
		axios(API_ENDPOINTS.handleAppeal.replace('%canHandleAppeal%', `${value}`), {
			method: 'PUT',
			headers: { Authorization: `Bearer ${getUserInfo()?.token}` },
		}).then(({ data }) => {
			setHandleAppeal(data.canHandleAppeal);
			setMainState({
				...mainState,
				canHandleAppeal: data.canHandleAppeal,
			});
			let loggedInUser = getUserInfo() ?? {};
			loggedInUser = { ...loggedInUser, canHandleAppeal: data.canHandleAppeal };
			setUserInfo(loggedInUser);
		});
	};
	return (
		<div className=' bg-lightBlue'>
			<div className='max-w-[1440px] mx-auto px-[15px] md:px-[75px] py-2 flex flex-col md:flex-row items-center justify-between'>
				{/* Logo and Menu Items */}
				<div className='flex flex-col md:flex-row items-center justify-between w-full md:w-auto h-[45px]'>
					{process.env.REACT_APP_LOGO === 'false' ? null : (
						<Link to='/'>
							<img src={Logo} alt='logo' className=' w-32 mx-3 md:mx-0 md:ml-3' />
						</Link>
					)}
				</div>
				<div className='flex items-center mt-2 md:mt-0'>
					<Languages />
					{isLoggedIn && <Notification />}
					{isLoggedIn && (
						<Popover
							placement={getUserLanguageInCookie() === 'ar' ? 'topLeft' : 'topRight'}
							trigger='click'
							content={
								<div className='navbar-container flex flex-col'>
									<div className='user-menu-details'>
										<span className='user-menu-details-initials'>{getInitials(userMenuDetails.userName)}</span>
										<div className='user-menu-details-content'>
											<div className='name flex font-bold'>
												{userMenuDetails.userName}
												{(
													<img
														src={`https://flagsapi.com/${userMenuDetails.countryCode}/shiny/64.png`}
														alt='country'
														className='country-flag'
													/>
												) ?? <>({userMenuDetails.countryCode})</>}
											</div>
											<p className='email'>{userMenuDetails.email}</p>
										</div>
									</div>
									<div className='w-[110%] h-[1px] bg-[#eaecef] mt-2 ml-[-10px]'></div>
									<div className='actionBtns'>
										<div className='flex align-middle'>
											<p className='my-1 mr-1'>{t<string>('handleAppeal')}</p>
											<Switch
												onChange={handleAppeal}
												size='small'
												className='m-2'
												checked={canHandleAppeal}
												style={{ backgroundColor: canHandleAppeal ? '#0077ff' : 'grey' }}
											/>
										</div>
										<Button
											className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
											onClick={() => navigate('/update-password')}
										>
											{t<string>('changePassword')}
										</Button>
										<Button
											onClick={hanelLogOut}
											className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
										>
											{t<string>('logout')}
										</Button>
									</div>
								</div>
							}
						>
							<Button className='p-0 m-0 border-none uppercase text-white text-xs font-medium flex items-center hover:text-white bg-transparent'>
								<UserOutlined style={{ fontSize: 16, color: '#fff' }} />
								<DownOutlined className='w-2 ' style={{ fontSize: 8, color: '#fff' }} />
							</Button>
						</Popover>
					)}
				</div>
			</div>
		</div>
	);
};

export default Navbar;
