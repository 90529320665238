import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Button, Form, DatePicker, Spin, Switch } from 'antd';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { Maintenance } from '../../models/Maintenance';
import Header from '../../components/Header/Header';
import dayjs from 'dayjs';
const AddMaintenance = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const [maintenance, setMaintenance] = useState({
		type: 'MAINTENANCE',
		startDate: dayjs().toISOString(),
		endDate: dayjs().toISOString(),
		enabled: false,
	});
	const [loading, setLoading] = useState(false);
	const token = getUserInfo()?.token;
	const queryParams = new URLSearchParams(location.search);
	const announcementId = queryParams.get('announcementId');

	const submitRecord = () => {
		setLoading(true);
		let data = {
			...maintenance,
			startDate: maintenance.startDate,
			endDate: maintenance.endDate,
			announcementId: announcementId ? +announcementId : null,
		};

		axios
			.post(`${API_ENDPOINTS.annoncement}`, { ...data })
			.then((_) => navigate('/maintenance'))
			.catch((err) => console.error(err))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (announcementId) {
			axios
				.get(API_ENDPOINTS.annoncement, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(({ data }) => {
					let announcement: Maintenance = data.find(
						(announcement: Maintenance) => announcement.announcementId === +announcementId,
					);
					setMaintenance({
						...maintenance,
						startDate: announcement.startDate,
						endDate: announcement.endDate,
						enabled: announcement.enabled,
					});
				});
		}
	}, []);
	return (
		<div>
			<Header title={t<string>('maintenance')} description={t<string>('maintenanceSubtitle')} />
			<div className='border border-solid  border-[#E6E6E6] rounded-md max-w-[900px] mx-auto px-[75px] p-10  mt-4 bg-white'>
				<Spin spinning={loading}>
					<div>
						<Form layout='vertical'>
							<div className='flex flex-col items-center justify-center w-full'>
								<div className='w-full flex justify-center'>
									<Form.Item label={t<string>('startTime')}>
										<DatePicker
											showTime={{ format: 'HH:mm:ss' }}
											value={dayjs(maintenance.startDate)}
											onChange={(value) => {
												if (value) {
													setMaintenance({
														...maintenance,
														startDate: dayjs(value).toISOString(),
													});
												} else {
													setMaintenance({
														...maintenance,
													});
												}
											}}
										/>
									</Form.Item>
								</div>
								<div className='w-full flex justify-center'>
									<Form.Item label={t<string>('endTime')}>
										<DatePicker
											showTime={{ format: 'HH:mm:ss' }}
											value={dayjs(maintenance.endDate)}
											onChange={(value) => {
												if (value) {
													setMaintenance({
														...maintenance,
														endDate: dayjs(value).toISOString(),
													});
												} else {
													setMaintenance({
														...maintenance,
													});
												}
											}}
										/>
									</Form.Item>
								</div>
								<div className='w-full flex justify-center'>
									<Form.Item label={t<string>('enabled')}>
										<Switch
											checked={maintenance.enabled}
											checkedChildren={t<string>('yes')}
											unCheckedChildren={t<string>('no')}
											onChange={(value) => setMaintenance({ ...maintenance, enabled: value })}
										/>
									</Form.Item>
								</div>
							</div>
						</Form>
						<div className='flex justify-center'>
							<Button
								onClick={submitRecord}
								className='px-10 bg-[#01A2FF] font-bold'
								style={{ boxShadow: 'none' }}
								type='primary'
							>
								{t<string>('submit')}
							</Button>
						</div>
					</div>
				</Spin>
			</div>
		</div>
	);
};

export default AddMaintenance;
