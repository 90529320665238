import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Button, Form, Input, Select, Spin, ColorPicker, Popconfirm, Table, Pagination } from 'antd';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import type { Color } from 'antd/es/color-picker';
import { useRouteContext } from '../../components/CurrentRouteProvider';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { OTPModal } from './OTPModal';

interface Record {
	id: number;
	methodId?: number | null;
	methodName: string;
	color: Color | string;
	bgColor: Color | string;
	public: boolean;
}
interface FieldItem {
	value: string;
	errorMessage: string;
	validationStatus: ValidateStatus;
}
const Country = () => {
	const params = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [paymentMethods, setPaymentMethods] = useState<Record[]>([]);
	const [showOTPModal, setShowOTPModal] = useState(false);
	const [isPublic, setIsPublic] = useState(false);
	const [page, setPage] = useState(1);
	const [totalElements, setTotalElements] = useState(0);
	const [countryName, setCountryName] = useState<FieldItem>({
		value: '',
		validationStatus: 'validating',
		errorMessage: '',
	});
	const [countryCode, setCountryCode] = useState<FieldItem>({
		value: '',
		validationStatus: 'validating',
		errorMessage: '',
	});
	const [currencyCode, setCurrencyCode] = useState<FieldItem>({
		value: '',
		validationStatus: 'validating',
		errorMessage: '',
	});
	const [maxProfitRatio, setMaxProfitRatio] = useState<FieldItem>({
		value: '',
		validationStatus: 'validating',
		errorMessage: '',
	});
	const [exchangeRate, setExchangeRate] = useState<FieldItem>({
		value: '',
		validationStatus: 'validating',
		errorMessage: '',
	});
	const [loading, setLoading] = useState(false);
	const { routeVariable, updateRouteVariable } = useRouteContext();
	useEffect(() => {
		if (params.countryCode) {
			updateRouteVariable('/edit-country');
		} else {
			updateRouteVariable('/add-country');
		}
	}, []);
	const token = getUserInfo()?.token;

	const submitRecord = () => {
		setLoading(true);
		axios(`${API_ENDPOINTS.countryAndPaymMethod}`, {
			method: params.countryCode ? 'PUT' : 'POST',
			headers: { Authorization: `Bearer ${token}` },
			data: {
				country: {
					countryCode: countryCode.value,
					countryName: countryName.value,
					currencyCode: currencyCode.value,
					exchangeRate: exchangeRate.value,
					maxProfitRatio: `${parseFloat(maxProfitRatio.value) / 100}`,
					isPublic,
				},
				paymentMethod: paymentMethods.map((el) => {
					return {
						countryCode: countryCode.value,
						methodId: el.methodId,
						methodName: el.methodName,
						color: el.color,
						bgColor: el.bgColor,
						isPublic: el.public,
					};
				}),
			},
		})
			.then((_) => navigate('/countries'))
			.catch((err) => console.error(err))
			.finally(() => setLoading(false));
	};
	useEffect(() => {
		if (params.countryCode) {
			setLoading(true);
			axios(`${API_ENDPOINTS.countries}`, {
				method: 'GET',
				headers: { Authorization: `Bearer ${token}` },
			})
				.then((res) => {
					const selectedCountry = res.data.find((country: any) => country.countryCode === params.countryCode);
					setCountryName({
						value: selectedCountry.countryName,
						validationStatus: 'success',
						errorMessage: t('requiredField'),
					});
					setCountryCode({
						value: selectedCountry.countryCode,
						validationStatus: 'success',
						errorMessage: t('requiredField'),
					});
					setCurrencyCode({
						errorMessage: t('requiredField'),
						value: selectedCountry.currencyCode,
						validationStatus: 'success',
					});
					setMaxProfitRatio({
						errorMessage: t('requiredField'),
						value: (selectedCountry.maxProfitRatio * 100).toString(),
						validationStatus: 'success',
					});
					setExchangeRate({
						...exchangeRate,
						value: selectedCountry.exchangeRate,
					});
					setIsPublic(selectedCountry.isPublic);
					setTotalElements(selectedCountry.paymentMethods.length);
					const paymentMethods = selectedCountry.paymentMethods
						.slice((page - 1) * 10, page * 10)
						.map((el: any, index: number) => {
							return {
								id: index + 1,
								methodId: el.methodId,
								methodName: el.methodName,
								color: el.color,
								bgColor: el.bgColor,
								public: el.isPublic,
							};
						});
					setPaymentMethods(paymentMethods);
				})
				.catch((err) => console.error(err))
				.finally(() => setLoading(false));
		} else {
			setPaymentMethods([]);
		}
	}, [params.countryCode, token, page]);
	const handleDeleteRecord = (id: number) => {
		const updatedData = paymentMethods.filter((row) => row.id !== id);
		setPaymentMethods(updatedData);
	};
	const handleAddRecord = () => {
		setPaymentMethods([
			...paymentMethods,
			{
				id: paymentMethods.length + 1,
				methodId: null,
				methodName: t('paymentMethodName'),
				color: '#a91581',
				bgColor: '#a91581',
				public: true,
			},
		]);
	};
	const handleBackgroundColorChange = (id: number, colorObj: Color) => {
		const updatedData = paymentMethods.map((row) => {
			if (row.id === id) {
				return { ...row, bgColor: colorObj.toHexString() };
			} else {
				return row;
			}
		});
		setPaymentMethods(updatedData);
	};
	const handlePublicChange = (value: string, record: Record) => {
		const updatedData = paymentMethods.map((row) =>
			row.id === record.id ? { ...row, public: value === t('yes') } : row,
		);
		setPaymentMethods(updatedData);
	};
	const handleColorChange = (id: number, colorObj: Color) => {
		const updatedData = paymentMethods.map((row) => (row.id === id ? { ...row, color: colorObj.toHexString() } : row));
		setPaymentMethods(updatedData);
	};

	const setPaymentMethodName = (id: number, newName: string) => {
		const updatedData = paymentMethods.map((row) =>
			row.id === id
				? {
						...row,
						methodName: newName,
				  }
				: row,
		);
		setPaymentMethods(updatedData);
	};

	const paymentMethodsColumns = [
		{
			title: (
				<div className='pb-4' style={{ display: 'flex', justifyContent: 'center' }}>
					<Button style={{ background: 'none', border: 'none', boxShadow: 'none' }} onClick={handleAddRecord}>
						<PlusOutlined className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold' />
					</Button>
				</div>
			),
			dataIndex: 'add',
			key: 'add',

			render: (record: any, rowData: Record) => (
				<div className='w-full' style={{ display: 'flex', justifyContent: 'center' }}>
					{!rowData.methodId ? (
						<Popconfirm
							title={t('confirmNewPaymentMethodDelete')}
							onConfirm={() => handleDeleteRecord(rowData.id)}
							okText={t('yes')}
							cancelText={t('no')}
						>
							<Button type='text' style={{ color: '#0F2147', margin: 'auto' }} icon={<DeleteFilled />} />
						</Popconfirm>
					) : (
						<></>
					)}
				</div>
			),
		},
		{
			title: t('name'),
			dataIndex: 'methodName',
			render: (name: string, record: Record) => (
				<Input value={name} onChange={(e) => setPaymentMethodName(record.id, e.target.value)} />
			),
		},
		{
			title: t('color'),
			dataIndex: 'color',
			render: (rowValue: string, record: Record) => (
				<div>
					<ColorPicker format='hex' value={record.color} onChange={(e) => handleColorChange(record.id, e)} />
				</div>
			),
		},
		{
			title: t('backgroundColor'),
			dataIndex: 'bgColor',
			render: (rowValue: string, record: Record) => (
				<div>
					<ColorPicker
						format='hex'
						value={record.bgColor}
						onChange={(e) => handleBackgroundColorChange(record.id, e)}
					/>
				</div>
			),
		},
		{
			title: t('public'),
			dataIndex: 'public',
			render: (value: any, record: Record) => (
				<Select
					defaultValue={value ? t('yes') : t('no')}
					style={{ width: 80 }}
					onChange={(val) => handlePublicChange(val, record)}
				>
					<Select.Option value={t('yes')}>{t('yes')}</Select.Option>
					<Select.Option value={t('no')}>{t('no')}</Select.Option>
				</Select>
			),
		},
	];
	const getErrorMessage = (value: string) => {
		if (!value) {
			return t('requiredField');
		} else if (+value < 0) {
			return t('biggerThanZero');
		} else if (+value > 100) {
			return t('lessThanhundred');
		} else {
			return '';
		}
	};
	const onShowOTPModal = () => {
		if (!countryCode.value) {
			setCountryCode({ ...countryCode, validationStatus: 'error', errorMessage: t('requiredField') });
		}
		if (!maxProfitRatio.value) {
			setMaxProfitRatio({ ...maxProfitRatio, validationStatus: 'error', errorMessage: t('requiredField') });
		}
		if (!countryName.value) {
			setCountryName({ ...countryName, validationStatus: 'error', errorMessage: t('requiredField') });
		}

		if (countryName.value && maxProfitRatio.value && countryCode.value) setShowOTPModal(true);
	};
	return (
		<>
			<div>
				<div className='merchants-head py-10'>
					<h1 className='text-[32px] text-white font-bold leading-7 mb-3 text-center'>Country/Payment Methods</h1>
					<p className='text-white text-sm text-center'>{t<string>('CountryAndPaymentMethods')}</p>
				</div>
				<div className='border border-solid border-[#E6E6E6] rounded-md p-4 max-w-[900px] mx-auto lg:px-[75px] pt-[20px] pb-20 mt-16 bg-white'>
					<Spin spinning={loading}>
						<Form layout='vertical'>
							<div
								className='flex justify-between'
								style={{
									flexWrap: 'wrap',
									minWidth: '85%',
									margin: 'auto',
								}}
							>
								<h2
									className='w-full text-[#00AFFF]'
									style={{
										borderBottomStyle: 'solid',
										borderBottomColor: '#C2C2C2',
										borderBottomWidth: '0.05rem',
									}}
								>
									{t('country')}
								</h2>
								<div className='xss-[100%] lg:w-[40%]'>
									<div className='flex flex-wrap'>
										<div className='w-full'>
											<Form.Item
												required
												validateStatus={countryName.validationStatus}
												help={countryName.validationStatus !== 'success' ? countryName.errorMessage : null}
												initialValue={countryName}
												valuePropName='countryName'
												name='countryName'
												label={<b>{t('name')}</b>}
												className='w-[300px]'
											>
												<Input
													name='countryName'
													value={countryName.value}
													onChange={(e) =>
														setCountryName({
															value: e.target.value,
															validationStatus: e.target.value ? 'success' : 'error',
															errorMessage: e.target.value ? '' : t('requiredField'),
														})
													}
												/>
											</Form.Item>
										</div>
										<div className='w-full'>
											<Form.Item
												validateStatus={currencyCode.validationStatus}
												help={currencyCode.validationStatus !== 'success' ? currencyCode.errorMessage : null}
												initialValue={currencyCode}
												valuePropName='currencyCode'
												name='currencyCode'
												label={<b>{t('currencyCode')}</b>}
												required
												className='w-[300px]'
											>
												<Input
													value={currencyCode.value}
													onChange={(e) => {
														setCurrencyCode({
															validationStatus: e.target.value ? 'success' : 'error',
															errorMessage: e.target.value ? '' : t('requiredField'),
															value: e.target.value,
														});
													}}
												/>
											</Form.Item>
										</div>
										<div className='w-full'>
											<Form.Item
												validateStatus={maxProfitRatio.validationStatus}
												help={maxProfitRatio.validationStatus !== 'success' ? maxProfitRatio.errorMessage : null}
												initialValue={maxProfitRatio}
												valuePropName='maxProfitRatio'
												name='maxProfitRatio'
												label={<b>{t('maxProfitRatio')}</b>}
												required
												className='w-[300px]'
											>
												<Input
													value={maxProfitRatio.value}
													onChange={(e) => {
														setMaxProfitRatio({
															validationStatus: !getErrorMessage(e.target.value) ? 'success' : 'error',
															errorMessage: getErrorMessage(e.target.value),
															value: e.target.value,
														});
													}}
													min={1}
													max={100}
													type='number'
													suffix='%'
												/>
											</Form.Item>
										</div>
									</div>
								</div>
								<div style={{ width: '40%' }}>
									<div className='flex flex-wrap'>
										<div className='w-full'>
											<Form.Item
												required
												validateStatus={countryCode.validationStatus}
												help={countryCode.validationStatus !== 'success' ? countryCode.errorMessage : null}
												valuePropName='countryCode'
												name='countryCode'
												label={<b>{t('countryCode')}</b>}
												className='w-[300px]'
											>
												<Input
													disabled={params.countryCode ? true : false}
													value={countryCode.value}
													onChange={(e) => {
														setCountryCode({
															errorMessage: e.target.value ? '' : t('requiredField'),
															value: e.target.value,
															validationStatus: e.target.value ? 'success' : 'error',
														});
													}}
													min={100}
												/>
											</Form.Item>
										</div>
										<div className='w-full'>
											<Form.Item
												required
												name='isPublic'
												valuePropName='isPublic'
												label={<b>{t('public')}</b>}
												className='w-[300px]'
											>
												<Select
													defaultValue={isPublic === true ? t('yes') : t('no')}
													value={isPublic === true ? t('yes') : t('no')}
													loading={false}
													onChange={(e: string) => {
														setIsPublic(e === t('yes') ? true : false);
													}}
												>
													{[
														{ label: t('yes'), value: t('yes') },
														{ label: t('no'), value: t('no') },
													].map((item, index) => (
														<Select.Option key={index} value={item.value}>
															{item.label}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</div>
										<div className='w-full'>
											<Form.Item
												valuePropName='exchangeRate'
												name='countryCode'
												label={<b>{t('exchangeRate')}</b>}
												className='w-[300px]'
											>
												<Input
													type='number'
													value={exchangeRate.value}
													onChange={(e) => {
														setExchangeRate({
															...exchangeRate,
															value: e.target.value,
														});
													}}
													min={100}
												/>
											</Form.Item>
										</div>
									</div>
								</div>
								<h2
									className='w-full text-[#00AFFF]'
									style={{
										borderBottomStyle: 'solid',
										borderBottomColor: '#C2C2C2',
										borderBottomWidth: '0.05rem',
									}}
								>
									{t('paymentMethods')}
								</h2>
								<div className='w-full'>
									<div>
										<Table pagination={false} columns={paymentMethodsColumns} dataSource={paymentMethods} rowKey='id' />
										<div className='m-4'>
											<Pagination
												showSizeChanger={false}
												pageSize={10}
												current={page}
												total={totalElements}
												onChange={(pageIndex) => setPage(pageIndex)}
											/>
										</div>
									</div>
								</div>
								<div className='w-full pt-3 flex justify-end'>
									<Button
										className='px-10 bg-[#01A2FF] font-bold'
										style={{ boxShadow: 'none' }}
										onClick={() => onShowOTPModal()}
										type='primary'
										disabled={
											countryCode.validationStatus === 'error' ||
											maxProfitRatio.validationStatus === 'error' ||
											currencyCode.validationStatus === 'error' ||
											countryName.validationStatus === 'error'
										}
									>
										{t<string>('submit')}
									</Button>
								</div>
							</div>
						</Form>
					</Spin>
				</div>
			</div>
			<OTPModal
				showOTPModal={showOTPModal}
				setOTPModal={setShowOTPModal}
				handelAccept={submitRecord}
				countryCode={countryCode.value}
				accountGroup={''}
			/>
		</>
	);
};

export default Country;
